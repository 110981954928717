import api from '../../utils/api'

export const state = {
  summary: []
}

export const mutations = {
  SET_SUMMARY(state, summary) {
    state.summary = summary
  }
}

export const actions = {
  async load({ commit }, userId) {
    const usersDatas = (await api.get(`/user-data/${userId}`)).data
    commit('SET_SUMMARY', usersDatas)
  },
  async updatePrintedReports(_, user) {
    await api.put(`/user-data/printed/${user}`)
  }
}

export const getters = {
  summary: (state) => state.summary
}
