import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import money from 'v-money'
import JsonViewer from 'vue-json-viewer'


import router from './router'
// import store from './store'
import store from './stores/index'
import App from './App.vue'
import api from './utils/api'

import Default from './layouts/Default'
import Sidebar from './layouts/Sidebar'
import Print from './layouts/Print'
import Landpage from './layouts/Landpage'
import Landpage2 from './layouts/Landpage2'

import { abilitiesPlugin } from '@casl/vue'
import ability from './config/ability.js'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VueCompositionAPI from '@vue/composition-api'
import CKEditor from 'ckeditor4-vue';

import { setupBaseComponents } from './config/BaseComponents'

Vue.component('default-layout', Default)
Vue.component('landpage-layout', Landpage)
Vue.component('landpage2-layout', Landpage2)
Vue.component('print-layout', Print)
Vue.component('sidebar-layout', Sidebar)

setupBaseComponents(Vue)

Vue.config.productionTip = false
Vue.use(BootstrapVueIcons)

Vue.use(abilitiesPlugin, ability)
Vue.use(BootstrapVue)
Vue.use(VueMoment, { moment })
Vue.use(VueCompositionAPI)
Vue.use( CKEditor );
Vue.use(money, {precision: 4})
Vue.use(JsonViewer)
Vue.prototype.$api = api

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
