import api from '../../utils/api'

export const state = {
  logsBuyPlans: [],
  logsBuyPlansMeta: {},
};

export const mutations = {
  SET_LOGSBUYPLANS(state, logsBuyPlans) {
    state.logsBuyPlans = logsBuyPlans;
  },
  SET_LOGSBUYPLANS_META(state, logsBuyPlansMeta) {
    state.logsBuyPlansMeta = logsBuyPlansMeta;
  },
}

export const actions = {
  async handleGetLogsBuyPlans({ commit }, payload) {
    let params = {};
    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        filter: payload.filter || undefined,
        skip: payload.skip || undefined,
        limit: payload.limit || undefined,
      }
    }
    const { data: response } = await api.get(`/subscription-logs/all`, { params });

    if (response && response.data.logsBuyPlans) {
      commit('SET_LOGSBUYPLANS', response.data.logsBuyPlans);
      commit('SET_LOGSBUYPLANS_META', response.meta);
    } else {
      commit('SET_LOGSBUYPLANS', []);
      commit('SET_LOGSBUYPLANS_META', {});
    }
  },
  resetLogsBuyPlans({ commit }) {
    commit('SET_LOGSBUYPLANS_META', {})
  },
}

export const getters = {
  getLogsBuyPlans: (state) => state.logsBuyPlans,
  getLogsBuyPlansMeta: (state) => state.logsBuyPlansMeta,
}
