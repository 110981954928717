<template>
  <div class="base-nps">
    <div 
      v-for="number in numbers"
      :key="`number_${number.value}`"
      class="number"
      @click="$emit('input', number.value)"
    >
      <div class="icon" v-html="number.icon"/>
      <div class="value" :class="{ selected: number.value == value }">{{number.value}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseNps',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
  },
  data(){
    return {
      numbers: [
        {
          icon: '&#128555;',
          value: 1
        },
        {
          icon: '',
          value: 2
        },
        {
          icon: '',
          value: 3
        },
        {
          icon: '',
          value: 4
        },
        {
          icon: '',
          value: 5
        },
        {
          icon: '&#128528;',
          value: 6
        },
        {
          icon: '',
          value: 7
        },
        {
          icon: '',
          value: 8
        },
        {
          icon: '',
          value: 9
        },
        {
          icon: '&#128515;',
          value: 10
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.base-nps {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;

  .number {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;

    gap: 4px;

    font-size: 20px;

    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    .value {
      width: 38px;
      height: 38px;

      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: bold;

      background: var(--dark-blue);
      color: var(--white);

      &.selected {
        background: var(--blue);
      }
    }

    // border: 2px solid transparent;

    // transition: all 0.1s ease-in-out;

    // &.selected  {
    //   border-bottom: 2px solid var(--sapphire-blue);
    // }

  }
}
</style>