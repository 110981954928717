<template>
  <section class='register p-4'>
    <b-overlay
      :show='signingUp'
      rounded="sm"
      opacity='0.6'
      spinner-small
      variant="light"
      spinner-variant='primary'
      class='d-inline-block w-100'
    >
    <section class='img_logo'>
      <img width='80' src='../../../../assets/images/logoCadastroPerfil.svg' alt='' />
    </section>

    <b-alert
      show
      style='margin-bottom: -10px;'
      class='mt-3'
      variant='danger'
      :class='{ hidden: !error }'
    >
      {{ error }}
    </b-alert>

    <section class='w-100'>
      <b-input-group>
        <section class='w-100 mt-3'>
          <label for="username" class='label-input'>E-mail <strong class='asterisco'>*</strong></label>
          <b-form-input
            class='register_input w-100'
            v-model='userProfile.username'
            type='email'
            required
            id='username'
            placeholder='your@email.com'
            @keyup='hasErrors'
            trim
          >
          </b-form-input>

          <b-input-group-append class='register_input_icon'>
            <b-icon
              font-scale='1.5'
              color='#707070'
              icon='person'
            ></b-icon>
          </b-input-group-append>
        </section>
      </b-input-group>

      <b-input-group>
        <section class='w-100 mt-3'>
          <label for="cpf" class='label-input'>CPF <strong class='asterisco'>*</strong></label>
          <b-form-input
            class='register_input'
            v-model='userProfile.cpf'
            type='text'
            required
            @keyup='hasErrors'
            placeholder='Digite o CPF'
            v-mask="['###.###.###-##']"
          ></b-form-input>
        </section>
      </b-input-group>

      <b-input-group>
        <section class="w-100 mt-3">
          <label for="phone" class='label-input'>Telefone celular <strong class='asterisco'>*</strong></label>
          <b-form-input
            class='register_input'
            v-model='userProfile.phone'
            type='text'
            required
            placeholder='Digite seu telefone celular'
            v-mask="['(##) #####-####']"
            @keyup='hasErrors'
          ></b-form-input>

          <b-input-group-append
            class='register_input_icon'
          >
            <b-icon
              font-scale='1.2'
              color='#707070'
              icon='telephone'
            ></b-icon>
          </b-input-group-append>
        </section>
      </b-input-group>

      <b-input-group>
        <section class="w-100 mt-3">
          <label for="password" class='label-input'>Senha <strong class='asterisco'>*</strong></label>
          <b-form-input
            class='register_input'
            :type="showTypedPassword ? 'text' : 'password'"
            v-model='userProfile.password'
            placeholder='Senha'
            @keyup='hasErrors'
            required
          ></b-form-input>

          <b-input-group-append
            class='register_input_icon'
          >
            <b-icon
              v-if='showTypedPassword'
              font-scale='1.5'
              color='#707070'
              icon='eye-slash'
              @click='showTypedPassword = !showTypedPassword'
            ></b-icon>

            <b-icon
              v-else
              font-scale='1.5'
              color='#707070'
              icon='eye'
              @click='showTypedPassword = !showTypedPassword'
            ></b-icon>
          </b-input-group-append>
        </section>
      </b-input-group>

      <b-input-group>
        <section class="w-100 mt-3">
          <label for="passwordConfirm" class='label-input'>Confirme sua senha <strong class='asterisco'>*</strong></label>
          <b-form-input
            :type="showTypedPasswordConfirm ? 'text' : 'password'"
            v-model='userProfile.passwordConfirm'
            placeholder='Confirme sua senha*'
            @keyup='hasErrors'
            required
            class='register_input'
          ></b-form-input>

          <b-input-group-append
            class='register_input_icon'
          >
            <b-icon
              v-if='showTypedPasswordConfirm'
              font-scale='1.5'
              color='#707070'
              icon='eye-slash'
              @click='showTypedPasswordConfirm = !showTypedPasswordConfirm'
            ></b-icon>
            <b-icon
              v-else
              font-scale='1.5'
              color='#707070'
              icon='eye'
              @click='showTypedPasswordConfirm = !showTypedPasswordConfirm'
            ></b-icon>
          </b-input-group-append>
        </section>
      </b-input-group>

      <div class='mt-1 mb-2'>
        <secure-password-checker :password='userProfile.password' />
      </div>

      <div>
        <Terms
          v-if='term && term.privacyPolicyUrl'
          title='CONTRATO DE POLÍTICA DE PRIVACIDADE'
          textLink='Tenha acesso a política de privacidade da Ho Fácil Web'
          :link='term.privacyPolicyUrl'
        />
        <b-input-group class='mt-2'>
          <p>
            <b-form-checkbox v-model='userProfile.acceptedTerms' @change='hasErrors'>
              Eu estou ciente e de acordo com a política de privacidade
            </b-form-checkbox>
          </p>
        </b-input-group>
      </div>

      <div>
        <div id="recaptcha_to_mark" class="g-recaptcha" :data-sitekey="RECAPTCHA_KEY_SITE"></div>
      </div>

      <section>
        <b-alert
          class='mt-3'
          show
          variant='success'
          :class='{ hidden: !message }'
        >
          {{ message }}
        </b-alert>

        <b-alert
          class='mt-3'
          show
          variant='danger'
          :class='{ hidden: !error }'
        >
          {{ error }}
        </b-alert>

        <section class='d-flex justify-content-between align-items-center my-2'>
        <b-overlay
          :show='loading'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='d-inline-block w-100'
        >
          <base-button
            block
            :disabled='disabled'
            @click='signup'
            class='btn '>
            <span class='text_btn'>Cadastrar</span>
          </base-button>
        </b-overlay>
      </section>
      </section>
    </section>

      <template #overlay>
        <div class="text-center">
          <b-spinner
            style="width: 3rem; height: 3rem;"
            variant="primary"
            label="Spinning">
          </b-spinner>
          <p class='loading-text'>Cadastrando...</p>
        </div>
      </template>

    </b-overlay>
  </section>
</template>

<script>
import SecurePasswordChecker from '@/components/SecurePasswordChecker/SecurePasswordChecker'
import { mask } from 'vue-the-mask'
import moment from 'moment'
import { phoneValidator } from '../../../../utils/phoneValidator'
import { CPF } from '../../../../utils/ValidateCPFCNPJ'
import { verifyCPFExists } from '../../../../services/Search_CPF_CNPJ_CEP'
import { mapActions } from 'vuex'
import Terms from '@/components/Terms.vue'

export default {
  directives: { mask },
  namer: 'PersonalData',
  components: {
    Terms,
    SecurePasswordChecker,
  },
  computed: {
    userProfile() {
      return this.$store.getters['user/userProfile']
    },
    message() {
      return this.$store.getters['access/message']
    },
    error() {
      return this.$store.getters['access/error']
    },
    term() {
      return this.$store.getters['term/term']
    },
  },
  data() {
    return {
      confirmCode: false,
      validatedSMSCode: false,
      signingUp: false,
      showTypedPassword: false,
      cpfIsValid: false,
      birthDateIsValid: false,
      cpfExists: '',
      cpfIsAlreadyRegistered: false,
      showTypedPasswordConfirm: false,
      createdAccountAtCheckout: false,
      confirm: false,
      disabled: false,
      loading: false,
      validatedCPF: false,
      messageConfirmCode: '',
      RECAPTCHA_KEY_SITE: process.env.VUE_APP_RECAPTCHA_KEY_SITE,
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      utm_id: '',
      utm_term: '',
      utm_content: '',
    }
  },
  validations() {
    return {}
  },
  async created() {
    await this.$store.dispatch('access/updateError', '')
    await this.$store.dispatch('access/updateMessage', '')
    const { utm_source, utm_medium, utm_campaign, utm_id, utm_term, utm_content } = this.$route.query
    this.utm_source = utm_source
    this.utm_medium = utm_medium
    this.utm_campaign = utm_campaign
    this.utm_id = utm_id
    this.utm_term = utm_term
    this.utm_content = utm_content
    await this.handleGet()
  },
  async mounted() {
    this.createdAccountAtCheckout = this.$route.query.checkout
    grecaptcha.render(document.getElementById('recaptcha_to_mark'), { sitekey: this.RECAPTCHA_KEY_SITE } )
    await this.hasErrors()
  },
  methods: {
    ...mapActions('checkout', {
      checkCpf: 'checkCpf',
      checkEmail: 'checkEmail'
    }),
    ...mapActions('user', {
      handleSetCurrentUser: 'handleSetCurrentUser',
    }),
    ...mapActions('auth', {
      handleRegisterUser: 'handleRegisterUser',
      handleResetRegister: 'handleResetRegister',
    }),
    ...mapActions('auth', {
      handleSaveLocalStorageSessionId: 'handleSaveLocalStorageSessionId',
    }),
    ...mapActions('term', {
      handleGet: 'handleGet'
    }),
    async recaptchaToken() {
      return await new Promise((resolve) => {
        resolve(grecaptcha.getResponse())
      })
    },

    async validateCPF() {
      try {
        //const { data: response } = await verifyCPFExists(this.userProfile.cpf.replace(/\D/g, ''), this.userProfile.birthDate)
        //const { cpfExists, validatedCPF } = response
        this.validatedCPF = false
        return true
      } catch (e) {
      }
    },

    validateInformedDateOfBirth() {
      const endDate = moment(new Date()).add(-18, 'year').toDate()
      const initialDate = moment(new Date(1900, 0, 1))
      const birthDate = moment(new Date(this.userProfile.birthDate))
      return birthDate.isAfter(initialDate) && birthDate.isBefore(endDate) && this.userProfile.birthDate
    },

    login() {
      this.$store.dispatch('access/updateError', '')
      this.$router.push({ path: '/login' })
    },

    async hasErrors() {
      await this.$store.dispatch('access/updateMessage', '')
      await this.$store.dispatch('access/updateError', '')

      if (!this.userProfile.phone || !this.userProfile.username || !this.userProfile.password ||
        !this.userProfile.passwordConfirm || !this.userProfile.cpf) {
        this.disabled = true
        return true
      }

      if (this.userProfile.password !== this.userProfile.passwordConfirm) {
        await this.$store.dispatch('access/updateError', 'As senhas não conferem')
        this.disabled = true
        return true
      }

      /*if (!phoneValidator(this.userProfile.phone.replace(/[^\d]/g, ''))) {
        await this.$store.dispatch('access/updateError', 'Por favor digite um telefone válido para prosseguir.')
        this.disabled = true
        return true
      }*/

      /*if (!CPF(this.userProfile.cpf)) {
        await this.$store.dispatch('access/updateError', 'CPF inválido!')
        this.disabled = true
        return true
      }*/

      if (!this.userProfile.acceptedTerms) {
        this.disabled = true
        return true
      }

      await this.$store.dispatch('access/updateError', '')
      await this.$store.dispatch('access/updateMessage', 'Campos preenchidos')
      this.disabled = false
      return false
    },

    async signup() {
      try {
        this.loading = true
        this.disabled = true
        await this.$store.dispatch('access/updateError', '')
        await this.$store.dispatch('access/updateMessage', '')

        const regexIsEmail = /\S+@\S+\.\S+/
        const specialCharacters = /[^a-zA-Z0-9]/g

        if (!regexIsEmail.test(this.userProfile.username)) {
          await this.$store.dispatch('access/updateError', "Por favor, preencha o campo 'E-mail' com um e-mail valido.")
          this.disabled = false
          this.loading = false
          return
        }

        const username = this.userProfile.username.replaceAll('_', '').replaceAll('-', '').replaceAll('@', '').replaceAll('.', '')
        if (specialCharacters.test(username)) {
          await this.$store.dispatch('access/updateError', "O campo 'E-mail' não pode conter caracteres especiais.")
          this.disabled = false
          this.loading = false
          return
        }

        this.userProfile.token = await this.recaptchaToken()
        if (!this.userProfile.token) {
          await this.$store.dispatch('access/updateError', "'Clique em 'não sou um robô'")
          this.disabled = false
          this.loading = false
          return
        }

        /*if (!this.validateInformedDateOfBirth()) {
          await this.$store.dispatch('access/updateError', 'Data de nascimento inválida!')
          this.disabled = false
          this.loading = false
          return
        }*/

        if (!CPF(this.userProfile.cpf)) {
          await this.$store.dispatch('access/updateError', 'CPF inválido!')
          this.disabled = false
          this.loading = false
          return
        }

        if (!phoneValidator(this.userProfile.phone.replace(/[^\d]/g, ''))) {
          await this.$store.dispatch('access/updateError', 'Por favor digite um telefone válido para prosseguir.')
          this.disabled = false
          this.loading = false
          return
        }

        const { errors: errorsEmail } = await this.checkEmail(this.userProfile.username)
        if (errorsEmail) {
          await this.$store.dispatch('access/updateError', errorsEmail)
          this.disabled = false
          this.loading = false
          return
        }

        const { errors: errorsCpf } = await this.checkCpf(this.userProfile.cpf.replace(/\D/g, ''))
        if (errorsCpf) {
          await this.$store.dispatch('access/updateError', errorsCpf)
          this.disabled = false
          this.loading = false
          return
        }

        if (!await this.validateCPF()) {
          await this.$store.dispatch('access/updateError', 'CPF inválido!')
          this.disabled = false
          this.loading = false
          return
        }

        await this.next()
        this.disabled = false
        this.loading = false
      } catch (e) {
        this.disabled = false
        this.loading = false
      }
    },

    async next() {
      try {
        this.signingUp = true
        this.confirmCode = false

        this.userProfile.token = await this.recaptchaToken()
        if (!this.userProfile.token) {
          this.signingUp = false
          await this.$store.dispatch('access/updateError', "'Clique em 'não sou um robô'")
          return false
        }
        const {privacyPolicyUrl, privacyPolicyDate, privacyPolicyName} = this.term
        const userProfile = {
          username: this.userProfile.username.trim(),
          cpf: this.userProfile.cpf,
          phone: this.userProfile.phone.replace(/[^\d]/g, ''),
          privacyPolicyConfirmation: this.userProfile.acceptedTerms,
          privacyPolicyUrl,
          privacyPolicyDate,
          privacyPolicyName,
          password: this.userProfile.password,
          passwordConfirm: this.userProfile.passwordConfirm,
          createdAccountAtCheckout: this.createdAccountAtCheckout,
          token: this.userProfile.token,
          validatedCPF: this.validatedCPF,
          utm_source: this.utm_source ? this.utm_source : null,
          utm_medium: this.utm_medium ? this.utm_medium : null,
          utm_campaign: this.utm_campaign ? this.utm_campaign : null,
          utm_id: this.utm_id ? this.utm_id : null,
          utm_term: this.utm_term ? this.utm_term : null,
          utm_content: this.utm_content ? this.utm_content : null
        }

        this.$api.post('users', userProfile)
          .then(async (res) => {
            this.signingUp = false
            if (this.createdAccountAtCheckout) {
              const user = res.data.user
              const sessionId = res.data.sessionId
              await this.handleSetCurrentUser(user)
              await this.handleResetRegister()
              await this.handleSaveLocalStorageSessionId(sessionId)
            }
            if (res.data.error) {
              await this.$store.dispatch('access/updateError', res.data.error)
              return
            } else {
              await this.$store.dispatch('access/updateMessage', 'Cadastrado com sucesso')
            }
            this.$emit('next', 2)
          })
          .catch((err) => {
            console.log(err)
            this.signingUp = false
            this.$store.dispatch('access/updateError', 'Erro em finalizar cadastro, entre em contato com o suporte')
          })
      } catch (e) {

      }
    }
  }
}
</script>


<style scoped>
.asterisco{
  color: red;
}
.label-input{
  font-weight: 700;
  margin-bottom: -4px;
  font-size: 15px;
}
.text_btn {
  font-weight: 600;
}
.cpf-date{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.cpf{
  width: 49%;
}
.btn {
  width: 130px;
  height: 45px;
  border-radius: 5px;
  border: none;
}

.btn:hover {
  color: white;
}

.border_confirm-code{
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 20px!important;
}

.register_input {
  /*border: 0;
  border-bottom: 1px solid #cccccc;
  border-radius: 0;
  margin: 15px 0;
  padding-top: 18px;
  padding-left: 0;
  outline: none;
  background: transparent;
  margin-top: 15px;*/
}

.register_input input:focus {
  outline: none;
}

.register_input input::placeholder,
.register_input input:-ms-input-placeholder,
.register_input input::-ms-input-placeholder {
  color: #aaaaaa;
  opacity: 1;
}
.register{
}
.register_input_icon {
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 45px;
  z-index: 5;
}

.register::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.register::-webkit-scrollbar-track {
  background: #FFFFFF; /* color of the tracking area */
}

.register::-webkit-scrollbar-thumb {
  background-color: #2ab5f5; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #FFFFFF; /* creates padding around scroll thumb */
}
.loading-text{
  font-size: 18px;
  color: var(--blue);
  font-weight: bold;
}
@media only screen and (max-width: 850px) {
  .cpf{
    width: 100%;
  }
  .cpf-date{
    display: block;
  }
}
</style>
