<template>
  <div class='create-promocode'>
    <base-text-area
      class='mb-2'
      v-model='comment'
      label='Comentário'
      placeholder='Digite um comentário'
    />

    <div class='d-flex'>
      <base-button
        class='w-50 mr-1'
        danger
        @click='close()'
      >
        Cancelar
      </base-button>

      <base-button
        @click='handleSubmit'
        :disabled='v$.$invalid'
        success
        class='w-50'
      >
        Adicionar comentário
      </base-button>

    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/validators'

export default {
  name: 'AddComment',
  setup() {
    return { v$: useVuelidate() }
  },
  props: {},
  data() {
    return {
      comment: ''
    }
  },
  async created() {
  },
  computed: {
  },
  validations: {
    comment: { required }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async handleSubmit() {
      this.$emit('comment', this.comment)
    }
  }

}
</script>

<style lang='scss' scoped>
.description {
  border: 1px solid var(--border-input);
  border-radius: 4px;
  background: var(--light-gray);
  padding-left: 16px;
  padding-top: 10px;
  min-height: 180px;
}

.description-label {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: -3px;
}

.create-promocode {
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    display: inline-block;
  }

  .space {
    margin-left: 10px;
  }

  h4 {
    font-weight: bold;
    border-bottom: 2px solid var(--blue);
    color: var(--blue);
    padding-bottom: 4px;
  }
}
</style>
