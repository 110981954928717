import api from '../../utils/api'

export const state = {}

export const mutations = {}

export const actions = {
  async handleCreate(_, measurement) {
    try {
      return await api.post('/measurement', measurement)
    } catch (err) {

    }
  },

  async handleGetMeasurements(_, productId) {
    try {
      return await api.get(`/measurement/product/${productId}`)
    } catch (err) {

    }
  },

  async handleGetMeasurement(_, measurementId) {
    try {
      return await api.get(`/measurement/${measurementId}`)
    } catch (err) {

    }
  },


  async handleUpdade(_, measurement) {
    try {
      return await api.put(`/measurement/${measurement._id}`, measurement)
    } catch (err) {

    }
  },

  async handleDelete(_, measurementId) {
    try {
      return await api.delete(`/measurement/${measurementId}`)
    } catch (err) {

    }
  },
  async handleDeleteByAgents(_, body) {
    try {
      return await api.post(`/measurement/by-agents`, body)
    } catch (err) {

    }
  }
}

export const getters = {}
