<template>
  <div 
    class="t-text-area"
    :class="{ 
      block: block
    }"
  >
    <label v-if="label">{{ label }}</label>
    <textarea
      :class="{ 
        block: block
      }"
      :rows="rows"
      :value="modelValue"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>
<script>
export default {
  name: 'BaseTextArea',
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    rows: {
      type: Number,
      default: 6,
      required: false,
    },
    modelValue: {
      type: [Number, String],
      default: '',
      required: true,
    },
    placeholder: {
      type: String,
    },
    block: { type: Boolean, default: false },
    disabled: {type: Boolean, default: false},
  },
}
</script>

<style scoped lang="scss">
.t-text-area {
  display: flex;
  flex-direction: column;

  &.block {
    width: 100%;
  }

  label {
    font-size: 18px;
    font-weight: 700;
  }

  textarea {
    height: 100%;

    font-size: 16px;

    padding: 16px;

    border: 1px solid var(--border-input);
    border-radius: 4px;
    outline: none;

    cursor: pointer;


    &.block {
      width: 100%;
    }

    &:hover {
      background: var(--light-gray);
    }

    &:focus {
      border-color: var(--blue);
    }

    &.error {
      border-color: var(--red);
    }
  }
}
</style>
