import api from '../../utils/api'

export const state = {
  survey: {
    type: '',
    body: {}
  },
  surveys: [],
  surveysMeta: {},
  userRegisterProfileData: false
};

export const mutations = {
  SET_SURVEY(state, survey) {
    state.survey = survey;
  },
  SET_SURVEY_USERREGISTERPROFILEDATA(state, userRegisterProfileData) {
    state.userRegisterProfileData = userRegisterProfileData;
  },
  SET_SURVEYS(state, surveys) {
    state.surveys = surveys;
  },
  SET_SURVEYS_META(state, surveysMeta) {
    state.surveysMeta = surveysMeta;
  }
}

export const actions = {
  resetSurvey({ commit }) {
    commit('SET_SURVEY', {
      type: '',
      body: {}
    })
  },
  resetSurveyMeta({ commit }) {
    commit('SET_SURVEYS_META', {})
  },
  async handleCreateSurvey({ state }) {
    const body = state.survey;
    const { data } = await api.post('/surveys', body);

    return data;
  },
  async handleGetSurveys({ commit }, payload) {
    let params = {};

    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        filter: payload.filter || undefined,
        sortBy: payload.sortBy || undefined,
        sortIn: payload.sortIn || undefined,
        skip: payload.skip || undefined,
        limit: payload.limit || undefined,
      }
    }

    const { data: response } = await api.get('/surveys', { params });

    if (response && response.data.surveys) {
      commit('SET_SURVEYS', response.data.surveys);
      commit('SET_SURVEYS_META', response.meta);
    } else {
      commit('SET_SURVEYS', []);
      commit('SET_SURVEYS_META', {});
    }
  },
  async export( { state }) {
    const payload = state.surveysMeta
    let params = {}
    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        filter: payload.filter || undefined,
      }
    }
    const { data: response } = await api.get(`/surveys/csv`, { params });
    return response.data.surveys
  },
  async userRegisterProfileData( { commit }) {
    const { data: response } = await api.get(`/surveys/userRegisterProfileData`, );
    commit('SET_SURVEY_USERREGISTERPROFILEDATA', response.userRegisterProfileData);

    return response
  },
  async handleGetSurveyByIndex({ commit, state }, index) {
    commit('SET_SURVEY', state.surveys[index]);
  }
}

export const getters = {
  getSurvey: (state) => state.survey,
  getSurveys: (state) => state.surveys,
  getSurveysMeta: (state) => state.surveysMeta,
  getUserRegisterProfileData: (state) => state.userRegisterProfileData,
}
