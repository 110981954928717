<template>
  <section class='w-100 confirmation'>
    <b-alert
      show
      variant='danger'
      :class='{ hidden: !error }'
    >
      {{ error }}
    </b-alert>
    <b-alert show variant='success' :class='{ hidden: !message }'>
      {{ message }}
    </b-alert>

    <section class='container-confirmation-mobile'>
      <section class='header w-100 position-relative '>
        <section class='background '></section>
        <img
          v-if='createdAccountAtCheckout'
          class='text-center'
          height='286'
          src='../../../../assets/images/timerMobile.png' alt=''
        />

        <img
          v-else
          class='text-center'
          height='286'
          src='../../../../assets/images/envelope1.png' alt=''
        />
      </section>


      <section class='content p-3'>
        <section class='mb-4 mt-3 d-flex justify-content-center'>
          <img width='200' height='88' src='../../../../assets/images/logoMobile.svg' alt='' />
        </section>


        <section v-if='createdAccountAtCheckout' class='mt-4 mb-5'>
          <strong class='atencao'>Atenção!</strong><br />
          <span class='timer'>Você será redirecionado em <strong>{{ timer }} segundos</strong></span>

          <section class='mt-4'>
            <span class='info'>Esta aba será fechada e você será redirecionado para prosseguir com a sua compra.</span>
          </section>
        </section>

        <section v-else>
          <p class='title-content mt-5'>Por favor, verifique seu e-mail</p>

          <p class='text-content'>Enviamos um e-mail para <strong>{{ userProfile.username }}</strong> com o link de
            ativação da sua conta. </p>

          <p class='text-content'>Clique no link que foi enviado para confirmar e acessar a sua conta.</p>

          <p class='text-btn-content mt-4'>Não recebeu o e-mail?</p>
          <base-button
            @click='resendEmail'
            block
          >
            <strong>Reenviar e-mail</strong>
          </base-button>

          <base-button
            class='mt-3'
            @click='login()'
            block
          >
            <strong>Sair</strong>
          </base-button>
        </section>
      </section>
    </section>


    <section class='d-flex w-100 pl-3 container-confirmation-desktop'>
      <section class='w-50'>
        <section class='mb-4 mt-3'>
          <img width='100' src='../../../../assets/images/logoCadastroPerfil.svg' alt='' />
        </section>

        <section v-if='createdAccountAtCheckout' class='mt-4 mb-5'>
          <strong class='atencao'>Atenção!</strong><br />
          <span class='timer'>Você será redirecionado em <strong>{{ timer }} segundos</strong></span>

          <section class='mt-5'>
            <span class='info'>Esta aba será fechada e você será redirecionado para prosseguir com a sua compra.</span>
          </section>
        </section>

        <section class='pr-2' v-else>
          <h1 class='check_email'>Por favor, verifique seu e-mail</h1>

          <section class='mt-4 '>
            <span class='send_link'>Enviamos um email para <strong>{{ userProfile.username }}</strong> com o link de ativação da sua conta.</span>
          </section>

          <section class='mt-5 send_link mb-5'>
            <span>Clique no link que foi enviado para confirmar e acessar a sua conta.</span>
          </section>


          <section class=' mt-5 pb-4 '>
            <span class='resend_email'>
              Não recebeu o e-mail
              <strong @click='resendEmail' class='reset'> Reenviar o email</strong>
              ou
              <a href='#' @click='login()'>
                <strong class='label'>Sair</strong>
              </a>
            </span>
          </section>
        </section>
      </section>

      <section class='w-50 d-flex align-items-center justify-content-end '
               :style="createdAccountAtCheckout ? '' : 'background: #04A8F3;'">
        <img v-if='createdAccountAtCheckout' width='80%' height='80%' src='../../../../assets/images/urgent.svg'
             alt='' />

        <img v-else width='80%' height='80%' src='../../../../assets/images/envelope.svg' alt='' />
      </section>
    </section>
  </section>
</template>

<script>

import { mapActions } from 'vuex'
import BaseButton from '@/components/Base/BaseButton.vue'

export default {
  namer: 'Confirmation',
  components: { BaseButton },
  computed: {
    userProfile() {
      return this.$store.getters['user/userProfile']
    },
    message() {
      return this.$store.getters['access/message']
    },
    error() {
      return this.$store.getters['access/error']
    }
  },
  data() {
    return {
      loading: false,
      timer: 10,
      redirect: 10,
      createdAccountAtCheckout: false
    }
  },
  validations() {
    return {}
  },
  created() {
    this.$store.dispatch('access/updateError', '')
    this.$store.dispatch('access/updateMessage', '')
  },
  async mounted() {
    this.redirect = this.$route.query
    this.createdAccountAtCheckout = this.$route.query.checkout

    if (this.createdAccountAtCheckout) {
      this.stopwatch()
      this.closeUserProfile()
    } else {
      window.open('https://hofacilweb.com.br/obrigado-ativacao-de-conta/', '_blank')
    }
  },
  methods: {
    ...mapActions('user', {
      handleSetCurrentUser: 'handleSetCurrentUser'
    }),
    async login() {
      await this.$store.dispatch('access/updateError', '')
      await this.$router.push({ path: '/login' })
    },
    stopwatch() {
      setInterval(() => {
        this.timer--
      }, 1000)
    },
    closeUserProfile() {
      setTimeout(async () => {
        await this.$router.push({ name: 'Checkout', query: this.$route.query })
      }, 10000)
    },
    resendEmail() {
      try {
        this.$api.post('users/resendEmail', { username: this.userProfile.username })
          .then((res) => {
            if (res.data.message)
              this.$store.dispatch('access/updateMessage', res.data.message)
            if (res.data.error)
              this.$store.dispatch('updateError', res.data.error)
          })
          .catch((err) => {
            this.$store.dispatch('updateError', err)
          })
        this.confirmationEmail = ''
      } catch (err) {

      }
    },
    next() {
      try {
        this.userProfile.phone = this.userProfile.phone.replace(/[^\d]/g, '')
        this.$api.post('users', this.userProfile)
          .then((res) => {
            if (res.data.error) {
              this.$store.dispatch('access/updateError', res.data.error)
            } else {
              this.$store.dispatch('access/updateMessage', 'Cadastrado com sucesso')
            }
          })
          .catch(() => {
            this.$store.dispatch('access/updateError', 'Erro em finalizar cadastro, entre em contato com o suporte')
          })
          .finally(() => {
            this.loading = false
          })
      } catch (e) {

      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.atencao {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #000000;
}

.timer {
  font-style: normal;
  font-size: 20px;
  color: #000000;
}

.info {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #4E4E4E;
}

.check_email {
  font-size: 24px;
  color: #4E4E4E;
  font-style: normal;
  font-weight: 600;
}


.send_link {
  font-size: 14px;
  color: #4E4E4E;
  font-style: normal;
  font-weight: 500;
  word-wrap: break-word;
}

.resend_email {
  font-size: 14px;
  color: #4E4E4E;
  font-weight: 500;
  font-style: normal;
}

.reset {
  color: var(--blue);
  cursor: pointer;
}

.container-confirmation-desktop {
  display: none !important;
}

.container-confirmation-mobile {
  display: block !important;
  justify-content: center !important;

  .header {
    //height: 290px;

    .background {
      background: #04A8F3;
      height: 179px;
    }

    img {
      position: absolute !important;
      top: 0 !important;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    }
  }

  .content {
    margin-top: 80px;

    .title-content {
      font-size: 18px;
      font-weight: 600;
      line-height: 24.38px;
      color: #000000;
    }

    .text-content {
      font-size: 14px;
      font-weight: 500;
      line-height: 17.07px;
      text-align: left;
      color: #4E4E4E;
    }

    .text-btn-content {
      font-size: 14px;
      font-weight: 700;
      line-height: 17.07px;
      text-align: left;
      color: #4E4E4E;
    }
  }
}

.confirmation {
  background: #FFFFFF;
}

@media only screen and (min-width: 1000px) {
  .container-confirmation-desktop {
    display: flex !important;
  }

  .container-confirmation-mobile {
    display: none !important;
  }
}

</style>
