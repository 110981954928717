import api from '../../utils/api'

export const state = {
  term: {
    _id: null,
    adhesionContractUrl: null,
    adhesionContractName: null,
    adhesionContractDate: null,
    termsOfUseUrl: null,
    termsOfUseName: null,
    termsOfUseDate: null,
    privacyPolicyUrl: null,
    privacyPolicyName: null,
    privacyPolicyDate: null
  }
}

export const mutations = {
  SET_TERM(state, term) {
    state.term = term
  }
}

export const actions = {
  async handleCreate(_, term) {
    try {
      return await api.post('/term', term)
    } catch (err) {

    }
  },

  async handleGet({ commit }) {
    try {
      const { data } = await api.get(`/term`)
      const { term } = data
      commit('SET_TERM', term)
      return term
    } catch (err) {

    }
  },

  async handleUpdade(_, term) {
    try {
      return await api.put(`/term/${term._id}`, term)
    } catch (err) {

    }
  }
}

export const getters = {
  term: (state) => state.term,
}
