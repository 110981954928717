import api from '../../utils/api'
import format from 'date-fns/esm/format/index'
export const state = {
  partner: {
    name: '',
    email: '',
    _id: ''
  },
  logsAPi: [],
  logsAPiMeta: {},
};

export const mutations = {
  SET_LOGS_API(state, logsAPi) {
    state.logsAPi = logsAPi;
  },
  SET_LOGS_API_META(state, logsAPiMeta) {
    state.logsAPiMeta = logsAPiMeta;
  },
}

export const actions = {
  resetLogsAPi({ commit }) {
    commit('SET_LOGS_API_META', {})
  },
  async handleGetLogsAPi({ commit }, payload) {
    let params = {};

    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        filter: payload.filter || undefined,
        skip: payload.skip || undefined,
        limit: payload.limit || undefined,
      }
    }
    const { data: response } = await api.get(`/log-api/all`, { params });

    if (response && response.data.logApi) {
      commit('SET_LOGS_API', response.data.logApi);
      commit('SET_LOGS_API_META', response.meta);
    } else {
      commit('SET_LOGS_API', []);
      commit('SET_LOGS_API_META', {});
    }
  },
}

export const getters = {
  getLogsAPi: (state) => state.logsAPi,
  getLogsAPiMeta: (state) => state.logsAPiMeta,
}
