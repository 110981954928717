<template>
  <div class="base-slider">
    <div class="values">
      <div 
        v-for="number in 10" 
        :key="`value_${number}`"
        :class="{selected: Number(number) == Number(value)}" 
        class="value" 
        @click="$emit('input', Number(number))"
      >
        {{number}}
      </div>
    </div>
    <input 
      type="range" 
      min="1" 
      max="10" 
      :value="value"
      @input="$emit('input', Number($event.target.value))"
    >
    <label>{{label}}</label>
  </div>
</template>

<script>
export default {
  name: 'BaseSlider',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Number,
      default: 1
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.base-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  .values {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .value {
      font-size: 14px;
      color: #B9B9B9;

      cursor: pointer;
      &.selected {
        font-size: 16px;
        font-weight: bold;
        color: var(--sapphire-blue);
      }
    }
  }
  input {
    -webkit-appearance: none;  
    appearance: none;
    width: 98%; 
    height: 8px; 
    border-radius: 12px;
    background: #d3d3d3; 
    outline: none; 
    opacity: 0.7; 
    -webkit-transition: .2s; 
    transition: opacity .2s ease-in-out;

    &:hover {
      opacity: 1;
    }

    &::-webkit-slider-thumb, &::-moz-range-thumb {
      -webkit-appearance: none; 
      appearance: none;
      width: 14px; 
      height: 14px; 
      border-radius: 50%;
      background: var(--blue); 
      cursor: pointer; 
    }
  }
  label {
    font-size: 12px;
    font-weight: bold;
  }
}
</style>