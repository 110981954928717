class IUGU {
  static setup(){
    const newElement = document.createElement('script')
    newElement.setAttribute('src', 'https://js.iugu.com/v2')
    document.head.appendChild(newElement)
  }

  static validateCreditCard(payload){
    const number = payload.number.replace(/^\s+|\s+$|\s+(?=\s)/g, "")
    const cvv = payload.cvv
    const expirationMonth = payload.month
    const expirationYear = payload.year

    const brandCard = Iugu.utils.getBrandByCreditCardNumber(number)

    if(!brandCard){
      return {
        error: 'As bandeiras suportadas são "visa", "mastercard", "amex", "diners" e "elo".'
      }
    } else if(!Iugu.utils.validateCreditCardNumber(number)){
      return {
        error: 'Número do cartão inválido'
      }
    } else if(!Iugu.utils.validateCVV(cvv, brandCard)) {
      return {
        error: 'Código de segurança do cartão inválido'
      }
    } else if(!Iugu.utils.validateExpiration(Number(expirationMonth), Number(expirationYear))) {
      return {
        error: 'Data de validade do cartão inválida'
      }
    }

    return true
  }

  static async getTokenCreditCard(payload){
    Iugu.setAccountID(process.env.VUE_APP_IUGU_ACCOUNT_ID)
    Iugu.setTestMode(false)
    Iugu.setup()
    const isBlockedByAdBlock = Iugu.utils.isBlockedByAdBlock(); // Retorna true caso tenha um adBlock impedindo o funcionamento correto e false caso contrário

    if(isBlockedByAdBlock){
      return {
        error: 'Por favor desabilite o AdBlock e tente novamente!'
      }
    }
    const profile = payload.profile
    const payment = payload.payment

    const number = payment.data.number.replace(/^\s+|\s+$|\s+(?=\s)/g, "")
    const cvv = payment.data.cvv
    const expirationMonth = payment.data.month
    const expirationYear = payment.data.year
    const first_name = payment.data.first_name
    const last_name = payment.data.last_name

    const validateCreditCard = this.validateCreditCard(payment.data)

    if(validateCreditCard.error){
      return validateCreditCard
    }

    const creditCard = Iugu.CreditCard(
      number,
      expirationMonth,
      expirationYear,
      first_name,
      last_name,
      cvv
    )

    let token = await new Promise((resolve) => {
      try {
        Iugu.createPaymentToken(creditCard, function(response) {
          if (response.errors) {
            resolve({
              error: 'Credit card rejected',
              errors: response.errors
            })
          } else {
            resolve(response.id)
          }
        })

      } catch( err) {
        return {
          error: err
        }
      }
    })

    return { token }
  }
}

export default IUGU
