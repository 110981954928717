import api from '../../utils/api'

export const state = {
  logsTab:[],
  logsTabMeta:{},
}

export const mutations = {
  SET_LOGS_TAB(state, logsTab) {
    state.logsTab = logsTab;
  },
  SET_LOGS_TAB_META(state, logsTabMeta) {
    state.logsTabMeta = logsTabMeta;
  }
}

export const actions = {
  resetLogsTab({ commit }) {
    commit('SET_LOGS_TAB_META', {})
  },

  async handleGetLogsTab({ commit }, payload) {
    try {
      let params = {};

      if (payload) {
        params = {
          endDate: payload.endDate || undefined,
          startDate: payload.startDate || undefined,
          filter: payload.filter || undefined,
          action: payload.action || undefined,
          skip: payload.skip || undefined,
          limit: payload.limit || undefined,
        }
      }
      const { data: response } = await api.post(`/logs-tab/all`, params);
      console.log(response)
      if (response && response.data && response.data.logsTab && response.data.logsTab.length > 0) {
        commit('SET_LOGS_TAB', response.data.logsTab);
        commit('SET_LOGS_TAB_META', response.meta);
      } else {
        commit('SET_LOGS_TAB', []);
        commit('SET_LOGS_TAB_META', {});
      }
    } catch (err) {

    }
  },
}

export const getters = {
  logsTab: (state) => state.logsTab,
  logsTabMeta: (state) => state.logsTabMeta,
}
