<template>
  <div>
    <div>
      <li :class="eightOrMoreCharacters ? 'active': 'did-not-meet-the-requirement'">
        <b-icon
          class='icon-check'
          :icon="eightOrMoreCharacters ? 'check-circle-fill' : 'circle-fill'"
          aria-hidden='true'>
        </b-icon>
        <span class='text-information'>8 ou mais Carácteres</span>
      </li>
      <li :class="capitalizeAndLowercase ? 'active': 'did-not-meet-the-requirement'">
        <b-icon
          class='icon-check'
          :icon="capitalizeAndLowercase ? 'check-circle-fill' : 'circle-fill'"
          aria-hidden='true'>
        </b-icon>
        <span class='text-information'>Letrar maiúsculas e minúsculas</span>
      </li>
      <li :class="atLeastOneNumber ? 'active': 'did-not-meet-the-requirement'">
        <b-icon
          class='icon-check'
          :icon="atLeastOneNumber ? 'check-circle-fill' : 'circle-fill'"
          aria-hidden='true'>
        </b-icon>

        <span class='text-information'>Pelo menos um número </span>
      </li>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecurePasswordChecker',
  props: {
    password: { type: String, default: '', required: true }
  },
  data() {
    return {
      weakLevel: 1,
      middleLevel: 2,
      strongLevel: 3,
    }
  },
  computed: {
    eightOrMoreCharacters() {
      return this.password.length > 7
    },
    capitalizeAndLowercase() {
      const uppercase = /([a-z])/
      const lowercase = /([A-Z])/
      return this.password && uppercase.test(this.password) && lowercase.test(this.password)
    },
    atLeastOneNumber() {
      const numbers = /([0-9])/
      return this.password && numbers.test(this.password)
    },
    securityLevel() {
      if (this.password && this.eightOrMoreCharacters && this.capitalizeAndLowercase && this.atLeastOneNumber) {
        return 3
      } else if (this.password && this.eightOrMoreCharacters && this.capitalizeAndLowercase) {
        return 2
      } else {
        return 1
      }
    }
  },
  methods: {}
}
</script>

<style lang='scss' scoped>
.active {
  //margin-left: -19px !important;
  color: var(--green);

  .icon-check {
    width: 10px;
    height: 10px;
    margin-right: 5px !important;
  }
}
.securityLevel{
  list-style: none;
  //margin-left: -19px !important;
  .icon{
    width: 10px;
    height: 10px;
    margin-right: 3px !important;
  }
}
.did-not-meet-the-requirement{
  .icon-check {
    width: 10px;
    height: 10px;
    margin-right: 5px !important;
    color: var(--red);
  }
}

li{
  list-style: none;
}
</style>
