import Vue from 'vue'
import Router from 'vue-router'

import auth from './middleware/auth'
import profile from './middleware/profile'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Consulta Legal',
        pagetitleicon: 'fa-clipboard-list',
        middleware: auth
      },
      component: () => import('./views/LegalSearch.vue')
    },
    {
      path: '/404',
      name: 'NotFound',
      meta: {
        layout: 'default'
      },
      component: () => import('./views/404.vue')
    },
    {
      path: '/termos',
      name: 'Policy',
      component: () => import('./views/Policy.vue'),
      meta: {
        layout: 'landpage2'
      }
    },
    {
      path: '/error',
      name: 'Error',
      component: () => import('./views/Error.vue')
    },
    {
      path: '/login',
      name: 'Signin',
      component: () => import('./views/Access/Signin.vue'),
      meta: {
        layout: 'landpage'
      }
    },
    {
      path: '/cadastrar',
      name: 'Signup',
      component: () => import('./views/Access/Signup.vue'),
      meta: {
        layout: 'landpage'
      }
    },
    {
      path: '/esqueci-senha',
      name: 'ForgotPassword',
      component: () => import('./views/Access/ForgotPassword.vue'),
      meta: {
        layout: 'landpage'
      }
    },
    {
      path: '/checkout',
      name: 'Checkout',
      component: () => import('./views/Checkout2.vue'),
      meta: {
        layout: 'print',
        middleware: profile
      }
    },
    {
      path: '/em-manutencao',
      name: 'UnderMaintenance',
      component: () => import('./views/UnderMaintenance.vue'),
      meta: {
        layout: 'print',
      }
    }, {
      path: '/inventario-quimico/hierarquia/:userId',
      name: 'Hierarchy',
      component: () => import('./views/Hierarchy.vue'),
      meta: {
        layout: 'print',
      }
    },
    {
      path: '/gerenciar-empresas/hierarquia/:companyId',
      name: 'CompanyHierarchy',
      component: () => import('./views/CompanyHierarchy.vue'),
      meta: {
        layout: 'print',
      }
    },
    {
      path: '/usuarios',
      name: 'Users',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Usuários',
        pagetitleicon: 'fa-users',
        permission: 'admin',
        middleware: auth
      },
      component: () => import('./views/Users.vue')
    },
    {
      path: '/usuarios/:_id',
      name: 'UserDetails',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Detalhes do usuário',
        pagetitleicon: 'fa-users',
        permission: 'admin',
        middleware: auth
      },
      component: () => import('./views/UserDetails.vue')
    },
    {
      path: '/pedidos-de-classificacao',
      name: 'ClassificationRequests',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Pedidos de classificação',
        pagetitleicon: 'fa-list',
        permission: 'admin',
        middleware: auth
      },
      component: () => import('./views/ClassificationRequests.vue')
    },
    {
      path: '/web',
      name: 'LegalSearch',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Consulta Legal',
        subtitle: 'Seleção de agentes',
        pagetitleicon: 'fa-clipboard-list',
        middleware: auth
      },
      component: () => import('./views/LegalSearch.vue')
    },
    {
      path: '/gerenciar',
      name: 'Management',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Gerenciar',
        pagetitleicon: 'fa-clipboard-list'
      },
      component: () => import('./views/Management.vue')
    },
    {
      path: '/perfil',
      name: 'Profile',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Perfil',
        pagetitleicon: 'fa-lock',
        middleware: auth
      },
      component: () => import('./views/Profile.vue')
    },
    {
      path: '/planos',
      name: 'ChangePlan',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Gestão de pagamentos',
        pagetitleicon: 'fa-lock',
        middleware: auth
      },
      component: () => import('./views/ChangePlan.vue')
    },
    {
      path: '/imprimir',
      name: 'PrintInsalubrity',
      meta: {
        layout: 'print',
        pagetitleicon: 'fa-lock',
        middleware: auth
      },
      component: () => import('./views/PrintInsalubrity.vue')
    },
    {
      path: '/imprimir-relatorio',
      name: 'PrintReport',
      meta: {
        layout: 'print',
        pagetitleicon: 'fa-lock',
        middleware: auth
      },
      component: () => import('./views/PrintReport.vue')
    },
    {
      path: '/imprimir-consulta-legal',
      name: 'PrintLegalSearch',
      meta: {
        layout: 'print',
        pagetitleicon: 'fa-lock',
        middleware: auth
      },
      component: () => import('./views/PrintLegalSearch.vue')
    },
    {
      path: '/imprimir-acgih',
      name: 'PrintAcgih',
      meta: {
        layout: 'print',
        pagetitleicon: 'fa-lock',
        middleware: auth
      },
      component: () => import('./views/PrintAcgih.vue')
    },
    {
      path: '/imprimir-ltcat-pdf',
      name: 'PrintLtcat',
      meta: {
        layout: 'print',
        pagetitleicon: 'fa-lock',
        middleware: auth
      },
      component: () => import('./views/PrintLtcat.vue')
    },
    {
      path: '/redefinir-senha',
      name: 'RedefinePwd',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Redefinição de Senha',
        pagetitleicon: 'fa-lock',
        middleware: auth
      },
      component: () => import('./views/RedefinePassword.vue')
    },
    {
      path: '/inventario-quimico',
      name: 'ChemicalInventory',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Inventário Químico',
        pagetitleicon: 'fa-clipboard-list',
        middleware: auth
      },
      component: () => import('./views/ChemicalInventory.vue')
    },
    {
      path: '/test-area',
      name: 'TestArea',
      component: () => import('./views/TestArea.vue')
    },
    {
      path: '/pesquisas',
      name: 'Surveys',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Pesquisas',
        pagetitleicon: 'fa-poll',
        permission: 'admin',
        middleware: auth
      },
      component: () => import('./views/Surveys.vue')
    },
    {
      path: '/renovacoes',
      name: 'Promocodes',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Renovações',
        pagetitleicon: 'fa-receipt',
        permission: 'admin',
        middleware: auth
      },
      component: () => import('./views/Promocodes.vue')
    },
    {
      path: '/tutoriais',
      name: 'Tutorials',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Tutoriais',
        pagetitleicon: 'fa-play',
        middleware: auth
      },
      component: () => import('./views/Tutorials.vue')
    },
    {
      path: '/admin/tutoriais',
      name: 'TutorialsAdmin',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Tutoriais Admin',
        pagetitleicon: 'fa-play',
        middleware: auth
      },
      component: () => import('./views/TutorialsAdmin.vue')
    },
    {
      path: '/log-atualizacao',
      name: 'UpdateLogs',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Atualizador',
        pagetitleicon: 'fa fa-refresh',
        permission: 'admin',
        middleware: auth
      },
      component: () => import('./views/UpdateNew.vue')
    },
    {
      path: '/adicionar-log-atualizacao',
      name: 'AddUpdateLogs',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Atualizador',
        pagetitleicon: 'fa fa-bell',
        permission: 'admin',
        middleware: auth
      },
      component: () => import('./views/AddUpdateNew.vue')
    },
    {
      path: '/relatorio-inventario-quimico',
      name: 'RelatorioInventarioQuimico',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Relatório de Inventário Químico',
        pagetitleicon: 'fa fa-file',
        permission: 'admin',
        middleware: auth
      },
      component: () => import('./views/ChemicalInventoryReport.vue')
    },
    {
      path: '/chave-api',
      name: 'ChaveApi',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Gerar chave para integração',
        pagetitleicon: 'fa-solid fa-key',
        permission: 'admin',
        middleware: auth
      },
      component: () => import('./views/ApiKey.vue')
    },
    {
      path: '/logs-api',
      name: 'LogsAPi',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Logs da api',
        pagetitleicon: 'fa fa-clock',
        permission: 'admin',
        middleware: auth
      },
      component: () => import('./views/LogApi.vue')
    },
    {
      path: '/logs-assinatura-planos',
      name: 'LogsAssinaturaPlanos',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Logs de assinaturas',
        pagetitleicon: 'fa fa-clock',
        permission: 'admin',
        middleware: auth
      },
      component: () => import('./views/LogsAssinaturaPlanos.vue')
    },
    {
      path: '/relatorios-consulta-legal',
      name: 'RelatoriosConsultaLegal',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Relatórios',
        pagetitleicon: 'fas fa-download',
        middleware: auth
      },
      component: () => import('./views/ReportsDownloads')
    },
    {
      path: '/checkout-precos-diferentes',
      name: 'LinkCheckoutsDifferentPrices',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Gerar link de checkout',
        pagetitleicon: 'fa-solid fa-key',
        permission: 'admin',
        middleware: auth
      },
      component: () => import('./views/LinkCheckoutsDifferentPrices.vue')
    },
    {
      path: '/preferencias',
      name: 'Preferencias',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Preferências',
        pagetitleicon: 'fa-user',
        middleware: auth
      },
      component: () => import('./views/UserPreferences.vue')
    },
    {
      path: '/colaboradores',
      name: 'Colaboradores',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Colaboradores',
        pagetitleicon: 'fa-user',
        middleware: auth
      },
      component: () => import('./views/Collaborators.vue')
    },
    {
      path: '/colaboradores/comprarAcessos/:planId',
      name: 'BuySingleAccesses',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Comprar acessos avulso',
        pagetitleicon: 'fa-users',
        middleware: auth
      },
      component: () => import('./views/BuySingleAccesses.vue')
    },
    {
      path: '/atualizar-telefone',
      name: 'AtualizarTelefone',
      component: () => import('./views/LinkUpdatePhone.vue'),
      meta: {
        layout: 'print',
      }
    },
    {
      path: '/indicacao',
      name: 'Indicacao',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Indicações',
        pagetitleicon: 'fa-users',
        middleware: auth
      },
      component: () => import('./views/Indications.vue')
    },
    {
      path: '/minha-conta',
      name: 'MyAccount',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Minha Conta',
        pagetitleicon: 'fa-users',
        middleware: auth
      },
      component: () => import('./views/MyAccount.vue')
    },
    {
      path: '/gerenciar-empresas',
      name: 'ManageCompanies',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Gerenciar empresas',
        pagetitleicon: 'fa-building',
        middleware: auth
      },
      component: () => import('./views/ManageCompanies.vue')
    },
    {
      path: '/funcionalidades',
      name: 'Functionalities',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Gerenciar funcionalidades dos usuários',
        pagetitleicon: 'fa-building',
        middleware: auth
      },
      component: () => import('./views/Functionality.vue')
    },
    {
      path: '/contratos-plataforma',
      name: 'PlatformContracts',
      meta: {
        layout: 'sidebar',
        pagetitle: 'Contratos da plataforma',
        pagetitleicon: 'fa-building',
        middleware: auth
      },
      component: () => import('./views/PlatformContracts.vue')
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.middleware) {
    return to.meta.middleware(to, from, next)
  }
  return next()
})

export default router
