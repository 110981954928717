<template>
  <div
    :class="{ isOpened: isOpened }"
    class="b-modal-container"
    @click.self="$emit('close')"
  >
    <div class="b-modal" :style="maxWidth ? maxWidth : ''">
      <header>
        <span><slot name="header"></slot></span>
        <button class="close-button" :class="{required}" @click="$emit('close')">
          <i class="fas fa-times"></i>
        </button>
      </header>
      <main :style="maxHeight ? maxHeight : ''">
        <slot name="content"></slot>
      </main>
      <footer>
        <slot name="footer"></slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped lang="scss">
.b-modal-container {
  display: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  position: fixed;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  background-color: rgba(0, 0, 0, 0.4);

  z-index: 200;
  &.isOpened {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .b-modal {
    width: 100%;
    max-width: 600px;
    position: relative;
    background-color: white;
    border-radius: 4px;

    header {
      background-color: var(--blue);

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;

      font-size: 24px;
      font-weight: 700;

      color: var(--white);
      border-radius: 4px 4px 0 0;
      span {
        flex-grow: 1;
        text-align: center;
      }
      .close-button {
        &.required {
          display: none;
        }

        width: 32px;
        height: 32px;

        display: flex;
        justify-content: center;
        align-items: center;

        border: 0px;
        background-color: var(--blue);
        opacity: 0.7;

        transition: 0.2s ease-in-out ;

        &:hover {
          opacity: 1;
        }

        .fa-times {
          color: var(--white);
        }
      }
    }

    main {
      max-height: 480px;
      overflow-y: scroll;
    }

  }
}
</style>
