<template>
  <component
    @click="$emit('click')"
    :is="component"
    :class="classes"
    :disabled="disabled">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    asLink: { type: Boolean },
    block: { type: Boolean },
    danger: { type: Boolean },
    outlinePrimary: { type: Boolean },
    outlinePrimaryNavyBlue: { type: Boolean },
    outlineDanger: { type: Boolean },
    dark: { type: Boolean },
    inline: { type: Boolean },
    success: { type: Boolean },
    pulse: { type: Boolean },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    classes() {
      return {
        block: this.block,
        danger: this.danger,
        outlinePrimary: this.outlinePrimary,
        outlineDanger: this.outlineDanger,
        outlinePrimaryNavyBlue: this.outlinePrimaryNavyBlue,
        inline: this.inline,
        success: this.success,
        dark: this.dark,
        pulse: this.pulse,
      }
    },
    component() {
      return this.asLink ? 'a' : 'button'
    },
  },
}
</script>

<style scoped lang="scss">
button,
a {
  display: block;
  width: fit-content;
  color: #212529;

  transition: all 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid transparent;
  border-radius: 4px;

  padding: 6px 12px;

  box-shadow: 0 0 0 0 var(--blue);

  background-color: var(--blue);
  color: var(--white);

  font-weight: 400;
  text-align: center;
  font-size: 16px;

  cursor: pointer;

  &:hover:not(:disabled) {
    opacity: 0.8;
  }

  &:disabled {
    background: #9FA4A8;
    cursor: not-allowed;
  }

  &.block {
    width: 100%;
    text-align: center;
  }

  &.inline {
    display: inline-block;
  }

  &.success:not(:disabled) {
    background-color: var(--green);
    box-shadow: 0 0 0 0 var(--green);
  }

  &.danger:not(:disabled) {
    background-color: var(--red);
    box-shadow: 0 0 0 0 var(--red);

  }
  &.outlinePrimary:not(:disabled) {
    background-color: transparent;
    box-shadow: 0 0 0 0 var(--red);
    border: 1px solid var(--blue);
    color: var(--blue);
    &:hover {
      background-color: var(--blue);
      color: var(--white);
    }
  }
  &.outlinePrimaryNavyBlue:not(:disabled) {
    background-color: transparent;
    box-shadow: 0 0 0 0 #022245;
    border: 1px solid #022245;
    color: #022245;
    font-weight: 700;
    &:hover {
      background-color: #022245;
      color: var(--white);
      svg {
        path {
          fill: #FFFFFF
        }
      }
    }
  }
  &.outlineDanger:not(:disabled) {
    background: #F4DBDC;
    box-shadow: 0 0 0 0 var(--red);
    border: 1px solid #F78E8F;
    color: #F00;
    font-weight: 700;
    height: 40px;
    &:hover {
      background-color: #F00;
      color: var(--white);
    }
  }

  &.dark:not(:disabled) {
    background-color: var(--dark-blue);
    box-shadow: 0 0 0 0 var(--dark-blue);

  }

  &.pulse {
    -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    background-color: var(--green);
    box-shadow: 0 0 0 0 var(--green);

    &:hover {
      -webkit-animation: none;
      -moz-animation: none;
      -ms-animation: none;
      animation: none;
    }
  }
}
@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}
@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}
@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}
</style>
