import api from '../../utils/api'

export const state = {
  smsSent: [],
  smsSentMeta: {},
};

export const mutations = {
  SET_SMS_SENT(state, smsSent) {
    state.smsSent = smsSent;
  },
  SET_SMS_SENT_META(state, smsSentMeta) {
    state.smsSentMeta = smsSentMeta;
  },
}

export const actions = {
  resetSmsSentMetaMeta({ commit }) {
    commit('SET_SMS_SENT_META', {})
  },

  async handleSmsSentMeta({ commit }, payload) {
    let params = {};
    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        filter: payload.filter || undefined,
        skip: payload.skip || undefined,
        limit: payload.limit || undefined,
      }
    }
    const { data: response } = await api.get(`/smsSentLog`, { params });
    if (response && response.data.smsSentLog) {
      commit('SET_SMS_SENT', response.data.smsSentLog);
      commit('SET_SMS_SENT_META', response.meta);
    } else {
      commit('SET_SMS_SENT', []);
      commit('SET_SMS_SENT_META', {});
    }
  },
}

export const getters = {
  getSmsSent: (state) => state.smsSent,
  getSmsSentMeta: (state) => state.smsSentMeta,
}
