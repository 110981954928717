<template>
  <section class='w-100 register p-3'>
    <b-alert
      show
      variant='danger'
      :class='{ hidden: !error }'
    >
      {{ error }}
    </b-alert>
    <section class='mb-3' >
      <img width='80' src='../../../../assets/images/logoCadastroPerfil.svg'/>
    </section>
    <section>
      <p>A <strong>sua idade</strong> está entre qual faixa dentre as opções a seguir?</p>

      <section class='mt-3 mb-3'>
        <section v-for='(age, i) in rangeAges' :key='i' class='d-inline-block mr-3 mb-3'>
          <base-button
            :style="userProfile.age.value === age.value ? 'border: 2px solid var(--blue);' : ''"
            class='btn_quantity_person' @click='selectAgeUser(age)'>
            {{ age.description }}
          </base-button>
        </section>
      </section>

      <p>Qual é o <strong>cargo</strong> que melhor descreve a sua atuação?</p>

      <section class='mb-3'>
        <section v-for='(position, i) in companyPositions' :key='i' class='d-inline-block mr-3 mb-3'>
          <base-button
            :style="userProfile.companyPosition.value === position.value ? 'border: 2px solid var(--blue);' : ''"
            class='btn_quantity_person' @click='selectCompanyPosition(position)'>
            {{ position.description }}
          </base-button>
        </section>
      </section>

      <section class='d-flex justify-content-between align-items-center my-4'>
        <b-progress :value='45' height='10px' variant='primary' class='w-25'></b-progress>
        <b-overlay
          :show='loading'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='d-inline-block'
        >
          <base-button
            :disabled='!userProfile.age.value || !userProfile.companyPosition.value'
            @click='next()'
            class='btn mt-1'>
            <span class='text_btn'>Continuar</span>
          </base-button>
        </b-overlay>
      </section>
    </section>
  </section>
</template>

<script>

export default {
  namer: 'AgePositionCompany',
  components: {},
  computed: {
    userProfile() {
      return this.$store.getters['user/userProfile']
    },
    message() {
      return this.$store.getters['access/message']
    },
    error() {
      return this.$store.getters['access/error']
    }
  },
  data() {
    return {
      loading: false,
      rangeAges: [
        {
          value: 1,
          description: '16 a 24 anos'
        },
        {
          value: 2,
          description: '25 a 30 anos'
        },
        {
          value: 3,
          description: '31 a 35 anos'
        },
        {
          value: 4,
          description: '36 a 40 anos'
        },
        {
          value: 5,
          description: '41 a 45 anos'
        },
        {
          value: 6,
          description: '46 a 50 anos'
        },
        {
          value: 7,
          description: '51 anos ou mais'
        }
      ],
      companyPositions: [
        {
          value: 1,
          description: 'Engenheiro de Segurança do Trabalho'
        },
        {
          value: 2,
          description: 'Higienista Ocupacional'
        },
        {
          value: 3,
          description: 'Diretor/Coordenador/Gerente de SST'
        },
        {
          value: 4,
          description: 'Técnico de Segurança do Trabalho'
        },
        {
          value: 5,
          description: 'Médico do Trabalho'
        }
      ]
    }
  },
  validations() {
    return {}
  },
  created() {
    this.$store.dispatch('access/updateError', '')
    this.$store.dispatch('access/updateMessage', '')
  },
  async mounted() {
  },
  methods: {
    selectAgeUser(ageUser) {
      if (ageUser.value === this.userProfile.age.value) {
        this.userProfile.age = {
          value: null,
          description: null
        }
        return
      }
      this.userProfile.age = ageUser
    },

    selectCompanyPosition(position) {
      if (position.value === this.userProfile.companyPosition.value) {
        this.userProfile.companyPosition = {
          value: null,
          description: null
        }
        return
      }
      this.userProfile.companyPosition = position
    },
    next() {
      this.$emit('next', 4)
    }
  }
}
</script>

<style scoped>
.text_btn {
  font-weight: 600;
}

.btn {
  width: 130px;
  height: 45px;
  border-radius: 5px;
  border: none;
}

.btn:hover {
  color: white;
}

.btn_quantity_person {
  background: transparent;
  border-radius: 20px;
  color: #48626f;
  border: 2px solid #C1D5E4;
}

.btn_quantity_person:hover {
  border-color: var(--blue);
}
</style>
