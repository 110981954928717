import api from '../../utils/api'

export const state = {
  userPreferences:{},
}

export const mutations = {
}
export const actions = {
  async handleGetUserPreferences({ commit }, userId) {
    const { data: response } = await api.get(`/user-preferences/${userId}`);
    return response
  },
  async handleCreateUserPreferences({ commit }, payload) {
    return await api.post('/user-preferences', payload);
  },
  async handleChangUserPreferences({ commit }, payload) {
    return await api.put(`/user-preferences/${payload._id}`, payload);
  },
}

export const getters = {
}
