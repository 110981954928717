<template>
  <div style='position: relative;' v-if='notifications && notifications.length > 0'>
    <b-modal
      header-bg-variant="info"
      header-text-variant="light"
      ref='show-log'
      size='lg'
      scrollable
      hide-footer
      title='Adicionar comentário'
    >
      <add-comment @close='close' @comment='addComment' />
    </b-modal>

    <section class='box' id='arrow_ball'>
      <div
        v-if='arrowBallLeft'
        class='arrow-ball-left'
        :style="pointerEvents ? '  pointer-events: none;': ''"
        @click='scrollClick(-1)'
      >
        <i
          style='color: #fff; font-size: 1.5rem'
          class='fas fa-angle-left'
        >
        </i>
      </div>

      <section
        v-for='(notification, i) in notifications'
        :key='i'
      >
        <section class='box-child'>
          <section class='d-flex justify-content-between'>
            <section class='pr-1 flex-wrap'>
              <strong v-if='notification.title.length < 32' class='notification-title '>{{ notification.title }}</strong>
              <strong v-else class='notification-title '>{{ notificationTitleHtml(notification.title) }}
                <a class='cliqueLerTudo' @click='readFullNotification(notification)'> ler mais</a>
              </strong>
            </section>

            <section >
              <img v-if='notification.like' class='mr-2' @click='removeLike(notification.like._id)'
                   src='@/assets/images/like.svg' />

              <img v-else class='mr-2' @click='addLike(notification._id)' src='@/assets/images/leaveYourLike.svg' />

              <img v-if='notification.disLike' @click='removeDisLike(notification.disLike._id)' class='mr-3'
                   src='@/assets/images/disLike.svg' />

              <img v-else class='mr-2' @click='addDisLike(notification._id)'
                   src='@/assets/images/leaveYourDislike.svg' />

              <img class='mr-2' @click='showModalAddComment(notification)' src='@/assets/images/writeComment.svg' />

              <b-icon @click='doNotShowNewUpdate(notification._id)' style="color: #FF0000; cursor: pointer;" font-scale='1.2' class='mr-2' icon='x-circle'></b-icon>
            </section>
          </section>

          <span class='notification-date'>{{ dateParse(notification.createdAt) }}</span><br />
          <section class='notification-description'>
            <span class='description'>{{ notificationHtml(notification.description) }}</span>
            <a class='cliqueLerTudo' @click='readFullNotification(notification)'> Clique para ler tudo</a>
          </section>
        </section>
      </section>

      <div
        v-if='arrowBallRight'
        :style="pointerEvents ? '  pointer-events: none;': ''"
        class='arrow-ball-right'
        @click='scrollClick(1)'
      >
        <i
          style='color: #fff; font-size: 1.5rem'
          class='fas fa-angle-right'
        ></i>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AddComment from '@/components/UpdateNew/AddComment'

export default {
  name: 'Notification',
  components: {
    AddComment
  },
  computed: {
    ...mapGetters('breadcrumb', {
      breadcrumb: 'getBreadcrumb'
    }),
    ...mapGetters('updateNews', {
      listOfUpdateLogsForUserView: 'getListOfUpdateNewForUserView',
      listOfUpdateLogsForUserViewMeta: 'getListOfUpdateLogsForUserViewMeta'
    })
  },
  data() {
    return {
      notifications: [],
      notification: null,
      revertArrow: false,
      pointerEvents: false,
      logUpdate: null,
      boxNotification: null,
      arrowBallLeft: false,
      arrowBallRight: true,
      clicks: 0,
      mesesAnos: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'out', 'Nov', 'Dez']
    }
  },
  async mounted() {

    await this.getNotifications()
  },
  methods: {
    ...mapActions('updateNews', {
      handleAddLike: 'handleAddLike',
      handleRemoveLike: 'handleRemoveLike',
      handleAddDisLike: 'handleAddDisLike',
      handleDoNotShowNewUpdate: 'handleDoNotShowNewUpdate',
      handleRemoveDisLike: 'handleRemoveDisLike',
      handleCreateComment: 'handleCreateComment',
      handleGetUpdatesNewsLastThirtyDays: 'handleGetUpdatesNewsLastThirtyDays',
      handleSaveUserSawReadNewUpdate: 'handleSaveUserSawReadNewUpdate'
    }),
    async getNotifications() {
      try {
        const { updatesNewsUsers } = await this.handleGetUpdatesNewsLastThirtyDays()
        this.notifications = updatesNewsUsers
      } catch (e) {
      }
    },

    async addLike(notificationId) {
      try {
        await this.handleAddLike(notificationId)
        await this.getNotifications()
      } catch (e) {
      }

    },

    async removeLike(notificationId) {
      try {
        await this.handleRemoveLike(notificationId)
        await this.getNotifications()
      } catch (e) {
      }
    },

    async doNotShowNewUpdate(notificationId) {
      try {
        await this.handleDoNotShowNewUpdate(notificationId)
        await this.getNotifications()
      } catch (e) {
      }

    },

    async addDisLike(notificationId) {
      try {
        await this.handleAddDisLike(notificationId)
        await this.getNotifications()
      } catch (e) {
      }

    },

    async removeDisLike(notificationId) {
      try {
        await this.handleRemoveDisLike(notificationId)
        await this.getNotifications()
      } catch (e) {
      }
    },

    async addComment(comment) {
      const { data, status } =  await this.handleCreateComment({ comment, _id: this.notification._id })
      let message = data.message
      let variant = 'danger'

      if (status === 201) {
        message = data.message
        variant = 'success'
      }

      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      this.close()
    },

    notificationHtml(html) {
      const htmlParse = new DOMParser().parseFromString(html, 'text/html')
      let notification = htmlParse.body.textContent || ''
      if (htmlParse.body.textContent && htmlParse.body.textContent.length > 170) {
        notification = htmlParse.body.textContent.substring(0, 160).trim()
      }
      return notification += `... `
    },
    notificationTitleHtml(html) {
      const htmlParse = new DOMParser().parseFromString(html, 'text/html')
      let notification = htmlParse.body.textContent || ''
      if (htmlParse.body.textContent && htmlParse.body.textContent.length > 32) {
        notification = htmlParse.body.textContent.substring(0, 32).trim()
      }
      return notification += `... `
    },

    async readFullNotification(notification) {
      this.$emit('readFullNotification', notification._id)
      await this.handleSaveUserSawReadNewUpdate(notification._id)
    },

    dateParse(registrationDate) {
      const date = new Date(registrationDate)
      return `${date.getDate()} ${this.mesesAnos.find((x, index) => index === date.getMonth())}. de ${date.getFullYear()}`
    },

    scrollClick(direcao) {
      this.boxNotification = document.getElementById('arrow_ball')
      this.pointerEvents = true
      setTimeout(() =>{
        this.boxNotification.scrollLeft += 520 * direcao;
        this.clicks += 1 * direcao;
        this.arrowBallRight = this.clicks !== this.notifications.length - 1;
        this.arrowBallLeft = this.clicks !== 0;
        this.pointerEvents = false
      }, 100)
    },

    showModalAddComment(notification) {
      this.notification = notification
      this.show()
    },

    show() {
      this.$refs['show-log'].show()
    },

    close() {
      this.$refs['show-log'].hide()
    }
  }
}
</script>
<style scoped>
img {
  cursor: pointer;
}

.notification-description >>> .cliqueLerTudo {
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #022245;
}
.notification-title >>> .cliqueLerTudo {
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #808080;
}

.notification-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #022245;
  word-wrap: break-word;
  text-overflow: ellipsis
}

.notification-date {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #808080;
}

.notification-description {
  width: 480px;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.notification-description::after {
}

.box {
  width: 550px;
  border: 1px solid #022245;
  display: flex;
  align-items: center;
  border-radius: 7px;
  padding: 10px 0;
  overflow: hidden;
  scroll-behavior: smooth;
  height: 150px;
}

.box::-webkit-scrollbar {
  display: none;
}

.box-child {
  border-radius: 5px;
  border: .5px solid #808080;
  padding: 5px 10px;
  width: 500px;
  margin: 0 10px;
  min-height: 130px;
  overflow: hidden;
}

.arrow-ball-right {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 55px;
  right: 18px;
  width: 35px;
  height: 35px;
  background-color: #022245;
  border-radius: 50%;
  cursor: pointer;
}

.arrow-ball-left {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 55px;
  right: 525px;
  background-color: #022245;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
}
</style>
