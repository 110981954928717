import api from '../../utils/api'

export const state = {

}

export const mutations = {

}

export const actions = {
  async handleCreate(_, unit) {
    try {
      return await api.post('/unit', unit)
    } catch (err) {

    }
  },

  async handleGet(_, unitId) {
    try {
      return await api.get(`/unit/${unitId}`)
    } catch (err) {

    }
  },


  async handleOpen(_, unitId) {
    try {
      return await api.put(`/unit/open/${unitId}`)
    } catch (err) {

    }
  },

  async handleUpdade(_, unit) {
    try {
      return await api.put(`/unit/${unit._id}`, unit)
    } catch (err) {

    }
  },

  async handleDelete(_, unitId) {
    try {
      return await api.delete(`/unit/${unitId}`)
    } catch (err) {

    }
  },

}

export const getters = {

}
