import api from '../../utils/api'

export const state = {
  event: {
    _id: '',
    title: '',
    description: '',
    link: '',
    active: null
  },
  eventActive: undefined
}

export const mutations = {
  SET_EVENT(state, event) {
    state.event = event
  },
  SET_EVENT_ACTIVE(state, eventActive) {
    state.eventActive = eventActive
  }
}

export const actions = {
  async handleCreateEvent({ state }) {
    const event = state.event
    return await api.post('/event', event)
  },

  async handleEnableOrDisableEvent({ state }, id) {
    return await api.post('/event/enableOrDisable', { id })
  },

  async handleUpdateEvent({ state }) {
    const event = state.event
    return await api.put(`/event/${event._id}`, event)
  },


  async handlDeleteEvent({ commit }, id) {
    return await api.delete(`/event/${id}`)
  },

  async handlGetEvents({ commit }) {
    const { data: response } = await api.get(`/event/events`)
    return response
  },

  async handlGetEvent({ commit }, id) {
    const { data: response } = await api.get(`/event/${id}`)
    commit('SET_EVENT', response.event)
    return response
  },

  async handlGetEventActive({ commit }) {
    const { data: response } = await api.post(`/event/active`)
    const { event } = response
    commit('SET_EVENT_ACTIVE', event)

    return response
  }
  ,
  async resetEvent({ commit }) {
    commit('SET_EVENT', {
      _id: null,
      title: '',
      description: '',
      link: '',
      active: null
    })
  }
}

export const getters = {
  getEvent: (state) => state.event,
  eventActive: (state) => state.eventActive
}
