<template>
  <div
    class="t-input-text"
    :class="{
      block: block,
      row: direction === 'row',
      column: direction === 'column',
    }">

    <b-tooltip v-if="textTooltip" :target="idTooltip" triggers="hover">
      {{ textTooltip }}
    </b-tooltip>

    <label v-if="label">
      {{ label }}
      <i v-if="icon" :class="icon" :id="idTooltip"/>
      <slot name="icon-label" />
    </label>
    <input
      :class="{ error: !!error, block: block }"
      :type="type"
      :value="modelValue"
      :placeholder="placeholder"
      @input="changeValue"
      @keypress="preventWhenInt"
      @blur="saveBlur"
      :max="max"
      :min="min"
      :disabled="disabled"
    />

    <span>
      {{ error }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'BaseInputText',
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  props: {
    label: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: [Number, String],
      default: ''
    },
    placeholder: {
      type: String
    },
    int: {type: Boolean, default: false},
    error: { type: String, default: '' },
    block: { type: Boolean, default: false },
    direction: { type: String, default: 'column' },
    max: { type: Number, default: 10000 },
    min: { type: Number, default: 0 },
    disabled: {type: Boolean, default: false},
    icon: {
      type: String,
      default: null,
    },
    textTooltip: {
      type: String,
      default: null,
    },
    idTooltip: {
      type: String,
      default: null,
    },
  },
  methods: {
    preventWhenInt(event){
      if(this.type === 'number'){
        if(this.int && (event.key === '.' || event.key === ',')){
          event.preventDefault()
        }
      }
    },
    changeValue(event){
      if(this.type === 'number'){
        if(event.target.value < this.min ){
          event.target.value = this.min
          return
        }
        else if(event.target.value > this.max){
          event.target.value = this.max
          return
        }
      }
      this.$emit('input', event.target.value)
    },
    saveBlur() {
      this.$emit('saveBlur')
    },
  }
}
</script>

<style scoped lang="scss">
.t-input-text {
  display: flex;
  flex-direction: column;

  &.row {
    flex-direction: row;
    align-items: flex-end;
    gap: 8px;
  }

  &.block {
    width: 100%;
  }

  label {
    font-size: 18px;
    font-weight: 700;
  }

  input {
    height: 48px;

    font-size: 16px;

    padding-left: 16px;
    padding-right: 16px;

    border: 1px solid var(--border-input);
    border-radius: 4px;

    outline: none !important ;

    cursor: pointer;

    &:hover {
      background: var(--light-gray);
    }

    &:focus {
      border-color: var(--blue);
    }

    &.error {
      border-color: var(--red);
    }

    &.block {
      width: 100%;
    }
  }

  span {
    font-weight: 400;
    color: var(--red);
    padding-left: 8px;
  }
}
</style>
