import api from '../../utils/api'
import CreditCard from '../../models/Payment/CreditCard'

export const state = {
  creditCard: new CreditCard(),
  error: undefined,
  signedPlan: null,
  freeTrialSubscription: {}
}

export const mutations = {
  SET_ERROR(state, data) {
    state.error = data
  },
  SET_Plan_SIGNED(state, signedPlan) {
    state.signedPlan = signedPlan
  },
  SET_FREETRIAL_SUBSCRIPTION(state, freeTrialSubscription) {
    state.freeTrialSubscription = freeTrialSubscription
  }
}

export const actions = {
  async cancel({ commit }, data) {
    return ({ data } = await api.post(`/plans/cancel`, data))
  },
  async checkIfTheUserHasSignedPlan({ commit }) {
    const { data } = await api.post(`/users/checkplan`)
    commit('SET_Plan_SIGNED', data);
  },
  async checkIfFreeTrialSubscription({ commit }) {
    const { data } = await api.post(`/users/checkplan2`)
    commit('SET_FREETRIAL_SUBSCRIPTION', data);
  }
}

export const getters = {
  creditCard: (state) => state.creditCard,
  error: (state) => state.error,
  getSignedPlan: (state) => state.signedPlan,
  getFreeTrialSubscription: (state) => state.freeTrialSubscription,
}
