import { AbilityBuilder } from '@casl/ability'

function updateAbilities(user) {
  return AbilityBuilder.define((can) => {
    if (user) {
      let abilitiesArray = []
      user.features.forEach((element) => {
        abilitiesArray.push(element.name)
      })
      can(user.role, abilitiesArray)
    }
  })
}

export default updateAbilities
