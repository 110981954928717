<template>
  <section v-if='limitationOfAgentsAdded' class='limitationOfAgentsAdded'>
    <span v-if="limitationOfAgentsAdded.plan === 'TRIAL'">Limite de <strong>{{ limitationOfAgentsAdded.limitation }}</strong> agentes por dia</span>
    <span v-else>Limite de <strong>{{ limitationOfAgentsAdded.limitation }}</strong> agentes por mês</span>

    <p>Agentes pesquisados: <strong> {{ limitationOfAgentsAdded.added }} / {{limitationOfAgentsAdded.limitation}}</strong></p>

    <span v-if="limitationOfAgentsAdded.plan === 'TRIAL'" class='mt-5'>
      Para pesquisar mais agentes clique sobre o texto a seguir para conferir os planos disponíveis:
      <a href='/planos'> Meus Planos </a>
    </span>
    <span v-else >
      Para pesquisar mais agentes clique no texto a seguir para
      <a href="/checkout?plano=AN1HO"> Adquirir um plano anual</a>
    </span>
  </section>
</template>

<script>
import {  mapGetters } from 'vuex'

export default {
  name: 'LimitationOfAgentsAdded',
  components: {},
  computed: {
    ...mapGetters('userPlans', {
      limitationOfAgentsAdded: 'limitationOfAgentsAdded'
    })
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  methods: {

  }
}
</script>
<style scoped>
.limitationOfAgentsAdded{
  font-size: 14px;
}
</style>
