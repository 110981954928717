import api from '../../utils/api'
import format from 'date-fns/esm/format/index'
export const state = {
  linkCheckoutsDifferentPrices: {
    url: '',
    code: '',
    price: '',
    plan: null,
    active: true,
  },
  linksCheckoutsDifferentPrices: [],
  linkCheckoutsDifferentPricesMeta: {},
};

export const mutations = {
  SET_LINKCHECKOUTSDIFFERENTPRICES(state, linkCheckoutsDifferentPrices) {
    state.linkCheckoutsDifferentPrices = linkCheckoutsDifferentPrices;
  },
  SET_LINKSCHECKOUTSDIFFERENTPRICES(state, linksCheckoutsDifferentPrices) {
    state.linksCheckoutsDifferentPrices = linksCheckoutsDifferentPrices;
  },
  SET_LINKSCHECKOUTSDIFFERENTPRICES_META(state, linkCheckoutsDifferentPricesMeta) {
    state.linkCheckoutsDifferentPricesMeta = linkCheckoutsDifferentPricesMeta;
  },
}

export const actions = {
  async handleCreateLinkCheckoutsDifferentPrices({ state }) {
    const body = state.linkCheckoutsDifferentPrices;
    const { data } = await api.post(`/link-checkouts-different-prices`, body);
    return data;
  },

  async handleGetLinksCheckoutsDifferentPrices({ commit }, payload) {
    let params = {};

    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        skip: payload.skip || undefined,
        limit: payload.limit || undefined,
        filter: payload.filter || undefined,
      }
    }
    const { data: response } = await api.get(`/link-checkouts-different-prices/all`, { params });
    if (response && response.data.linksCheckoutsDifferentPrices) {
      commit('SET_LINKSCHECKOUTSDIFFERENTPRICES', response.data.linksCheckoutsDifferentPrices);
      commit('SET_LINKSCHECKOUTSDIFFERENTPRICES_META', response.meta);
    } else {
      commit('SET_LINKSCHECKOUTSDIFFERENTPRICES', []);
      commit('SET_LINKSCHECKOUTSDIFFERENTPRICES_META', {});
    }
  },

  async handleLinksCheckoutsDifferentPrices({ commit }, _id) {
    const { data: response } = await api.get(`/link-checkouts-different-prices/${_id}`);
    if (response && response.data) {
      commit('SET_LINKCHECKOUTSDIFFERENTPRICES', response.data);
    }
    return response;
  },
  async handleSearchPriceLinkByCode({ commit }, code) {
    const { data: response } = await api.post(`/link-checkouts-different-prices/searchPriceLinkByCode`, {code});

    return response;
  },

  async handleGetEmailPartner({ state }) {
    const body = state.partner;
    const { data: response } = await api.get(`/api-key-partners/email/${body.email}`);
    return response;
  },

  async handleUpdatePartner({ state }, _id) {
    const body = state.partner;
    const { data } = await api.put(`/api-key-partners/${_id}`, body);
    return data;
  },

  async handleUpdateLinksCheckoutsDifferentPrices({ state }, _id) {
    const body = state.linkCheckoutsDifferentPrices;
    const { data } = await api.put(`/link-checkouts-different-prices/${_id}`, body);
    return data;
  },

  resetLinksCheckoutsDifferentPrices({ commit }) {
    commit('SET_LINKCHECKOUTSDIFFERENTPRICES', {
      url: '',
      code: '',
      price: '',
      active: '',
      plan: '',
      _id: ''
    })
  },

  async handleDeleteLinksCheckoutsDifferentPrices({ state }, _id) {
    const { data } = await api.delete(`/link-checkouts-different-prices/${_id}`);
    return data;
  },

  resetUpdateLog({ commit }) {
    commit('SET_UPDATE_LOG', {
      title: '',
      description: '',
      registrationDdate: ''
    })
  },
}

export const getters = {
  getLinkCheckoutsDifferentPrices: (state) => state.linkCheckoutsDifferentPrices,
  getLinksCheckoutsDifferentPrices: (state) => state.linksCheckoutsDifferentPrices,
  getLinkCheckoutsDifferentPricesMetaMeta: (state) => state.linkCheckoutsDifferentPricesMeta,
}
