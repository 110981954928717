<template>
  <div class="t-input-select">
    <b-tooltip v-if="textTooltip" :target="idTooltip" triggers="hover" placement="top">
      {{ textTooltip }}
    </b-tooltip>

    <label v-if="label">
      {{ label }}
      <i v-if="icon" :class="icon" :id="idTooltip"/>
    </label>
    <select
      @input="$emit('input', $event.target.value)"
      :disabled="disable"
      @change="$emit('change')"
    >
      <option value="" disabled :selected="!modelValue">
        {{ placeholder }}
      </option>
      <option
        v-for="(option, index) in options"
        :selected="modelValue === option[_key]"
        :value="option[_key]"
        :key="`${index}`"
      >
        {{ option[_value] }}
      </option>
    </select>
    <span>
      {{ error }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'BaseInputSelect',
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    _key: {
      type: String,
      required: false,
      default: '_id',
    },
    placeholder: {
      type: String,
    },
    _value: {
      type: String,
      default: 'value',
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    modelValue: {
      type: String,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    error: { type: String, default: '' }  ,
    textTooltip: {
      type: String,
      default: null,
    },
    idTooltip: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped lang="scss">
.t-input-select {
  display: flex;
  flex-direction: column;

  label {
    font-size: 18px;
    font-weight: 700;
  }

  select {
    height: 48px;

    font-size: 16px;

    padding-left: 16px;
    padding-right: 16px;

    border: 1px solid var(--border-input);
    border-radius: 4px;

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: var(--white);

    cursor: pointer;

    &:hover {
      background: var(--light-gray);
    }
    &:disabled {
      cursor: not-allowed;
    }
    &:focus {
      border-color: var(--blue);
    }

    &.error {
      border-color: var(--red);
    }
  }

  span {
    font-weight: 400;
    color: var(--red);
    padding-left: 8px;
  }
}

.options {
  font-size: 16px;
  font-weight: 400;
  background: var(--white);

  &:hover {
    background: var(--blue);
  }
}
</style>
