import api from '../../utils/api'

export const state = {
  chemicalAgentsResearched:[],
  chemicalAgentsResearchedMeta:{},
}

export const mutations = {
  SET_CHEMICALAGENTSRESEARCHED(state, chemicalAgentsResearched) {
    state.chemicalAgentsResearched = chemicalAgentsResearched;
  },
  SET_CHEMICALAGENTSRESEARCHED_META(state, chemicalAgentsResearchedMeta) {
    state.chemicalAgentsResearchedMeta = chemicalAgentsResearchedMeta;
  }
}

export const actions = {
  resetLogsChemicalAgentsResearchedMeta({ commit }) {
    commit('SET_CHEMICALAGENTSRESEARCHED_META', {})
  },
  async createUserActionLogs(_, log) {
    await api.post(`/userActionLogs`, log)
  },

  async handleGetChemicalAgentsResearched({ commit }, payload) {
    let params = {};

    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        filter: payload.filter || undefined,
        action: payload.action || undefined,
        skip: payload.skip || undefined,
        limit: payload.limit || undefined,
      }
    }
    const { data: response } = await api.get('/chemical-agents-researched', { params });
    if (response && response.agentsResearched && response.agentsResearched.data && response.agentsResearched.data.agentsResearched) {
      commit('SET_CHEMICALAGENTSRESEARCHED', response.agentsResearched.data.agentsResearched);
      commit('SET_CHEMICALAGENTSRESEARCHED_META', response.agentsResearched.meta);
    } else {
      commit('SET_CHEMICALAGENTSRESEARCHED', []);
      commit('SET_CHEMICALAGENTSRESEARCHED_META', {});
    }
  },

  async exportChemicalAgentsResearched( { state }) {
    const payload = state.chemicalAgentsResearchedMeta
    let params = {}
    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        filter: payload.filter || undefined,
      }
    }
    const { data: response } = await api.get(`/chemical-agents-researched/csv`, { params });
    return response.data.chemicalAgentsResearched
  },
}

export const getters = {
  getChemicalAgentsResearched: (state) => state.chemicalAgentsResearched,
  getChemicalAgentsResearchedMeta: (state) => state.chemicalAgentsResearchedMeta,
}
