<template>
  <section v-if='breadcrumb'>
    <span class='page'>{{ breadcrumb.page }}</span> <br />
    <section class='d-flex'>
    <span
      v-if='breadcrumb.subPage && breadcrumb.subPage.name'
      :style="breadcrumb.subPage.active ? 'color: #04A8F3;': ''"
      class='sub-page'>{{ breadcrumb.subPage.name }}</span>
      <section v-for='(tab, i) in breadcrumb.tabs' :key='i'>

        <span > &ensp;> <span :style="tab.active ? 'color: #04A8F3;': ''" class='tab'>{{ tab.name }}</span></span>
      </section>
    </section>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Breadcrumb',
  computed: {
    ...mapGetters('breadcrumb', {
      breadcrumb: 'getBreadcrumb'
    })
  }
}
</script>
<style scoped>
.page {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: #04A8F3;
}

.sub-page {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #5F6368;
}

.tab {
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  color: #5F6368;
}
</style>
