<template>
  <div>
    <b-modal
      ref='modalnewUpdate'
      id='modalnewUpdate'
      title='Atualizações'
      hide-footer
      size='lg'
      header-bg-variant='info'
      header-text-variant='light'
      @show='init'
    >
      <b-overlay
        :show='loading'
        rounded
        opacity='0.7'
        spinner-small
        spinner-variant='primary'
        class='w-100'
      >
        <section class='modal-font-family modal-see-survey'>
          <b-modal
            ref='show-log'
            size='lg'
            scrollable
            hide-footer
            title='Atualização'
            header-bg-variant='info'
            header-text-variant='light'
          >
            <section class='modal-font-family'>
              <add-update-new :readOnly='true' :log='updateNew' />
            </section>
          </b-modal>
          <b-list-group>
            <b-list-group-item
              button
              v-for='(updateNew, i) in listOfUpdateNewsForUserView'
              :key='i'
              class='ma-0 pa-0'
            >
            <span
              @click='showModal(updateNew)'
              :style="checkUserReadUpdateNew(updateNew) ? 'font-weight : bold' : ''"
              class='log'
            >
              Data: {{ updateNew.registrationDate }} - Nome: {{ updateNew.title }}
            </span>
            </b-list-group-item>
          </b-list-group>
          <p
            v-if='listOfUpdateNewsForUserView.length < totalItens'
            @click='viewMore()'
            class='view-more mt-3'
          >
            Ver mais
          </p>
        </section>

        <template v-slot:overlay>
          <div class='text-center loading'>
            <b-spinner variant='primary' class='ml-3' label='Large Spinner'></b-spinner>
            <h6 class='text-primary'>Buscando...</h6>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>


<script>
import format from 'date-fns/esm/format/index'
import { mapActions, mapGetters } from 'vuex'
import AddUpdateNew from '@/components/UpdateNew/AddUpdateNew'

export default {
  async mounted() {
    this.user = this.$store.getters['user/current']
  },
  data() {
    return {
      loading: false,
      totalItens: 0,
      skip: 1,
      user: '',
      updateNew: '',
      openDialogModalSeeUpdateLogs: false
    }
  },
  name: 'ModalSeeUpdaNew',
  props: {
    isOpen: { type: Boolean, default: false }
  },
  components: {
    AddUpdateNew
  },
  computed: {
    ...mapGetters('updateNews', {
      listOfUpdateNewsForUserView: 'getListOfUpdateNewsForUserView',
      listOfUpdateNewsForUserViewMeta: 'getListOfUpdateNewsForUserViewMeta'
    })
  },
  methods: {
    ...mapActions('updateNews', {
      handleGetListUpdatesNews: 'handleGetListUpdatesNews',
      handleAddUserReadUpdatesNew: 'handleAddUserReadUpdatesNew'
    }),

    closeModal() {
      this.$emit('close')
    },

    async showModal(item) {
      this.updateNew = item._id
      const response = await this.handleAddUserReadUpdatesNew(item._id)
      this.$refs['show-log'].show()

      if (response.status === 200) {
        if (!item.userReadNewUpdates.find((x) => x.user === this.user.id)) {
          item.userReadNewUpdates.push({ user: this.user.id })
        }
      }
    },

    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy')
    },

    checkUserReadUpdateNew(log) {
      return !log.userReadNewUpdates.find((x) => x.user === this.user.id)
    },

    async init() {
      this.loading = true
      const params = {
        skip: 0
      }
      await this.getListOfUpdateNewForUserView(params)
      this.loading = false
    },

    viewMore() {
      this.loading = true
      this.skip++
      const params = {
        skip: (this.skip - 1) * this.listOfUpdateNewsForUserViewMeta.limit
      }
      this.getListOfUpdateNewForUserView(params)
      this.loading = false
    },

    async getListOfUpdateNewForUserView(payload) {
      const params = {
        ...this.listOfUpdateNewsForUserViewMeta,
        ...payload
      }
      await this.handleGetListUpdatesNews(params)
      this.totalItens = this.listOfUpdateNewsForUserViewMeta.total
    }
  }
}
</script>

<style lang='scss' scoped>
.view-more {
  text-align: right;
  cursor: pointer;
  color: var(--blue);
  font-width: bold;
  &:hover {
    text-decoration: underline;
  }
}

.log {
  cursor: pointer;
  padding: 5px 0;
  font-size: 18px;
  color: #212529;
}

.modal-see-survey {
  min-height: 100px;
}
</style>
