import api from '../../utils/api'

export const state = {
  activecampaignTagSent: [],
  activecampaignTagSentMeta: {},
};

export const mutations = {
  SET_ACTIVECAMPAIGNTAGSENT(state, activecampaignTagSent) {
    state.activecampaignTagSent = activecampaignTagSent;
  },
  SET_ACTIVECAMPAIGNTAGSENT_META(state, activecampaignTagSentMeta) {
    state.activecampaignTagSentMeta = activecampaignTagSentMeta;
  },
}

export const actions = {
  resetActivecampaignTagSentMeta({ commit }) {
    commit('SET_ACTIVECAMPAIGNTAGSENT_META', {})
  },
  async handleActivecampaignTagSent({ commit }, payload) {
    let params = {};
    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        filter: payload.filter || undefined,
        skip: payload.skip || undefined,
        limit: payload.limit || undefined,
      }
    }
    const { data: response } = await api.get(`/activecampaignTagSent`, { params });

    if (response && response.data.activecampaignTagSent) {
      commit('SET_ACTIVECAMPAIGNTAGSENT', response.data.activecampaignTagSent);
      commit('SET_ACTIVECAMPAIGNTAGSENT_META', response.meta);
    } else {
      commit('SET_ACTIVECAMPAIGNTAGSENT', []);
      commit('SET_ACTIVECAMPAIGNTAGSENT_META', {});
    }
  },
}

export const getters = {
  getActivecampaignTagSent: (state) => state.activecampaignTagSent,
  getActivecampaignTagSentMeta: (state) => state.activecampaignTagSentMeta,
}
