<template>
  <div class="tooltip-box">
    <slot name="body" />
    <div class="message">
      <slot name="message" />
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.tooltip-box {
  position: relative;
  display: inline-block;
  &:hover {
    .message{
      display: block;
    }
  }
  .message {
    color: #ffffff;
    text-align: center;
    padding: 16px;
    border-radius: 2px;

    min-width: 364px;
    bottom: 100%;
    left: 50%;

    display: none;
    transition: opacity 1s;

    position: absolute;
    z-index: 1;

    background: var(--sapphire-blue);
  }

}
</style>
