import api from '../../utils/api'

export const state = {
  userRegister: {
    username: '',
    password: '',
    confirmPassword: '',
    phone: '',
  }
}

export const mutations = {
  SET_REGISTER(state, data) {
    state.userRegister = data
  },
}

export const actions = {
  handleResetRegister: ({ commit }) => commit('SET_REGISTER', {
    username: '',
    password: '',
    passwordConfirm: '',
    phone: '',
  }),
  async handleRegisterUser({ commit }, token) {
    const user = state.userRegister
    user.createdAccountAtCheckout = true;
    user.phone = user.phone.replace(/[^\d]/g, '')
    user.token = token
    const { data } = await api.post('/users', user)

    return data
  },
  async handleSaveLocalStorageSessionId(_, sessionId) {
    localStorage.setItem("sessionId", sessionId);
  },
  async handleSaveLocalStorageToken(_, tokenHofacil) {
    localStorage.setItem("tokenHofacil", tokenHofacil);
  }
}

export const getters = {
  getUserRegister: (state) => state.userRegister,
}
