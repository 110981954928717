import axios from 'axios'
import { decrypt } from '@/utils/EncryptDecryptData'

const hubDesenvolvedor = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 180000 /* 3 minutes */,
  headers: {},

})
export const verifyCPFExists = async (cpf, birthDate) => {
  try {
    return new Promise((resolve, reject) => {
      hubDesenvolvedor.get(`/service/cpf/${cpf}/${birthDate}`)
        .then((data) => {
          const {data: response} = data
          data.data = decrypt(response)
          return resolve(data)
        })
        .catch((error) => {
          return resolve(error.response)
        })
    })
  } catch (error) {
    console.log(error)
  }
}
export const verifyCNPJExists = async (cnpj) => {
  try {
    return new Promise((resolve, reject) => {
      hubDesenvolvedor.get(`/service/cnpj/${cnpj}`)
        .then((data) => {
          const {data: response} = data
          data.data = decrypt(response)
          return resolve(data)
        })
        .catch((error) => {
          return resolve(error.response)
        })
    })
  } catch (error) {
    console.log(error)
  }
}

export const consultCEP = async (cep) => {
  try {
    return new Promise((resolve, reject) => {
      hubDesenvolvedor.post(`cep3/?cep=${cep}&token=${process.env.HUB_DEV_TOKEN}`)
        .then((data) => {
          return resolve(data)
        })
        .catch((error) => {
          return resolve(error.response)
        })
    })
  } catch (error) {
    console.log(error)
  }
}
