<template>
  <div :class="`${type}`">
    <span v-if="message">{{message}}</span>
    <i class="fas fa-times" @click="$emit('close')"/>
  </div>
</template>

<script>
export default {
  name: 'BaseAlert',
  props: {
    block: { type: Boolean },
    message: { type: String },
    type: { type: String },
  },
}
</script>

<style scoped lang="scss">
div {
  width: 100%;

  background-color: #fefefe;
  color: #818182;

  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid transparent;

  &.danger {
    color: #721C24;
    border-color: #721C24;
    background-color: #F8D7DA;

    i {
      color: #721C24;
    }
  }
  &.info {
    color: #004085;
    border-color: #004085;
    background-color: #CCE5FF;

    i {
      color: #004085;
    }
  }
  &.success {
    color: #155724;
    border-color: #155724;
    background-color: #D4EDDA;

    i {
      color: #155724;
    }
  }
  &.warning {
    color: #856404;
    border-color: #856404;
    background-color: #FFF3CD;

    i {
      color: #856404;
    }
  }

  span {
    font-family: sans-serif;
    font-size: 16px;
  }

  i {
    color: #818182;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}
</style>
