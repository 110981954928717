import api from '../../utils/api'

export const state = {
  userPlans: [],
  userPlansMeta: {},
  userPlansUtm: [],
  userPlansUtmMeta: {},
  plansPurchasedByUserIndication: [],
  plansPurchasedByUserIndicationMeta: {},
  limitationOfAgentsAdded: undefined,
}

export const mutations = {
  SET_USER_PLANS(state, userPlans) {
    state.userPlans = userPlans;
  },
  SET_USER_PLANS_META(state, userPlansMeta) {
    state.userPlansMeta = userPlansMeta;
  },
  SET_USER_PLANS_UTM(state, userPlansUtm) {
    state.userPlansUtm = userPlansUtm;
  },
  SET_USER_PLANS_UTM_META(state, userPlansUtmMeta) {
    state.userPlansUtmMeta = userPlansUtmMeta;
  },
  SET_LIMITATIONOFAGENTSADDED(state, limitationOfAgentsAdded) {
    state.limitationOfAgentsAdded = limitationOfAgentsAdded;
  },
  SET_PLANSPURCHASEDBYUSERINDICATION(state, plansPurchasedByUserIndication) {
    state.plansPurchasedByUserIndication = plansPurchasedByUserIndication;
  },
  SET_PLANSPURCHASEDBYUSERINDICATIONMETA(state, plansPurchasedByUserIndicationMeta) {
    state.plansPurchasedByUserIndicationMeta = plansPurchasedByUserIndicationMeta;
  },
}

export const actions = {
  async handleAmountPaidPlan(_, planId) {
    const { data: response } =
      await api.get(`user-plans/amountPaidPlan/${planId}`);

    return response
  },
  async handlePlanPaidOrPendingByEmail(_, email) {
    const { data: response } =
      await api.get(`user-plans/planPaidOrPendingByEmail/${email}`);

    return response
  },
  async handleAdminReleaseTrialPlan(_, payload) {
    const { data: response } =
      await api.post('admin/user-plans/release/trial', { userId: payload.userId });

    return response
  },
  async handleAdminReleaseCopyPlan(_, payload) {
    const { data: response } =
      await api.post('admin/user-plans/release/copy', payload);
    return response
  },
  async handlePlanInvoice(_, user) {
    if(user && user.plan && user.plan.status && user.plan.invoiceId) {
      const { invoice } =  (await api.get(`/user-plans/invoice/${user.plan.invoiceId}`)).data
      if (invoice && invoice.status === 'pending') {
        const { secure_url } = invoice
        return { secure_url}
      }
    }
    return { }
  },
  async handleGetUserPlans({ commit }, payload) {
    let params = {};

    if (payload) {
      params = {
        sortBy: payload.sortBy || undefined,
        sortIn: payload.sortIn || undefined,
        skip: payload.skip || undefined,
        limit: payload.limit || undefined,
      }
    }

    const { data: response } = await api.get(`user-plans/${payload.userId}`, { params });

    if (response && response.data.userPlans) {
      commit('SET_USER_PLANS', response.data.userPlans);
      commit('SET_USER_PLANS_META', response.meta);
    }
  },
  resetUserPlansUtmMeta({ commit }) {
    commit('SET_USER_PLANS_UTM_META', {})
  },
  async handleGetUserPlansUtm({ commit }, payload) {
    let params = {};

    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        search: payload.search || undefined,
        skip: payload.skip || undefined,
        limit: payload.limit || undefined,
      }
    }
    const { data: response } = await api.get(`user-plans/traching/utm`, { params });
    commit('SET_USER_PLANS_UTM', []);
    commit('SET_USER_PLANS_UTM_META', {});
    if (response && response.data.userPlans) {
      commit('SET_USER_PLANS_UTM', response.data.userPlans);
      commit('SET_USER_PLANS_UTM_META', response.meta);
    }
  },

  async handleGetAddedAgentsLimitation({commit}, user) {
    if (user && user.role !== 'admin' && user.plan && (user.plan.plan === 'TRIAL' || user.plan.plan === 'MO1HO')){
      const { data: response } = await api.get(`user-plans/addedAgentsLimitation/${user.plan._id}`);
      commit('SET_LIMITATIONOFAGENTSADDED', response.limitationOfAgentsAdded);
    } else {
      commit('SET_LIMITATIONOFAGENTSADDED', undefined);
    }
  },

  async handlePlansPurchasedByUserIndication({ commit }, payload) {
    try {
      let body = {};
      if (payload) {
        body = {
          username: payload.username,
          skip: payload.skip || undefined,
          limit: payload.limit || undefined,
        }
      }

      const { data: response } = await api.post(`user-plans/plansPurchasedByUserIndication`, body);

      if (response && response.data.userPlans) {
        commit('SET_PLANSPURCHASEDBYUSERINDICATION', response.data.userPlans);
        commit('SET_PLANSPURCHASEDBYUSERINDICATIONMETA', response.meta);
      } else {
        commit('SET_PLANSPURCHASEDBYUSERINDICATION', []);
        commit('SET_PLANSPURCHASEDBYUSERINDICATIONMETA', {});
      }
    } catch (e) {

    }
  },

  async handleCancelUserPlan(_, dataCancel) {
    try {
      const { data: response } = await api.post(`admin/user-plans/cancel`, dataCancel);
      return response
    } catch (e) {

    }
  },
}

export const getters = {
  userPlans: (state) => state.userPlans,
  userPlansMeta: (state) => state.userPlansMeta,
  limitationOfAgentsAdded: (state) => state.limitationOfAgentsAdded,
  plansPurchasedByUserIndication: (state) => state.plansPurchasedByUserIndication,
  plansPurchasedByUserIndicationMeta: (state) => state.plansPurchasedByUserIndicationMeta,
  userPlansUtm: (state) => state.userPlansUtm,
  userPlansUtmMeta: (state) => state.userPlansUtmMeta,
}
