<template>
  <div
    class="input-container"
    :class="{
      block: block
    }"
  >
    <label class="checkbox">
      <input 
        type="checkbox" 
        @input="updateValue"
        :checked="modelValue"
      />
      <span></span>
    </label>
    <label v-if="label">{{ label }}</label>
  </div>
</template>
<script>

export default {
  name: 'BaseInputCheckbox',
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    label: { type: String, default: '' },
    modelValue: { type: Boolean, default: false },
    block: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  methods: {
    updateValue(event) {
      const { checked } = event.target;

      this.$emit('input', checked);
    }
  },
};
</script>

<style scoped lang="scss">
.input-container {
  display: flex;
  align-items: center;
  margin-left: 3px;

  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    margin-left: 0px;
    
    & > span {
      color: #000;
      padding: 0.5rem 0.20rem;
    }

    & > input {
      height: 24px;
      width: 24px;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      border: 1px solid var(--blue);
      border-radius: 4px;
      outline: none;
      transition-duration: 0.3s;
      background-color: #FFF;
      cursor: pointer;
    }

    & > input:checked {
        background-color: var(--blue);
    }

    & > input:checked + span::before {
      content: '\2713';
      display: block;
      text-align: center;
      color: #FFF;
      position: absolute;
      left: 4px;
      top: 2px;
    }

    & > input:active {
      border: 2px solid var(--blue);
    }
  }

  label {
    font-size: 16px;
    margin-left: 8px;
  }
}
</style>
