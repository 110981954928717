import api from '../../utils/api'

export const state = {
  selectedAgent: undefined,
  summary: []
}

export const mutations = {
  SET_SELECTED_AGENT(state, selectedAgent) {
    state.selectedAgent = selectedAgent
  },
  SET_SUMMARY(state, summary) {
    state.summary = summary
  }
}

export const actions = {
  async add(_, data) {
    const chemicalInventory = await api.post('/chemical-inventories', data)

    return chemicalInventory.data
  },
  async load({ commit }, productId) {
    const { data } = await api.get(`/chemical-inventories/${productId}`)

    commit('SET_SUMMARY', data)
  },
  async remove(_, itemsToRemove) {
    for (let item in itemsToRemove) {
      await api.delete(`/chemical-inventories/${itemsToRemove[item]}`)
    }
  },
  select({ commit }, agent) {
    commit('SET_SELECTED_AGENT', agent)
  },
  async handleUpdate(_, item) {
    /*for (let index in summary) {
      const item = summary[index]
      await api.put(`/chemical-inventories/${item._id}`, { agent: item })
    }*/
    await api.put(`/chemical-inventories/${item._id}`, { agent: item })
  }
}

export const getters = {
  selectedAgent: (state) => state.selectedAgent,
  summary: (state) => state.summary
}
