<template>
  <div class='terms px-3 py-2'>
    <header class=' '>
      <h1>{{title}}</h1>
      <p>
        <a
          :href='link'
          target='_blank'
        >
          {{ textLink}}
        </a>
      </p>
    </header>
    <img alt='Ho Facil logo' src='../assets/images/ho-facil-white.png' />
  </div>
</template>
<script>
export default {
  props:{
    title: {type: String, required: false, default: ''},
    link: {type: String, required: false, default: ''},
    textLink: {type: String, required: false, default: ''},
  }
}
</script>
<style scoped>
.terms {
  height: fit-content;
  background-color: #ECF0F3;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.terms img {
  height: 80px;
}

h1 {
  font-size: 14px;
  font-weight: 700;
  color: #262626;
}

p > a {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: var(--navy-blue);
  text-decoration: underline;
}
</style>
