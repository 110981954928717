import api from '../../utils/api'
import axios from 'axios'

export const state = {}

export const mutations = {}

export const actions = {
  async handleLinkFunctionalities(_, functionality) {
    try {
      return await api.post(`/functionality`, functionality)
    } catch (e) {
      console.error(e)
    }
  },

  async handleGetFunctionalities(_) {
    try {
      return await api.get(`/functionality`)
    } catch (e) {
      console.error(e)
    }
  }
}

export const getters = {}
