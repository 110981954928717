import api from '../../utils/api'
import axios from 'axios'

export const state = {
  disableProduct: false,
  attachmentProducts: [],
  attachmentProductsMeta: {},
}

export const mutations = {
  SET_DISABLE_PRODUCT(state, disableProduct) {
    state.disableProduct = disableProduct
  },
  SET_ATTACHMENT_PRODUCTS(state, attachmentProducts) {
    state.attachmentProducts = attachmentProducts
  },
  SET_ATTACHMENT_PRODUCTS_META(state, attachmentProductsMeta) {
    state.attachmentProductsMeta = attachmentProductsMeta
  },
}

async function upload(file) {
  try {
    if (file && file.size) {
      const { data: responseSignedUrl } = await api.get('uploads/signed-url', {
        params: {
          fileType: file.type
        }
      })

      if (responseSignedUrl.error) {
        return responseSignedUrl
      }

      const { url: signedUrl } = responseSignedUrl
      const url = signedUrl.split('?')[0]

      const options = {
        headers: {
          'Content-Type': file.type
        }
      }

      await axios.put(signedUrl, file, options)
      return url
    }
  } catch (e) {
    console.error(e)
  }
}

export const actions = {
  async setDisableProduct({ commit }, disableProduct) {
    commit('SET_DISABLE_PRODUCT', disableProduct)
  },
  async handleCreateProductLegalConsultation(_, productLegalConsultation) {
    try {
      let urlFISPQ = ''
      if (productLegalConsultation.file) {
        urlFISPQ = await upload(productLegalConsultation.file)
      }
      productLegalConsultation.urlFISPQ = urlFISPQ
      return await api.post(`/productLegalConsultation`, productLegalConsultation)
    } catch (e) {
      console.error(e)
    }
  },

  async handleGetCompanyProductLegalConsultation(_, user) {
    try {
      return await api.get(`/productLegalConsultation/${user}`)
    } catch (e) {
      console.error(e)
    }
  },

  async handleGetCompanyUserProductLegalConsultation(_, params) {
    try {
      return await api.post(`/productLegalConsultation/by-company`, params)
    } catch (e) {
      console.error(e)
    }
  },
  async handleGetProductLegalConsultationByCompany(_, companyId) {
    try {
      return await api.get(`/productLegalConsultation/${companyId}/company`, companyId)
    } catch (e) {
      console.error(e)
    }
  },

  async handleDeleteProductLegalConsultation(_, id) {
    try {
      return await api.delete(`productLegalConsultation/${id}`)
    } catch (e) {
      console.error(e)
    }
  },

  async handleAddAgentProductLegalConsultation(_, body) {
    try {
      return await api.put(`productLegalConsultation/addAgent`, body)
    } catch (e) {
      console.error(e)
    }
  },

  async handleAddUpdateRoleProductLegalConsultation(_, body) {
    try {
      return await api.put(`productLegalConsultation/updateRole`, body)
    } catch (e) {
      console.error(e)
    }
  },

  async handleUpdatePercentageProductLegalConsultation(_, body) {
    try {
      return await api.put(`productLegalConsultation/percentage`, body)
    } catch (e) {
      console.error(e)
    }
  },

  async handleDeleteAgentProductLegalConsultation(_, body) {
    try {
      return await api.post(`productLegalConsultation/deleteAgent`, body)
    } catch (e) {
      console.error(e)
    }
  },

  async handleGetProductLegalConsultation(_, id) {
    try {
      return await api.get(`productLegalConsultation/product/${id}`)
    } catch (e) {
      console.error(e)
    }
  },

  async handleCreatewithoutAgentsProductLegalConsultation(_, productLegalConsultation) {
    try {
      return await api.post(`productLegalConsultation/withoutAgents`, productLegalConsultation)
    } catch (e) {
      console.error(e)
    }
  },

  async handleDeleteAgentsProductLegalConsultation(_, products) {
    try {
      return await api.post(`productLegalConsultation/deleteAgents`, products)
    } catch (e) {
      console.error(e)
    }
  },

  async handleDeleteByIdProductLegalConsultation(_, id) {
    try {
      return await api.delete(`productLegalConsultation/byId/${id}`)
    } catch (e) {
      console.error(e)
    }
  },

  async handleUpdateProductLegalConsultation(_, productLegalConsultation) {
    try {
      let urlFISPQ = ''
      if (productLegalConsultation.file && productLegalConsultation.file.size) {
        urlFISPQ = await upload(productLegalConsultation.file)
      } else if (productLegalConsultation.urlFISPQ) {
        urlFISPQ = productLegalConsultation.urlFISPQ
      }
      const { name, description, id, physicalState, role, degreeOfDust } = productLegalConsultation
      const payload = {
        name,
        description,
        physicalState,
        degreeOfDust,
        role,
        urlFISPQ
      }
      return await api.put(`productLegalConsultation/${id}`, payload)
    } catch (e) {
      console.error(e)
    }
  },

  async handleProductLegalConsultationAttachments({ commit, state}, payload) {
    try {
      let params = {};

      if (payload) {
        params = {
          product: payload.product || null,
          company: payload.company || null,
          user: payload.user || null,
          skip: payload.skip || 0,
        }
      }
      commit('SET_ATTACHMENT_PRODUCTS', []);
      commit('SET_ATTACHMENT_PRODUCTS_META', {});
      const { data: response } = await api.post(`/productLegalConsultation/list/attachments`, params)
      if (response && response.attachmentProducts && response.attachmentProducts.length > 0) {
        commit('SET_ATTACHMENT_PRODUCTS', response.attachmentProducts);
        commit('SET_ATTACHMENT_PRODUCTS_META', response.meta);
      }
    } catch (e) {
      console.error(e)
    }
  },
  resetFilterProductLegalConsultationAttachments({ commit }) {
    commit('SET_ATTACHMENT_PRODUCTS', []);
    commit('SET_ATTACHMENT_PRODUCTS_META', {});
  },
}

export const getters = {
  disableProduct: (state) => state.disableProduct,
  attachmentProductsMeta: (state) => state.attachmentProductsMeta,
  attachmentProducts: (state) => state.attachmentProducts,
}
