export const state = {
  message: '',
  error: '',
  menuCollapsed: true,
}

export const mutations = {
  SET_MESSAGE(state, data) {
    state.message = data
  },
  SET_ERROR(state, data) {
    state.error = data
  },
  SET_MENU_COLLAPSED(state, menuCollapsed) {
    state.menuCollapsed = menuCollapsed
  },
}

export const actions = {
  async updateMessage({ commit }, message) {
    commit('SET_MESSAGE', message)
  },
  async updateError({ commit }, error) {
    commit('SET_ERROR', error)
  },
  async updateMenuCollapsed({ commit }, menuCollapsed) {
    commit('SET_MENU_COLLAPSED', menuCollapsed)
  },
}

export const getters = {
  error: (state) => state.error,
  message: (state) => state.message,
  menuCollapsed: (state) => state.menuCollapsed,
}
