<template>
  <table class="base-table">
    <thead>
      <tr>
        <th
          v-for="(column, i) in columns"
          :key="`column_${column.key}`"
        >
          <div class="content">
            <slot
              :item="column"
              :index="i"
              :name="`header-${column.key}`"
            >
              {{ column.value }}
            </slot>
            <div class="sort-buttons" v-if="column.sort">
              <i
                class="fas fa-chevron-up"
                @click="$emit('sort-by', {
                  page: currentPage,
                  sortBy: typeof column.sort === 'boolean' ? column.key :column.sort,
                  sortIn: 'asc'
                })"
              />
              <i
                class="fas fa-chevron-down"
                @click="$emit('sort-by', {
                  page: currentPage,
                  sortBy: typeof column.sort === 'boolean' ? column.key : column.sort,
                  sortIn: 'desc'
                })"
              />
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, i) in data"
        :key="`row_${i}`"
      >
        <td
          v-for="(column, j) in columns"
          :key="`row_${i}_column_${j}`"
        >
          <div class="content">
            <slot
              :item="item"
              :index="i"
              :name="`body-${column.key}`"
            >
              <template v-if="item[column.key]">
                {{ item[column.key] }}
              </template>
            </slot>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr v-if="quantityPages > 1">
        <td :colspan="columns.length">
          <div class="content navigation">
            <base-button-table
              :disabled="backDisabled"
              @click="setPage(currentPage - 1)"
            >
             Anterior
            </base-button-table>
            <base-button-table
              v-for="number in 5"
              v-if="CUR_PAGE+number-1 < quantityPages"
              :key="`page_${number}`"
              :selected="PAGE_SELECTED(CUR_PAGE+number-1)"
              @click="setPage(CUR_PAGE+number-1)"
            >
              {{ CUR_PAGE+number-1 }}
            </base-button-table>
            <base-button-table
              :disabled="nextDisabled"
              @click="setPage(currentPage + 1)"
            >
              Próximo
            </base-button-table>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import BaseButtonTable from './BaseButtonTable.vue';

export default {
  name: 'BaseTable',
  components: {
    BaseButtonTable,
  },
  props: {
    columns: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    totalItems: {
      type: Number,
      default: 10,
    },
    maxItems: {
      type: Number,
      default: 10,
    },
    startItems: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    currentPage: 1
  }),
  computed: {
    LAST_PAGE() {
      return Math.ceil(this.maxItems)
    },
    CUR_PAGE() {
      return Math.ceil(this.startItems / this.maxItems)
    },
    pagesPaginated() { return Math.ceil(this.totalItems / this.maxItems) },
    quantityPages() { return Math.ceil(this.totalItems / this.maxItems) },
    backDisabled() { return this.startItems === (((this.startItems * this.maxItems) - this.maxItems) + 1)},
    nextDisabled() { return this.startItems === (((this.maxItems * this.quantityPages) - this.maxItems) + 1)},
  },
  methods: {
    PAGE_SELECTED(number) {
      const isSelected = this.startItems === (((number * this.maxItems) - this.maxItems) + 1)
      if(isSelected) {
        this.currentPage = number;
      }
      return isSelected
    },
    setPage(page) {
      this.$emit('set-page', page);
    },
  },
}
</script>

<style lang="scss" scoped>
.base-table {
  width: 100%;

  tr {
    transition: all 0.2s ease-in-out;
    &:nth-child(even){
      background: #E7E7E7
    }

    &:hover:not(:last-child)  {
      background: #dfdfdf
    }

    th, td {
      vertical-align: middle;
      text-align: center;
      font-size: 16px;

      .content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      .sort-buttons {
          display: flex;
          flex-direction: column;

          i {
            cursor: pointer;
            color: var(--white);

            &:hover {
              opacity: 0.95;
            }
          }
        }
      }
    }

    th {
      background: var(--sapphire-blue);
      color: var(--white);
      padding: 16px;
    }

    td {
      padding: 16px;
      font-size: 14px;
      word-break: break-word!important;
      min-width: 100px;
    }
  }
  tfoot {
    tr {
      border: 0;
    }
    td {
      .navigation{
        padding: 0.5rem;
        justify-content: end;
        gap: 4px;
      }
    }
  }
}


</style>
