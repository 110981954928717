<template>
  <div class="base-input-file" :class="{block}">
    <input
      type="file"
      hidden
      :id="name"
      :accept="extentesionFile"
      @change="onChange"
      :multiple="multiple"
    />

    <label :for="name" :class="{block, disabled: !multiple && files.length > 0}">
      <i class="fa fa-plus"/>
      {{label}}
    </label>
    <div class="files" v-if="files.length > 0"  >
      <div class="file" v-for="(file, index) in files" :key="file.name">
        {{ file.name }}
        <i @click="removeFile(index)" class="fa fa-times"/>
      </div>
    </div>
    <span v-else>Nenhum arquivo selecionado</span>
    <span class="error" v-if="error">{{error}}</span>
  </div>
</template>
<script>
export default {
  name: 'BaseInputFile',
  data() {
    return {
      files: [],
      error: ''
    }
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [File, FileList],
    },
    placeholder: {
      type: String,
    },
    extentesionFile: {
      type: String,
    },
    label: {
      type: String,
      default: 'Selecione um arquivo'
    },
    block: { type: Boolean },
    multiple: { type: Boolean, default: false },
    maxSize: { type: [Boolean, Number], default: false },
  },
  methods: {
    checkSize(size){
      if(this.maxSize){
        if(size > this.maxSize){
          this.error = `Este arquivo é muito grande. O tamanho máximo permitido é ${this.maxSize / 1048576} MB.`
          return false
        }
      }
      return true
    },
    onChange(event) {
      const files = Array.from(event.target.files)
      if(this.checkSize(files[0].size)){
        this.files = files
        this.$emit('input', this.files)
        this.error = ''
      }
    },
    removeFile(index){
      this.files.splice(index, 1)
      this.$emit('input', this.files)
    }
  },
}
</script>
<style lang="scss" scoped>

.base-input-file{
  display: flex;
  flex-direction: column;

  &.block {
    width: 100%;
  }

  label {
    width: fit-content;

    transition: all 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 1px solid transparent;
    border-radius: 4px;

    padding: 6px 12px;

    box-shadow: 0 0 0 0 var(--blue);

    background-color: var(--blue);
    color: var(--white);

    font-weight: 400;
    text-align: center;
    font-size: 16px;

    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }

    &.block {
      width: 100%;
    }

  }
  span {
    font-weight: 400;
    font-size: 20px;
    padding-left: 8px;
  }

  .files {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;

    .file {
      width: fit-content;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      white-space: nowrap;

      color: var(--blue);
      border: 2px solid var(--blue);

      padding: 6px 12px;

      border-radius: 4px;

      transition: all 0.2s ease-in-out;

      &:hover {
        background: #028bed33;
      }

      i {
        color: var(--blue);
        cursor: pointer;
      }
    }
  }

  .error {
    color: red;
  }

}
</style>
