import api from '../../utils/api'
import format from 'date-fns/esm/format/index'

export const state = {
  updateNew: {
    title: '',
    description: '',
    registrationDate: ''
  },
  updateNews: [],
  updateNewsMeta: {},
  countOfUsersHaveNotSeenUpdateNews: 0,
  listOfUpdateNewsForUserView: [],
  listOfUpdateNewsForUserViewMeta: {
    total: 10
  }
}

export const mutations = {
  SET_UPDATE_NEW(state, updateNew) {
    state.updateNew = updateNew
  },
  SET_UPDATE_NEWS(state, updateNews) {
    state.updateNews = updateNews
  },
  SET_UPDATE_NEWS_META(state, updateNewsMeta) {
    state.updateNewsMeta = updateNewsMeta
  },
  SET_USERS_WHO_SAW_UPDATES_COUNT(state, countOfUsersHaveNotSeenUpdateNews) {
    state.countOfUsersHaveNotSeenUpdateNews = countOfUsersHaveNotSeenUpdateNews
  }
  , SET_LIST_OF_UPDATE_NEWS_FOR_USE_RVIEW_META(state, listOfUpdateNewsForUserViewMeta) {
    state.listOfUpdateNewsForUserViewMeta = listOfUpdateNewsForUserViewMeta
  }
  , SET_LIST_OF_UPDATE_NEWS_FOR_USE_RVIEW(state, listOfUpdateNewsForUserView) {
    state.listOfUpdateNewsForUserView.push(...listOfUpdateNewsForUserView)
  }
  , SET_LIST_OF_UPDATE_NEWS_FOR_USE_RESET(state, listOfUpdateNewsForUserView) {
    state.listOfUpdateNewsForUserView = listOfUpdateNewsForUserView
  }
}

export const actions = {
  async handleGetUpdateNew({ commit }, _id) {
    const { data: response } = await api.get(`/newUpdate/${_id}`)
    if (response && response.newUpdate) {
      response.newUpdate.registrationDate = format(new Date(response.newUpdate.registrationDate), 'yyyy-MM-dd')
      commit('SET_UPDATE_NEW', response.newUpdate)
    }
    return response
  },

  async handleCountOfUsersHaveNotSeenUpdateNews({ commit }) {
    const { data: response } = await api.post('/newUpdate/countOfUsersHaveNotSeenUpdateNews/', {})

    if (response) {
      commit('SET_USERS_WHO_SAW_UPDATES_COUNT', response.count)
    }
    return response
  },

  async handleUpdateUpdateNew({ state }, _id) {
    const body = state.updateNew
    const { data } = await api.put(`/newUpdate/${_id}`, body)
    return data
  },

  async handleDeleteUpdateNew({ state }, _id) {
    const { data } = await api.delete(`/newUpdate/${_id}`)
    return data
  },

  async resetUpdateNew({ commit }) {
    commit('SET_UPDATE_NEW', {
      title: '',
      description: '',
      registrationDate: ''
    })
  },

  async handleCreateUpdateNew({ state }) {
    const body = state.updateNew
    const { data } = await api.post('/newUpdate', body)
    return data
  },

  async handleAddUserReadUpdatesNew({ state }, _id) {
    try {
      return await api.post('/newUpdate/addUserReadUpdatesNew', { _id })
    } catch (error) {
      console.log(error)
    }
  },

  async handleProcessUserWhoSawTheUpdates({ state }) {
    try {

      return await api.post('/newUpdate/addUserSawUpdatesNew')
    } catch (error) {
      console.log(error)
    }
  },

  async handleGetNewsUpdates({ commit }, payload) {
    let params = {}
    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        skip: payload.skip || undefined,
        limit: payload.limit || undefined
      }
    }
    const { data: response } = await api.get('/newUpdate', { params })
    if (response && response.updatesNews) {
      commit('SET_UPDATE_NEWS', response.updatesNews)
      commit('SET_UPDATE_NEWS_META', response.meta)
    } else {
      commit('SET_UPDATE_NEWS', [])
      commit('SET_UPDATE_NEWS_META', {})
    }
  },

  async handleGetListUpdatesNews({ commit }, payload) {
    let params = {}

    if (payload) {
      params = {
        skip: payload.skip || undefined,
        limit: payload.limit || undefined
      }
    }
    if (!payload.skip || payload.skip < 1) {
      commit('SET_LIST_OF_UPDATE_NEWS_FOR_USE_RESET', [])
    }

    const { data: response } = await api.get('/newUpdate', { params })

    if (response && response.updatesNews) {
      commit('SET_LIST_OF_UPDATE_NEWS_FOR_USE_RVIEW', response.updatesNews)
      commit('SET_LIST_OF_UPDATE_NEWS_FOR_USE_RVIEW_META', response.meta)

    }
  },

  async handleGetUpdateLogByIndex({ commit, state }, index) {
    commit('SET_UPDATE_NEW', state.updateLogs[index])
  },

  async handleAddLike({ commit }, _id) {
    const { data: response } = await api.post(`newUpdate/like`, { _id })
    return response
  },

  async handleRemoveLike({ commit }, _id) {
    const { data: response } = await api.delete(`newUpdate/like/${_id}`)
    return response
  },

  async handleAddDisLike({ commit }, _id) {
    const { data: response } = await api.post(`newUpdate/disLike`, { _id })
    return response
  },

  async handleDoNotShowNewUpdate({ commit }, _id) {
    const { data: response } = await api.post(`newUpdate/doNotShowNewUpdate`, { _id })
    return response
  },

  async handleRemoveDisLike({ commit }, _id) {
    const { data: response } = await api.delete(`newUpdate/disLike/${_id}`)
    return response
  },

  async handleGetUpdatesNewsLastThirtyDays({ commit }) {
    const { data: response } = await api.post(`newUpdate/updatesNewsLastThirtyDays`)
    return response
  },

  async handleCreateComment({ commit }, comment) {
    return await api.post(`newUpdate/comment`, comment)
  },


  async handleSaveUserSawReadNewUpdate({ commit }, _id) {
    const { data: response } = await api.post(`newUpdate/saveUserSawReadNewUpdate`, { _id })
    return response
  },


  async handleGetUsersLikedUpdateNew({ commit }, payload) {
    let params = {}
    if (payload) {
      params = {
        skip: payload.skip || undefined,
        limit: payload.limit || undefined
      }
    }
    const { data: response } = await api.get(`newUpdate/usersLikedNewUpdate/${payload.newUpdateId}`, { params })
    return response
  },

  async handleGetUsersDisLikeNewUpdate({ commit }, payload) {
    let params = {}
    if (payload) {
      params = {
        skip: payload.skip || undefined,
        limit: payload.limit || undefined
      }
    }
    const { data: response } = await api.get(`newUpdate/usersDisLikeNewUpdate/${payload.newUpdateId}`, { params })
    return response
  },

  async handleGetUsersReadNewUpdate({ commit }, payload) {
    let params = {}
    if (payload) {
      params = {
        skip: payload.skip || undefined,
        limit: payload.limit || undefined
      }
    }
    const { data: response } = await api.get(`newUpdate/usersReadNewUpdate/${payload.newUpdateId}`, { params })
    return response
  },

  async handleGetUsersSawNewUpdate({ commit }, payload) {
    let params = {}
    if (payload) {
      params = {
        skip: payload.skip || undefined,
        limit: payload.limit || undefined
      }
    }
    const { data: response } = await api.get(`newUpdate/usersSawNewUpdate/${payload.newUpdateId}`, { params })
    return response
  },

  async handleGetUsersCommentsNewUpdate({ commit }, payload) {
    let params = {}
    if (payload) {
      params = {
        skip: payload.skip || undefined,
        limit: payload.limit || undefined
      }
    }
    const { data: response } = await api.get(`newUpdate/usersCommentsNewUpdate/${payload.newUpdateId}`, { params })
    return response
  },
  async handleGetUserDoNotShowNewUpdate({ commit }, payload) {
    let params = {}
    if (payload) {
      params = {
        skip: payload.skip || undefined,
        limit: payload.limit || undefined
      }
    }
    const { data: response } = await api.get(`newUpdate/userDoNotShowNewUpdate/${payload.newUpdateId}`, { params })
    return response
  }

}

export const getters = {
  getUpdateNew: (state) => state.updateNew,
  getUpdateNews: (state) => state.updateNews,
  getUpdateNewsMeta: (state) => state.updateNewsMeta,
  getCountOfUsersHaveNotSeenUpdateNews: (state) => state.countOfUsersHaveNotSeenUpdateNews,
  getListOfUpdateNewsForUserView: (state) => state.listOfUpdateNewsForUserView,
  getListOfUpdateNewsForUserViewMeta: (state) => state.listOfUpdateNewsForUserViewMeta
}
