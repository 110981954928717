<template>
  <div class="feedback">
    <base-modal
      class="modal-feedback"
      :isOpened="isOpen"
      @close="closeModal"
    >
      <template v-slot:header>
        <h3>ENVIE SEU FEEDBACK</h3>
      </template>
      <template v-slot:content>
        <div class='d-flex'>
          <p>
            Envie um e-mail informando sua dúvida, crítica ou sugestão para nosso suporte técnico. Estaremos te ajudando o mais rápido possível.
          </p>

          <p v-if='loading'>
            <b-spinner class='loading-spinner' variant="primary" label="Spinning"></b-spinner>
          </p>
        </div>
        <base-alert2
          v-if="alert.open"
          class="alert"
          :type="alert.type"
          :message="alert.message"
          @close="alert.open = false"
        />
        <div class="w-100">
          <label class='label_input'>Email:</label>
          <base-input-text
            v-model="feedback.email"
            label=""
            placeholder="Digite seu E-mail"
            type="email"
            :disabled="loading"
            block
          />
        </div>
        <div class="w-100 mt-3">
          <label class='label_input'>Mensagem</label>
          <base-text-area
            v-model="feedback.message"
            label=""
            placeholder="Digite sua mensagem"
            :disabled="loading"
            block
          />
        </div>
        <div class="my-3 w-100">
          <base-input-file
            v-model="files"
            label="Clique aqui para anexar fotos ou arquivos"
            name="file"
            block
            multiple
            :max-size="MAX_SIZE_IN_BYTES"
          />
        </div>
      </template>
      <template v-slot:footer>
        <base-button
          danger
          @click="closeModal"
        >
          Cancelar
        </base-button>
        <base-button
          @click="handleSubmitFeedback()"
          success
          :disabled="!isValid()"
        >
          Enviar
        </base-button>
      </template>
    </base-modal>
    <!--<base-button
      :class="{ hide: feedbackHidden }"
      @click="openModal = true"
    >
      <i class="fa fa-comment mx-1" v-if='icon'></i>
      <span v-html="legendButton"></span>
    </base-button>-->
  </div>
</template>

<script>
import api from '../../utils/api'
import axios from 'axios'
export default {
  name: 'Feedback',
  props:{
    isOpen: { type: Boolean, default: false },
    legendButton: {type: String, default: "Envie seu feedback"},
    icon: {type: Boolean, default: true}
  },
  created() {
    this.feedback.email = this.user.username
    this.files = []
  },
  data() {
    return {
      feedbackHidden: false,
      loading: false,
      openModal: false,
      feedback: {
        email: '',
        message: '',
        files: [],
      },
      alert: {
        open: false,
        type: 'success',
        message: ''
      },
      files: [],
      MAX_SIZE_IN_BYTES: 1572864
    }
  },
  watch:{

  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    },
  },
  methods: {
    async handleSubmitFeedback() {
      const fileSizeMAX_SIZE_IN_BYTES = this.files.filter((x) => x.size > this.MAX_SIZE_IN_BYTES)
      if(fileSizeMAX_SIZE_IN_BYTES.length > 0){
        fileSizeMAX_SIZE_IN_BYTES.forEach((x) => {
          this.$bvToast.toast(`O arquivo ${x.name} é muito grande. O tamanho máximo permitido é ${this.MAX_SIZE_IN_BYTES / 1048576} MB.`, {
            title: 'Aviso',
            variant: 'danger',
            toaster: 'b-toaster-top-center',
            autoHideDelay: 7000
          })
        })
        return
      }
      if(this.files.length > 5){
        this.$bvToast.toast('Màximo de 5 arquivos.', {
          title: 'Aviso',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        return
      }

      this.loading = true
      this.feedback.files = []
      for (let i = 0; i < this.files.length ; i++) {
        const file = this.files[i]

        const { data: responseSignedUrl } = await api.get('uploads/signed-url', {
          params: {
            fileType: file.type
          }
        })

        if (responseSignedUrl.error) {
          return responseSignedUrl
        }

        const { url: signedUrl } = responseSignedUrl
        const url = signedUrl.split('?')[0]

        const options = {
          headers: {
            'Content-Type': file.type
          }
        };
        const uploaded = await axios.put(signedUrl, file, options)
        this.feedback.files.push(
          {
            fileName: this.files[i].name,
            url
          }
        )
      }

      this.$api
        .post('users/sendFeedback', this.feedback)
        .then((res) => {
          if (res.data.message) {
            this.alert.open = true
            this.alert.type = 'success'
            this.alert.message = res.data.message

            this.closeModal()
          }

          if (res.data.error) {
            this.alert.open = true
            this.alert.type  = 'danger'
            this.alert.message = res.data.error
          }

          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.alert.open = true
          this.alert.type = 'danger'
          this.alert.message = 'Erro ao enviar o feedback, tente novamente!'
        })
    },
    closeModal(){
      this.feedback = {
        email: this.feedback.email,
        message: ''
      }
      this.alert = {
        open: false,
        type: 'success',
        message: ''
      }
      this.$emit('close')
    },
    isValid(){
      if(!this.feedback.email || !this.feedback.message ) {
        return false
      }

      return true
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-feedback {
  /deep/ header {
    h3 {
      font-weight: bold;
    }
  }

  /deep/ main {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;

    span {
      width: 100%;
      font-size: 22px;
      text-align: center;
    }
    p{
      color: #212529;
    }
    .loading-spinner{
      width: 2.5rem;
      height: 2.5rem;
    }
    .label_input {
      text-align: left;
      color: #212529;
      font-weight: 700;
      margin-bottom: -5px;
    }
  }

  /deep/ footer {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding: 1rem;
  }
}
</style>
