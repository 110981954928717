import api from '../../utils/api'
import axios from 'axios'

export const state = {
}

export const mutations = {
}

export const actions = {
  async handleCreateFreeModeAgents(_, freeModeAgents) {
    try {
      return await api.post(`/free-mode`, freeModeAgents)
    } catch (e) {
      console.error(e)
    }
  },

  async handleAddAgentsFreeModeAgents(_, freeModeAgents) {
    try {
      return await api.put(`/free-mode`, freeModeAgents)
    } catch (e) {
      console.error(e)
    }
  },

  async handleRemovegentsFreeModeAgents(_, freeModeAgents) {
    try {
      return await api.put(`/free-mode/delete-agents`, freeModeAgents)
    } catch (e) {
      console.error(e)
    }
  },
}

export const getters = {

}
