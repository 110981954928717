<template>
  <section class='w-100 p-3'>
    <section class='mb-3' >
      <img width='80' src='../../../../assets/images/logoCadastroPerfil.svg'/>
    </section>

    <b-alert
      show
      variant='danger'
      :class='{ hidden: !error }'
    >
      {{ error }}
    </b-alert>
    <section>
      <p>Qual é a <strong>sua principal dificuldade</strong> hoje? E como você acha que podemos te ajudar?</p>

      <section>
        <base-text-area
          class='w-100'
          v-model='userProfile.mainDifficulty'
          label=''
          block
        />
      </section>

      <section class='d-flex justify-content-between align-items-center my-4'>
        <b-progress :value='90' height='10px' variant='primary' class='w-25'></b-progress>
        <b-overlay
          :show='loading'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='d-inline-block'
        >
          <base-button
            :disabled='!userProfile.mainDifficulty'
            @click='next()'
            class='btn mt-1'>
            <span v-if='loading' class='text_btn'>Enviando</span>
            <span v-else class='text_btn'>Continuar</span>
          </base-button>
        </b-overlay>
      </section>
    </section>
  </section>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  namer: 'MainDifficulty',
  components: {},
  computed: {
    ...mapGetters('surveys', {
      survey: 'getSurvey',
    }),
    ...mapGetters('surveys', {
      getUserRegisterProfileData: 'getUserRegisterProfileData',
    }),
    userProfile() {
      return this.$store.getters['user/userProfile']
    },
    message() {
      return this.$store.getters['access/message']
    },
    error() {
      return this.$store.getters['access/error']
    },
    user() {
      return this.$store.getters['user/current']
    },

  },
  data() {
    return {
      loading: false,
      curCookie: null
    }
  },
  validations() {
    return {}
  },
  created() {
    this.$store.dispatch('access/updateError', '')
    this.$store.dispatch('access/updateMessage', '')
  },
  async mounted() {
    try {
      const sessionId = localStorage.getItem("sessionId");
      if(this.user && sessionId) {
        await this.userRegisterProfileData();
      }
    } catch (e) {

    }
  },
  methods: {
    ...mapActions('user', {
      handleSetCurrentUser: 'handleSetCurrentUser',
    }),
    ...mapActions('auth', {
      handleRegisterUser: 'handleRegisterUser',
      handleResetRegister: 'handleResetRegister',
    }),
    ...mapActions('surveys', {
      userRegisterProfileData: 'userRegisterProfileData',
      handleCreateSurvey: 'handleCreateSurvey',
    }),
    async next() {
      try {
        this.loading = true
        const sessionId = localStorage.getItem("sessionId");
        if(this.user && this.getUserRegisterProfileData && sessionId){
          this.survey.type = 'user-profile';
          this.survey.body = {
            numberOfPeopleSelected: this.userProfile.numberOfPeopleSelected,
            company: this.userProfile.company,
            age: this.userProfile.age,
            companyPosition: this.userProfile.companyPosition,
            actingArea: this.userProfile.actingArea,
            salaryRange: this.userProfile.salaryRange,
            reasonsToUse: this.userProfile.reasonsToUse,
            mainDifficulty: this.userProfile.mainDifficulty,
            whatManagementSoftwareYouUse: this.userProfile.whatManagementSoftwareYouUse,
          }
          await this.handleCreateSurvey();
          await this.userRegisterProfileData();
        }
      } catch (e) {

      } finally {
      }
    }
  }
}
</script>

<style scoped>
.text_btn {
  font-weight: 600;
}

.btn {
  width: 130px;
  height: 45px;
  border-radius: 5px;
  border: none;
}

.btn:hover {
  color: white;
}

</style>
