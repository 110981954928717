import api from '../../utils/api'

export const state = {

}

export const mutations = {

}

export const actions = {
  async handleCreate(_, termsOfUse) {
    try {
      return await api.post('/terms-of-use', termsOfUse)
    } catch (err) {

    }
  },

  async handleGet(_, termsOfUseId) {
    try {
      return await api.get(`/terms-of-use/${termsOfUseId}`)
    } catch (err) {

    }
  },


  async handleUpdade(_, termsOfUse) {
    try {
      return await api.put(`/terms-of-use/${termsOfUse._id}`, termsOfUse)
    } catch (err) {

    }
  },
}

export const getters = {

}
