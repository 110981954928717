import * as validators from '@vuelidate/validators'

validators.required.$message = 'Este campo é de preenchimento obrigatório'
export const required = validators.required

export const minLength = (value) => validators.helpers.withMessage(
  'Este campo precisa de mais caracteres',
  validators.minLength(value)
)

export const maxLength = (value) => validators.helpers.withMessage(
  'Este campo precisa de menos caracteres',
  validators.maxLength(value)
)

export const requiredIf = (value) => validators.helpers.withMessage(
  'Este campo é obrigatório',
  validators.requiredIf(value)
)

export const sameAs = (message, value) => validators.helpers.withMessage(
  message,
  validators.sameAs(value)
)
