import api from '../../utils/api'
import format from 'date-fns/esm/format/index'
export const state = {
  partner: {
    name: '',
    email: '',
    _id: ''
  },
  partners: [],
  partnersMeta: {},
};

export const mutations = {
  SET_PARTNER(state, partner) {
    state.partner = partner;
  },
  SET_PARTNERS(state, partners) {
    state.partners = partners;
  },
  SET_PARTNERS_META(state, partnersMeta) {
    state.partnersMeta = partnersMeta;
  },
}

export const actions = {
  async handleCreatePartner({ state }) {
    const body = state.partner;
    const { data } = await api.post(`/api-key-partners`, body);
    return data;
  },

  async handleGetPartners({ commit }, payload) {
    let params = {};

    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        skip: payload.skip || undefined,
        limit: payload.limit || undefined,
        filter: payload.filter || undefined,
      }
    }
    const { data: response } = await api.get(`/api-key-partners/all`, { params });
    if (response && response.data.apiKeyForPartners) {
      commit('SET_PARTNERS', response.data.apiKeyForPartners);
      commit('SET_PARTNERS_META', response.meta);
    } else {
      commit('SET_PARTNERS', []);
      commit('SET_PARTNERS_META', {});
    }
  },

  async handleGetPartner({ commit }, _id) {
    const { data: response } = await api.get(`/api-key-partners/${_id}`);
    if (response && response.data) {
      commit('SET_PARTNER', response.data);
    }
    console.log(response)
    return response;
  },
  async handleGetEmailPartner({ state }) {
    const body = state.partner;
    const { data: response } = await api.get(`/api-key-partners/email/${body.email}`);
    return response;
  },

  async handleUpdatePartner({ state }, _id) {
    const body = state.partner;
    const { data } = await api.put(`/api-key-partners/${_id}`, body);
    return data;
  },
  async handleUpdatePartnerApiKey({ state }, _id) {
    const body = state.partner;
    const { data } = await api.put(`/api-key-partners/apiKey/${_id}`, body);
    return data;
  },
  resetPartner({ commit }) {
    commit('SET_PARTNER', {
      name: '',
      email: '',
      _id: ''
    })
  },

  async handleDeletePartner({ state }, _id) {
    const { data } = await api.delete(`/api-key-partners/${_id}`);
    return data;
  },

  resetUpdateLog({ commit }) {
    commit('SET_UPDATE_LOG', {
      title: '',
      description: '',
      registrationDdate: ''
    })
  },
}

export const getters = {
  getPartner: (state) => state.partner,
  getPartners: (state) => state.partners,
  getPartnersMeta: (state) => state.partnersMeta,
}
