import api from '../../utils/api'
import axios from 'axios'

export const state = {
  importedCompany: null,
  companies: [],
  companiesMeta: {},
  companiesReport: [],
  companiesReportMeta: {},
  companyPerPage: 5,
  companyPerPageReport: 10,
}

export const mutations = {
  SET_IMPORTED_COMPANY(state, importedCompany) {
    state.importedCompany = importedCompany
  },
  SET_COMPANIES(state, companies) {
    state.companies = companies
  },
  SET_COMPANIES_META(state, companiesMeta) {
    state.companiesMeta = companiesMeta
  },
  SET_COMPANIES_REPORT(state, companiesReport) {
    state.companiesReport = companiesReport
  },
  SET_COMPANIES_META_REPORT(state, companiesReportMeta) {
    state.companiesReportMeta = companiesReportMeta
  },
}

export const actions = {
  async handleCreateCompanyLegalConsultation(_, companyLegalConsultation) {
    try {
      return await api.post(`/companyLegalConsultation`, companyLegalConsultation)
    } catch (e) {
      console.error(e)
    }
  },

  async handleGetCompanyLegalConsultation(_, user) {
    try {
      return await api.get(`/companyLegalConsultation/${user}`)
    } catch (e) {
      console.error(e)
    }
  },

  async handleGetLastImportedCompanyLegalConsultation({ commit }, user) {
    try {
      const { data, status } = await api.get(`/companyLegalConsultation/lastImported/${user}`)
      if (data && data.company) {
        commit('SET_IMPORTED_COMPANY', data.company)
      } else {
        commit('SET_IMPORTED_COMPANY', null)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async handleGetNumberRegisteredCompanies({ commit }, user) {
    try {
      const { data, status } = await api.get(`/companyLegalConsultation/numberRegisteredCompanies/${user}`)
      return data.amount
    } catch (e) {
      console.error(e)
    }
  },

  async handleMarkAsLastImportedCompanyLegalConsultation({ commit }, body) {
    try {
      const { data, status } = await api.put(`/companyLegalConsultation/markAsLastImported`, body)
      if (data && data.company) {
        commit('SET_IMPORTED_COMPANY', data.company)
      } else {
        commit('SET_IMPORTED_COMPANY', null)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async handleUnmarkAllAsLastImportedCompanyLegalConsultation({ commit }, user) {
    try {
      return await api.put(`/companyLegalConsultation/unmarkAllAsLastImported/${user}`)
    } catch (e) {
      console.error(e)
    }
  },

  async handleAddAgentCompanyLegalConsultation({ commit }, bodyAddAgentCompany) {
    try {
      return await api.post(`/companyLegalConsultation/addAgent`, bodyAddAgentCompany)
    } catch (e) {
      console.error(e)
    }
  },

  async handleDeleteAgentCompanyLegalConsultation({ commit }, bodyDelteAgentCompany) {
    try {
      return await api.post(`/companyLegalConsultation/deleteAgent`, bodyDelteAgentCompany)
    } catch (e) {
      console.error(e)
    }
  },

  async handleUpdateAgentsCompanyLegalConsultation({ commit }, bodyUpdateAgents) {
    try {
      return await api.put(`/companyLegalConsultation/agents`, bodyUpdateAgents)
    } catch (e) {
      console.error(e)
    }
  },

  async handleUpdatePercentageCompanyLegalConsultation({ commit }, bodyUpdateAgents) {
    try {
      return await api.put(`/companyLegalConsultation/percentage`, bodyUpdateAgents)
    } catch (e) {
      console.error(e)
    }
  },

  async handleRemoveAgentByProductCompanyLegalConsultation({ commit }, bodyRemoveAgents) {
    try {
      return await api.put(`/companyLegalConsultation/removeAgentByProduct`, bodyRemoveAgents)
    } catch (e) {
      console.error(e)
    }
  },

  async handleGetByIdCompanyLegalConsultation({ commit }, id) {
    try {
      return await api.get(`/companyLegalConsultation/byId/${id}`)
    } catch (e) {
      console.error(e)
    }
  },

  async handleGetCompleteHierarchyCompanyLegalConsultation({ commit }, id) {
    try {
      return await api.get(`/companyLegalConsultation/completeHierarchy/${id}`)
    } catch (e) {
      console.error(e)
    }
  },

  async handleOpenHierarchyCompleteCompanyLegalConsultation({ commit }, id) {
    try {
      return await api.put(`/companyLegalConsultation/open-Hierarchy/${id}`)
    } catch (e) {
      console.error(e)
    }
  },

  async handleUpdatecompanyLegalConsultation({ commit }, company) {
    try {
      return await api.put(`/companyLegalConsultation/update/${company._id}`, company)
    } catch (e) {
      console.error(e)
    }
  },

  async handleUpdateViewInManageCompany({ commit }, bodu) {
    try {
      return await api.put(`/companyLegalConsultation/viewInManageCompany`, bodu)
    } catch (e) {
      console.error(e)
    }
  },

  async handleUpdateOpencompanyLegalConsultation({ commit }, company) {
    try {
      return await api.put(`/companyLegalConsultation/open/${company._id}`, company)
    } catch (e) {
      console.error(e)
    }
  },

  async handleDeletecompanyLegalConsultation({ commit }, id) {
    try {
      return await api.delete(`/companyLegalConsultation/${id}`)
    } catch (e) {
      console.error(e)
    }
  },

  async handleClear({ commit }) {
    try {
      commit('SET_COMPANIES', []);
      commit('SET_COMPANIES_META', {});
    } catch (e) {
      console.error(e)
    }
  },

  async handlePaginationCompanyLegalConsultation({ commit, state}, payload) {
    try {
      let params = {};

      if (payload) {
        params = {
          user: payload.user || '',
          filter: payload.filter || '',
          skip: payload.skip || '',
          limit: state.companyPerPage,
          companyId: payload.companyId,
        }
      }
      const { data: response } = await api.post(`/companyLegalConsultation/pagination`, params)
      if (response && response.companies && response.companies.length > 0) {
        commit('SET_COMPANIES', response.companies);
        commit('SET_COMPANIES_META', response.meta);
      } else {
        commit('SET_COMPANIES', []);
        commit('SET_COMPANIES_META', {});
      }
    } catch (e) {
      console.error(e)
    }
  },

  async handlePaginationReportCompanyLegalConsultation({ commit, state}, payload) {
    try {
      let params = {};

      if (payload) {
        params = {
          user: payload.user || '',
          search: payload.search || '',
          startDate: payload.startDate,
          endDate: payload.endDate,
          skip: payload.skip || '',
          limit: state.companyPerPageReport,
        }
      }
      const { data: response } = await api.post(`/companyLegalConsultation/pagination/report`, params)
      if (response && response.companies && response.companies.length > 0) {
        commit('SET_COMPANIES_REPORT', response.companies);
        commit('SET_COMPANIES_META_REPORT', response.meta);
      } else {
        commit('SET_COMPANIES_REPORT', []);
        commit('SET_COMPANIES_META_REPORT', {});
      }
    } catch (e) {
      console.error(e)
    }
  },
  resetCompanyLegalConsultationMetaReport({ commit }) {
    commit('SET_COMPANIES_META_REPORT', {})
  },
}

export const getters = {
  importedCompany: (state) => state.importedCompany,
  companiesMeta: (state) => state.companiesMeta,
  companies: (state) => state.companies,
  companiesReportMeta: (state) => state.companiesReportMeta,
  companiesReport: (state) => state.companiesReport,
  companyPerPage: (state) => state.companyPerPage,
}
