<template>
  <div class="form-field">
    <label v-if="label">{{ label }}</label>
    <div class="field">
      <input
        type="text"
        v-model="search"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="onChange($event)"
        @keydown.down="onArrowDown"
        @keydown.up="onArrowUp"
        @keydown.enter="onEnter"
        @keydown.esc="onEsc"
      />
      <div class="icon">
        <i
          class="fas fa-search"
          v-b-tooltip.lefttop.hover
          title="Clique aqui para listar todas as opções"
          v-if="!search"
          @click="searchAll"
        ></i>
        <i
          class="fa fa-plus"
          v-else-if="search && !value"
          aria-hidden="true"
          v-b-tooltip.lefttop.hover
          title="Clique aqui para adicionar"
          @click="add"
        ></i>
        <i 
          class="fas fa-edit" 
          v-else 
          aria-hidden="true" 
          v-b-tooltip.lefttop.hover
          title="Clique aqui para editar"
          @click="edit"
        ></i>
      </div>
    </div>
    <ul v-show="isOpen" class="autocomplete-results">
      <li
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ result[_value] }}
      </li>
    </ul>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'BaseAutocomplete',
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
    },
    _key: {
      type: String,
      default: '_id',
    },
    _value: {
      type: String,
      default: 'value',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: '',
      required: true,
    },
    placeholder: {
      type: String,
    },
    type: {
      type: String,
    },
    parent: {
      type: String,
    },
  },
  data() {
    return {
      id: '',
      search: '',
      results: [],
      isOpen: false,
      arrowCounter: -1,
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  watch: {
    value() {
      if (this.value === '') {
        this.search = ''
      }
    },
  },
  methods: {
    ...mapActions('hierarchy', {
      setFormData: 'setFormData',
    }),
    add() {
      this.$emit('add', {
        name: this.search,
        type: this.type,
        parent: this.parent,
      })
    },
    searchAll() {
      this.$emit('search')
    },
    edit() {
      this.$emit('edit', { _id: this.value, type: this.type })
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1
      }
    },
    onEnter() {
      this.setResult(this.results[this.arrowCounter])
      this.search = this.results[this.arrowCounter][this._value]
      this.isOpen = false
      this.arrowCounter = -1
    },
    onEsc() {
      this.isOpen = false
    },
    onChange() {
      this.filterResults()
      this.isOpen = this.results.length > 0 ? true : false
      this.$emit('input', undefined)
    },
    filterResults() {
      this.results = this.items.filter(
        (item) =>
          item[this._value].toLowerCase().indexOf(this.search.toLowerCase()) >
          -1
      )
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false
        this.arrowCounter = -1
      }
    },
    setResult(result) {
      this.search = result[this._value]
      this.id = result[this._key]
      this.isOpen = false
      this.$emit('input', result[this._key])
    },
    setSearch(item) {
      this.search = item.name
      this.isOpen = false
      this.$emit('input', item._id)
    },
  },
}
</script>
<style lang="scss" scoped>
.form-field {
  display: flex;
  flex-direction: column;
  label {
    font-size: 18px;
    font-weight: 700;
  }
  .field {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 280px;

    .icon {
      margin-left: -25px;
      z-index: 2;
      cursor: pointer;
    }

    input {
      height: 48px;
      width: 100%;

      font-size: 16px;

      padding-left: 16px;
      padding-right: 35px;

      border: 1px solid var(--dark-blue);
      border-radius: 4px;

      background: var(--white);

      cursor: pointer;

      &:hover {
        background: var(--light-gray);
      }
      &:disabled {
        cursor: not-allowed;
        background-color: #cecece;
      }
    }
    // BEGIN TODO
    // MELHORAR O SISTEMA DE EMIT ADD
    // END TODO
  }
}

.options {
  font-size: 16px;
  font-weight: 400;
  background: var(--white);

  &:hover {
    background: var(--blue);
  }
}

.form-field {
  position: relative;
  input {
    position: relative;
  }
}

.autocomplete-results {
  z-index: 1000;
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  background-color: white;
  height: fit-content;
  overflow: auto;
  position: absolute;
  top: 84px;
  left: 0px;
  width: 100%;
  max-height: 320px;

  box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: var(--primary);
  color: white;
}
</style>
