<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
    <cookie-law theme="dark-lime" buttonText="Ok, aceito!">
      <div slot="message">
        Este site usa cookies para garantir que você obtenha uma melhor
        experiência.
      </div>
    </cookie-law>
  </div>
</template>
<script>
const default_layout = 'default'

import CookieLaw from 'vue-cookie-law'

export default {
  components: { CookieLaw },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout'
    },
  },
  created() {
    if (window.location.href.indexOf('/#') !== -1) {
      let withouthash = window.location.href.replace('/#', '')
      window.location.href = withouthash
    }
    if (window.location.host !== 'www.hofacilweb.app.br')
      window.location.href = window.location.href.replace(
        window.location.host,
        'www.hofacilweb.app.br'
      )
  },
}
</script>
<style>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

:root {
  --dark-blue: #082342;
  --sapphire-blue: #00537b;
  --blue: #028ded;
  --green: #55bb00;
  --light-gray: #f4f4f4;
  --white: #ffffff;
  --border-input: #c0c3c7;
  --navy-blue: #022245;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body,
html,
#app {
  height: 100%;
  width: 100%;
  font-family: 'Montserrat', serif;
}
.table > table th,
.table > table td {
  border: 0px;
}

.table tr:first-child th {
  background: #ffffff;
}
.table tr:nth-child(2) th {
  vertical-align: middle;
}
.table tr:nth-child(3) th {
  vertical-align: middle;
}

.table td {
  padding: 5px;
  color: #808080;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  vertical-align: middle!important;
}

.table {
  margin: 10px 0 50px;
}

.table #search-cell {
  text-align: left;
}
.table-custom thead tr:last-child {
  display: none;
}
.fa-check {
  color: green;
  font-size: 23px;
}
.fa-times {
  color: red;
  font-size: 23px;
}
.hidden {
  display: none;
}
#table-users.table thead th {
  background-color: var(--sapphire-blue);
  color: var(--white);
}

.hidden {
  display: none;
}

#login-card {
  box-shadow: 4px 6px 20px 0px #222222;
  max-width: 680px;
  max-height: 850px;
  width: 100%;
  height: 100%;
  margin: 0px 30px;
  box-sizing: border-box;
}

#login-card input {
  border: 0;
  border-bottom: 1px solid #cccccc;
  border-radius: 0;
  margin: 20px 0;
  padding-top: 18px;
  padding-left: 0;
  outline: none;
}

#login-card input:focus {
  outline: none;
}

#login-card input::placeholder,
#login-card input:-ms-input-placeholder,
#login-card input::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaaaaa;
  opacity: 1; /* Firefox */
}

.card-header h1 {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: #999;
  padding: 0;
  margin: 0;
}

button#login-button {
  margin-top: 20px;
  font-weight: 500;
  color: var(--white);
  border-radius: 0;
  background-color: #ccdb3a;
  border: none;
}

.fa-history {
  color: #ffa500;
}
.loading-button {
  width: 1.25rem !important;
  height: 1.25rem !important;
}
.btn_export_modal {
  background-color: #cbd63e !important;
  color: #022245 !important;
  font-weight: 600 !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  font-size: 15px !important;
  line-height: 24px !important;

  i {
    color: #022245 !important;
    margin-right: 6px !important;
  }

  img {
    width: 15px !important;
    height: 15px !important;
    margin-right: 6px !important;
  }
}
.router-link-text-decoration {
  text-decoration: none !important;
}
.modal-font-family {
  font-family: 'Montserrat', serif !important;
}
.word-break {
  word-break: break-word !important;
}
.link-pubchem-cas{
  font-size: 15px!important;
  color: #FFFFFF!important;
}

.link-pubchem-cas:hover {
  text-decoration: underline solid #FFFFFF 12%;
}
.cursor-pointer{
  cursor: pointer!important;
}

</style>
