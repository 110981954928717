<template>
  <button @click="$emit('click')" class="button-table" :disabled="disabled" :class="{selected}">
    <slot />
  </button>
</template>

<script>

export default {
  name: 'BaseButtonTable',
  props: {
    selected: { type: Boolean },
    disabled: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.button-table {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
  outline: none !important;
  padding: 1.5rem;
  line-height: 0;

  font-weight: bold;

  color: var(--dark-blue);

  background: #F7F7F7;
  border: 1px solid var(--dark-blue);
  border-radius: 8px;

  transition: background 0.2s ease-in-out;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    border-color: transparent;
  }

  &.selected, &:hover:not(:disabled) {
    background-color: var(--sapphire-blue);
    color: #F1F6F2;
  }
}
</style>
