import api from '../../utils/api'

export const state = {

}

export const mutations = {

}

export const actions = {
  async handleCreate(_, role) {
    try {
      return await api.post('/role', role)
    } catch (err) {

    }
  },

  async handleGet(_, roleId) {
    try {
      return await api.get(`/role/${roleId}`)
    } catch (err) {

    }
  },


  async handleOpen(_, roleId) {
    try {
      return await api.put(`/role/open/${roleId}`)
    } catch (err) {

    }
  },

  async handleUpdade(_, role) {
    try {
      return await api.put(`/role/${role._id}`, role)
    } catch (err) {

    }
  },

  async handleDelete(_, roleId) {
    try {
      return await api.delete(`/role/${roleId}`)
    } catch (err) {

    }
  },

  async handleGetByCompany(_, companyId) {
    try {
      return await api.get(`/role/by_company/${companyId}`)
    } catch (err) {

    }
  },


  async handleGetByUserNoLinkedSectorAll(_, body) {
    try {
      return await api.get(`/role/by_user_no_linked_sector/${body.userId}/${body.sectorId}`)
    } catch (err) {

    }
  },


  async handleSetSector(_, body) {
    try {
      return await api.post(`/role/set_sector`, body)
    } catch (err) {

    }
  },

}

export const getters = {

}
