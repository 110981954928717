const context = require.context('.', true, /\.js$/)
const modules = {}

context.keys().forEach((fileName) => {
  if (fileName === './index.js') return

  const moduleName = fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')

  modules[moduleName] = {
    namespaced: true,
    ...context(fileName)
  }
})

export default modules
