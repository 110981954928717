var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"base-table"},[_c('thead',[_c('tr',_vm._l((_vm.columns),function(column,i){return _c('th',{key:("column_" + (column.key))},[_c('div',{staticClass:"content"},[_vm._t(("header-" + (column.key)),function(){return [_vm._v("\n            "+_vm._s(column.value)+"\n          ")]},{"item":column,"index":i}),(column.sort)?_c('div',{staticClass:"sort-buttons"},[_c('i',{staticClass:"fas fa-chevron-up",on:{"click":function($event){return _vm.$emit('sort-by', {
                page: _vm.currentPage,
                sortBy: typeof column.sort === 'boolean' ? column.key :column.sort,
                sortIn: 'asc'
              })}}}),_c('i',{staticClass:"fas fa-chevron-down",on:{"click":function($event){return _vm.$emit('sort-by', {
                page: _vm.currentPage,
                sortBy: typeof column.sort === 'boolean' ? column.key : column.sort,
                sortIn: 'desc'
              })}}})]):_vm._e()],2)])}),0)]),_c('tbody',_vm._l((_vm.data),function(item,i){return _c('tr',{key:("row_" + i)},_vm._l((_vm.columns),function(column,j){return _c('td',{key:("row_" + i + "_column_" + j)},[_c('div',{staticClass:"content"},[_vm._t(("body-" + (column.key)),function(){return [(item[column.key])?[_vm._v("\n              "+_vm._s(item[column.key])+"\n            ")]:_vm._e()]},{"item":item,"index":i})],2)])}),0)}),0),_c('tfoot',[(_vm.quantityPages > 1)?_c('tr',[_c('td',{attrs:{"colspan":_vm.columns.length}},[_c('div',{staticClass:"content navigation"},[_c('base-button-table',{attrs:{"disabled":_vm.backDisabled},on:{"click":function($event){return _vm.setPage(_vm.currentPage - 1)}}},[_vm._v("\n           Anterior\n          ")]),_vm._l((5),function(number){return (_vm.CUR_PAGE+number-1 < _vm.quantityPages)?_c('base-button-table',{key:("page_" + number),attrs:{"selected":_vm.PAGE_SELECTED(_vm.CUR_PAGE+number-1)},on:{"click":function($event){return _vm.setPage(_vm.CUR_PAGE+number-1)}}},[_vm._v("\n            "+_vm._s(_vm.CUR_PAGE+number-1)+"\n          ")]):_vm._e()}),_c('base-button-table',{attrs:{"disabled":_vm.nextDisabled},on:{"click":function($event){return _vm.setPage(_vm.currentPage + 1)}}},[_vm._v("\n            Próximo\n          ")])],2)])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }