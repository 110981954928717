<template>
  <div >
    <b-modal
      body-class="p-0"
      id='userProfile'
      ref='userProfile'
      no-close-on-esc
      no-close-on-backdrop
      size='lg'
      hide-footer
      hide-header
      title='Cadastro'>
      <component class='dd' :is='component' @next='next' />
    </b-modal>
  </div>
</template>

<script>

import PersonalData from './Steps/PersonalData'
import HowManyPeopleWorkInTheCompany from './Steps/HowManyPeopleWorkInTheCompany'
import AgePositionCompany from './Steps/AgePositionCompany'
import AreaOfWorkSalaryRange from './Steps/AreaOfWorkSalaryRange'
import ReasonsToUse from './Steps/ReasonsToUse'
import MainDifficulty from './Steps/MainDifficulty'
import Confirmation from './Steps/Confirmation'
import { mapActions } from 'vuex'

export default {
  name: 'UserProfile',
  components: {
    PersonalData,
    HowManyPeopleWorkInTheCompany,
    AgePositionCompany,
    AreaOfWorkSalaryRange,
    ReasonsToUse,
    MainDifficulty,
    Confirmation
  },
  props: {
    step: { type: Number, default: 1 },
    createdAccountAtCheckout: { type: Boolean, default: false }
  },
  data() {
    return {
      component: '',
      userProfileReset: {
        username: null,
        cpf: null,
        birthDate: null,
        phone: null,
        password: '',
        passwordConfirm: null,
        token: null,
        createdAccountAtCheckout: false,
        numberOfPeopleSelected: {
          value: null,
          description: null
        },
        company: null,
        whatManagementSoftwareYouUse: null,
        age: {
          value: null,
          description: null
        },
        companyPosition: {
          value: null,
          description: null
        },
        actingArea: {
          value: null,
          description: null
        },
        salaryRange: {
          value: null,
          description: null
        },
        reasonsToUse: [],
        mainDifficulty: '',
        register: false
      },
      stepComponent: null,
      components: {
        1: 'PersonalData',
        2: 'HowManyPeopleWorkInTheCompany',
        3: 'AgePositionCompany',
        4: 'AreaOfWorkSalaryRange',
        5: 'ReasonsToUse',
        6: 'MainDifficulty',
        7: 'Confirmation'
      }
    }
  },
  directives: {},
  computed: {
    userProfile() {
      return this.$store.getters['user/userProfile']
    }
  },
  validations() {
    return {}
  },
  async mounted() {
    try {
      this.userProfileReset.register = this.createdAccountAtCheckout
      await this.handleSetuserProfile(this.userProfileReset)
      this.userProfile.createdAccountAtCheckout = this.createdAccountAtCheckout
      this.component = this.components[this.step]
      this.show()
    } catch (error) {
    }
  },
  created() {

  },
  methods: {
    ...mapActions('user', {
      handleSetuserProfile: 'handleSetuserProfile'
    }),
    hide() {
      this.$refs.userProfile.hide()
    },
    show() {
      this.$refs.userProfile.show()
    },
    next(step) {
      this.component = this.components[step]
    }
  },
  watch: {
    step() {
      this.component = this.components[this.step]
    }
  }
}
</script>

<style lang='scss' scoped>

.title_welcome {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 36px;
}
</style>
