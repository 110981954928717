<template>
  <section class='w-100 register p-3'>
    <b-alert
      show
      variant='danger'
      :class='{ hidden: !error }'
    >
      {{ error }}
    </b-alert>
    <section class='mb-3' >
      <img width='80' src='../../../../assets/images/logoCadastroPerfil.svg'/>
    </section>
    <section>
      <p><strong>Quantas pessoas</strong> trabalham em sua empresa?</p>
      <span>Esta informação nos ajudará a personalizar a sua experiência.</span>
      <section class='mt-3 mb-3'>
        <section v-for='(quantityPerson, i) in numberOfPeople' :key='i' class='d-inline-block mr-3 mb-3'>
          <base-button
            active-class='act'
            :style="userProfile.numberOfPeopleSelected.value === quantityPerson.value ? 'border: 2px solid var(--blue);' : ''"
            class='btn_quantity_person' @click='selectPersonQuantity(quantityPerson)'>
            {{ quantityPerson.description }}
          </base-button>
        </section>
      </section>

      <section>
        <base-input-text
          class='w-75'
          v-model='userProfile.company'
          label='E qual o nome da sua empresa?'
          type='test'
          block
        />
        <base-input-text
          class='w-75 mt-2'
          v-model='userProfile.whatManagementSoftwareYouUse'
          label='Qual software de gestão você utiliza para fazer seus laudos?'
          type='test'
          block
        />
      </section>

      <section class='d-flex justify-content-between align-items-center my-4'>
        <b-progress :value='30' height='10px' variant='primary' class='w-25'></b-progress>
        <b-overlay
          :show='loading'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='d-inline-block'
        >
          <base-button
            :disabled='!userProfile.numberOfPeopleSelected.value || !userProfile.company'
            @click='next()'
            class='btn mt-1'>
            <span class='text_btn'>Continuar</span>
          </base-button>
        </b-overlay>
      </section>
    </section>
  </section>
</template>

<script>

export default {
  namer: 'HowManyPeopleWorkInTheCompany',
  components: {},
  computed: {
    userProfile() {
      return this.$store.getters['user/userProfile']
    },
    message() {
      return this.$store.getters['access/message']
    },
    error() {
      return this.$store.getters['access/error']
    }
  },
  data() {
    return {
      loading: false,
      numberOfPeople: [
        {
          value: 1,
          description: 'Somente eu'
        },
        {
          value: 2,
          description: '2-10'
        },
        {
          value: 3,
          description: '11-50'
        },
        {
          value: 4,
          description: '51-200'
        },
        {
          value: 5,
          description: '201-500'
        },
        {
          value: 6,
          description: '501 ou mais'
        }
      ]
    }
  },
  validations() {
    return {}
  },
  created() {
    this.$store.dispatch('access/updateError', '')
    this.$store.dispatch('access/updateMessage', '')
  },
  async mounted() {
  },
  methods: {
    selectPersonQuantity(quantityPerson) {
      if (quantityPerson.value === this.userProfile.numberOfPeopleSelected.value) {
        this.userProfile.numberOfPeopleSelected = {
          value: null,
          description: null
        }
        return
      }
      this.userProfile.numberOfPeopleSelected = quantityPerson
    },
    next() {
      this.$emit('next', 3)
    }
  }
}
</script>

<style scoped>
.text_btn {
  font-weight: 600;
}

.btn {
  width: 130px;
  height: 45px;
  border-radius: 5px;
  border: none;
}

.btn:hover {
  color: white;
}

.btn_quantity_person {
  background: transparent;
  border-radius: 20px;
  color: #48626f;
  border: 2px solid #C1D5E4;
}

.btn_quantity_person:hover {
  border-color: var(--blue);
}
</style>
