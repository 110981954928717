import { addDays } from 'date-fns'
import api from '../../utils/api'

export const state = {
  current: undefined,
  user: {},
  users: [],
  usersMeta: {},
  usersUtm: [],
  usersUtmMeta: {},
  loadingUserList: false,
  checkPendingInvoice: false,
  userEligibleUserFreeTrial: false,
  showRenewPlan: false,
  userProfile: {
    username: null,
    cpf: null,
    birthDate: null,
    phone: null,
    password: '',
    passwordConfirm: null,
    token: null,
    createdAccountAtCheckout: false,
    numberOfPeopleSelected: {
      value: null,
      description: null
    },
    company: null,
    whatManagementSoftwareYouUse: null,
    age: {
      value: null,
      description: null
    },
    companyPosition: {
      value: null,
      description: null
    },
    actingArea: {
      value: null,
      description: null
    },
    salaryRange: {
      value: null,
      description: null
    },
    reasonsToUse: [],
    mainDifficulty: '',
    register: false,
  },
  /*userProfile: {
    username: 'ragirfernandoactivecampaign@gmail.com',
    cpf: '18640780064',
    birthDate: '1991-07-20',
    phone: '63992715543',
    password: 'r19912016V',
    passwordConfirm: 'r19912016V',
    acceptedTerms: true,
    token: null,
    createdAccountAtCheckout: false,
    numberOfPeopleSelected: {
      value: 1,
      description: 'Somente eu'
    },
    company: 'Ragir fernando',
    age: {
      value: 1,
      description: '16 a 24 anos'
    },
    companyPosition: {
      value: 1,
      description: 'Engenheiro de Segurança do Trabalho'
    },
    actingArea: {
      value: 1,
      description: 'Trabalho em uma Consultoria de SST'
    },
    salaryRange: {
      value: 1,
      description: 'Até R$ 1.000,00'
    },
    reasonsToUse: [
      {
        value: 1,
        description: 'Praticidade na consulta de agentes químicos'
      },
      {
        value: 2,
        description: 'Facilidade no enquadramento do laudo de insalubridade'
      },
      {
        value: 3,
        description: 'Facilidade no enquadramento do LTCAT'
      },
    ],
    mainDifficulty: 'Facilidade no enquadramento do LTCAT',
    register: false,
  },*/
}

export const mutations = {
  UPDATE_USER(state, payload) {
    state.user = payload
  },
  SET_HIERARCHY(state, obj) {
    state.temp = obj
  },
  SET_CURRENT(state, user) {
    state.current = user
  },
  SET_USER(state, user) {
    state.user = user
  },
  SET_USERS(state, users) {
    state.users = users
  },
  SET_USERS_META(state, usersMeta) {
    state.usersMeta = usersMeta;
  },
  SET_USERS_UTM(state, usersUtm) {
    state.usersUtm = usersUtm
  },
  SET_USERS_UTM_META(state, usersUtmMeta) {
    state.usersUtmMeta = usersUtmMeta;
  },
  SET_LOADINGUSERLIST(state, loadingUserList) {
    state.loadingUserList = loadingUserList;
  },
  SET_USERPROFILE(state, userProfile) {
    state.userProfile = userProfile;
  },
  SET_CHECKPENDINGINVOICEE(state, checkPendingInvoice) {
    state.checkPendingInvoice = checkPendingInvoice;
  },
  SET_USERELIGIBLEUSERFREETRIAL(state, userEligibleUserFreeTrial) {
    state.userEligibleUserFreeTrial = userEligibleUserFreeTrial;
  },
  SET_MODAL_RENEW_PLAN(state, showRenewPlan) {
    state.showRenewPlan = showRenewPlan;
  }
}

export const actions = {
  async handleUserEligibleUserFreeTrial({ commit }) {
    try {
      const { data } = await api.post(`/users/eligibleUserFreeTrial`);
      commit('SET_USERELIGIBLEUSERFREETRIAL', data.eligible)
    } catch (e) {
      console.log(e)
    }
  },
  checkSession({ commit }) {
    const sessionId = localStorage.getItem("sessionId");
    if (sessionId) {
      api.get(`users/${sessionId}`).then((res) => {
        commit('UPDATE_USER', res.data)
      })
    } else {
      commit('UPDATE_USER', null)
    }
  },
  updateUser({ commit }, user) {
    localStorage.setItem("userHoFacil", JSON.stringify(user));
    commit('SET_CURRENT', user)
  },
  setCheckPendingInvoice({ commit }, checkPendingInvoice) {
    commit('SET_CHECKPENDINGINVOICEE', checkPendingInvoice)
  },
  setShowModalRenew({ commit }, showRenewPlan) {
    commit('SET_MODAL_RENEW_PLAN', showRenewPlan)
  },

  handleSetCurrentUser: ({ commit }, user) => commit('SET_CURRENT', user),
  handleSetuserProfile ({ commit }, userProfile) {
    commit('SET_USERPROFILE', userProfile)
  },
  async handleGetUser({ commit }, _id) {
    const { data: response } = await api.get(`admin/users/${_id}`);

    if (response && response.data.user) {
      commit('SET_USER', response.data.user);
    }
  },

  async transferPlan({ commit }, dataTransfer) {
    const { data: response } = await api.post(`admin/users/transferPlan`, dataTransfer);
    return response
  },

  async handleGetUsers({ commit }, payload) {
    try {
      commit('SET_LOADINGUSERLIST', true);
      let params = {};

      if (payload) {
        params = {
          search: payload.search || undefined,
          sortBy: payload.sortBy || undefined,
          sortIn: payload.sortIn || undefined,
          skip: payload.skip || undefined,
          limit: payload.limit || undefined,
        }
      }

      const { data: response } = await api.get('admin/users', { params });

      if (response && response.data.users && response.data.users.length > 0) {
        commit('SET_USERS', response.data.users);
        commit('SET_USERS_META', response.meta);
      } else {
        commit('SET_USERS', []);
        commit('SET_USERS_META', {});
      }
      commit('SET_LOADINGUSERLIST', false);
    }catch (err) {}
  },

  resetUsersUtmMeta({ commit }) {
    commit('SET_USERS_UTM_META', {})
  },

  async handleGetUsersUtm({ commit }, payload) {
    try {
      commit('SET_LOADINGUSERLIST', true);
      let params = {};

      if (payload) {
        params = {
          endDate: payload.endDate || undefined,
          startDate: payload.startDate || undefined,
          search: payload.search || undefined,
          skip: payload.skip || undefined,
          limit: payload.limit || undefined,
        }
      }

      const { data: response } = await api.get('admin/users/utm', { params });

      if (response && response.data.users && response.data.users.length > 0) {
        commit('SET_USERS_UTM', response.data.users);
        commit('SET_USERS_UTM_META', response.meta);
      } else {
        commit('SET_USERS_UTM', []);
        commit('SET_USERS_UTM_META', {});
      }
      commit('SET_LOADINGUSERLIST', false);
    }catch (err) {}
  },
  async handleUnblockUser(_, userId) {
    const { data } = await api.patch(`admin/users/unblock/${userId}`);
    return data;
  },
  async handleBlockUser(_, userId) {
    const { data } = await api.patch(`admin/users/block/${userId}`);
    return data;
  },
  async handleChangePassword(_, payload) {
    const { _id, ...body } = payload

    const { data } = await api.patch(`admin/users/change-password/${_id}`, body);

    return data;
  },
  async handleUserByUsername(_, username) {
    const { data } = await api.get(`users/userByUsername/${username}`);
    return data;
  },
  async handleChangePhone(_, body) {
    const { data } = await api.post(`users/changePhone`, body);
    return data;
  },
  async handleRequestToChangeData(_, body) {
    return await api.post(`users/requestToChangeData`, body);
  },
  async handleDeleteAccount(_, _id) {
    return (await api.delete(`admin/users/deleteAccount/${_id}`)).data;
  },
  async handleUserById(_, _id) {
    return (await api.get(`admin/users/userById/${_id}`)).data;
  },
  async handleUserProfileById(_, _id) {
    return (await api.get(`admin/users/userProfileById/${_id}`)).data;
  },
  async handleUpdateCpf(_, payload) {
    const { _id, ...body } = payload
    const { data } = await api.patch(`admin/users/updateCpf/${_id}`, body);
    return data;
  },
  async handTwoFactorAuthenticationReleaseDeadline(_, body) {
    return await api.put(`admin/users/twoFactorAuthenticationReleaseDeadline`, body);
  },
  async handleSetQuantityDaysExtraAccess(_, body) {
    return await api.put(`admin/users/quantityDaysExtraAccess`, body);
  },

  async handleUpdatePrivacyPolicy(_) {
    try {
      return await api.put(`admin/users/privacyPolicy`);
    } catch (e) {

    }
  },
  async handleUpdateTermsOfUse(_) {
    try {
      return await api.put(`admin/users/termsOfUse`);
    } catch (e) {

    }
  },
}

export const getters = {
  current: (state) => state.current,
  getUser: (state) => state.user,
  getUsers: (state) => state.users,
  getUsersMeta: (state) => state.usersMeta,
  renewable: (state) => {
    const user = state.current
    const plan = user.plan

    if (!plan.plan === 'AN1HO' || !plan.plan === 'PROMO') {
      return false
    }

    const NOW = new Date()
    const DATE_NOW_WITH_30_DAYS = addDays(now, 30)

    return plan.dueDate > NOW && plan.dueDate <= DATE_NOW_WITH_30_DAYS
  },
  loadingUserList: (state) => state.loadingUserList,
  userProfile: (state) => state.userProfile,
  checkPendingInvoice: (state) => state.checkPendingInvoice,
  userEligibleUserFreeTrial: (state) => state.userEligibleUserFreeTrial,
  usersUtm: (state) => state.usersUtm,
  usersUtmMeta: (state) => state.usersUtmMeta,
  showRenewPlan: (state) => state.showRenewPlan,
}
