import api from '../../utils/api'

export const state = {
  searchAgents: [],
  searchAgentsMeta: {}
}

export const mutations = {
  SET_SEARCH_AGENTS(state, searchAgents) {
    state.searchAgents = searchAgents
  },
  SET_SEARCH_AGENTS_META(state, searchAgentsMeta) {
    state.searchAgentsMeta = searchAgentsMeta
  }
}

export const actions = {
  resetSearchAgentsMeta({ commit }) {
    commit('SET_SEARCH_AGENTS_META', {})
  },

  async handleCreateSearchAgents(_, searchaAents) {
    await api.post(`/search-agents`, searchaAents)
  },

  async handleSearchAgents({ commit }, payload) {
    let params = {}
    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        filter: payload.filter || undefined,
        skip: payload.skip || undefined,
        limit: payload.limit || undefined
      }
    }
    const { data: response } = await api.get(`/search-agents`, { params })
    if (response && response.data.searchAgents) {
      commit('SET_SEARCH_AGENTS', response.data.searchAgents)
      commit('SET_SEARCH_AGENTS_META', response.meta)
    } else {
      commit('SET_SEARCH_AGENTS', [])
      commit('SET_SEARCH_AGENTS_META', {})
    }
  }
}

export const getters = {
  searchAgents: (state) => state.searchAgents,
  searchAgentsMeta: (state) => state.searchAgentsMeta
}
