import api from '../../utils/api'

export const state = {
  groupFundamentationLtcat: [],
  groupFundamentationUnhealthy: []
}

export const mutations = {
  SET_GROUPFUNDAMENTATIONLTCAT(state, groupFundamentationLtcat) {
    state.groupFundamentationLtcat = groupFundamentationLtcat
  },
  SET_GROUPFUNDAMENTATIONUNHEALTHY(state, groupFundamentationUnhealthy) {
    state.groupFundamentationUnhealthy = groupFundamentationUnhealthy
  },
}

export const actions = {
  async loadRationaleGroups({ commit }) {
    const {data} = await api.get(`/rationale-groups/`)
    commit('SET_GROUPFUNDAMENTATIONLTCAT', data.groupFundamentationLtcat)
    commit('SET_GROUPFUNDAMENTATIONUNHEALTHY', data.groupFundamentationUnhealthy)
  },
}

export const getters = {
  getGroupFundamentationLtcat: (state) => state.groupFundamentationLtcat,
  getGroupFundamentationUnhealthy: (state) => state.groupFundamentationUnhealthy,
}
