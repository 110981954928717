<template>
  <div class='create-promocode'>
    <!--    <h4>Cadastro</h4>-->
    <base-input-text
      v-model='v$.updateNew.title.$model'
      label='Título'
      :disabled="readOnly"
      placeholder='Digite o Título'
    />

    <label class='description-label'>Descrição</label>
    <ckeditor v-if='!readOnly' v-model='v$.updateNew.description.$model' ></ckeditor>

    <span v-else v-html='v$.updateNew.description.$model' class='description'></span>

    <base-input-text
      v-model='v$.updateNew.registrationDate.$model'
      label='Data da atualização'
      :disabled="readOnly"
      type='date'
    />

    <div v-if='!readOnly' class='d-flex'>
      <base-button
        @click='handleSubmit'
        :disabled='v$.$invalid'
        class='w-50'
      >
        {{ logId ? 'Atualizar' : 'Salvar' }}
      </base-button>
      <base-button
        outlinePrimary
        class='w-50 space'
        @click="close()"
      >
        Voltar
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/validators'

export default {
  name: 'AddUpdateNew',
  setup() {
    return { v$: useVuelidate() }
  },
  props:{
    readOnly: { type: Boolean, default: false },
    log: { type: String, default: '' },
  },
  data() {
    return {
      logId: '',
      html: '',
      errors: {
        client: ''
      }
    }
  },
  async created() {
    this.logId = this.log;

    if (this.logId) {
      const response = await this.handleGetUpdateNew(this.logId)
      if (!response.newUpdate) {
        this.$bvToast.toast(response.message, {
          title: 'Aviso',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
      }
    }else {
      await this.resetUpdateNew()
    }
  },
  computed: {
    ...mapGetters('updateNews', {
      updateNew: 'getUpdateNew'
    })
  },
  validations: {
    updateNew: {
      title: { required },
      description: { required },
      registrationDate: { required }
    }
  },
  methods: {
    ...mapActions('updateNews', {
      handleCreateUpdateNew: 'handleCreateUpdateNew',
      handleUpdateUpdateNew: 'handleUpdateUpdateNew',
      handleGetUpdateNew: 'handleGetUpdateNew',
      resetUpdateNew: 'resetUpdateNew'
    }),
    close() {
      this.$emit('close')
    },
    async handleSubmit() {
      let response
      if (this.logId) {
        response = await this.handleUpdateUpdateNew(this.logId)
      } else {
        response = await this.handleCreateUpdateNew()
      }
      let message = response.message
      if (response.errors) {
        message = response.errors
        this.$bvToast.toast(message, {
          title: 'Aviso',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
      }
      this.$emit('show-toast-response', message);
      this.resetUpdateNew()
    },
    async remove(id) {
      const response = await this.removePromocode(id)

      let message = ''
      let variant = ''

      if (response.message) {
        message = response.message
        variant = 'success'
        this.handleClearPromocode()
      }
      if (response.errors) {
        message = response.errors
        variant = 'danger'
      }

      this.$bvToast.toast(message, {
        title: 'Aviso',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
    }
  }

}
</script>

<style lang='scss' scoped>
.description {
  border: 1px solid var(--border-input);
  border-radius: 4px;
  background: var(--light-gray);
  padding-left: 26px;
  padding-top: 10px;
  min-height: 180px;
}
.description-label {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: -3px;
}
.create-promocode {
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    display: inline-block;
  }

  .space {
    margin-left: 10px;
  }

  h4 {
    font-weight: bold;
    border-bottom: 2px solid var(--blue);
    color: var(--blue);
    padding-bottom: 4px;
  }
}
</style>
