import api from '../../utils/api'

export const state = {
  creditCards:[],
}

export const mutations = {
  SET_CREDIT_CARDS(state, creditCards) {
    state.creditCards = creditCards;
  },
}

export const actions = {
  async handleGetCreditCards({ commit }) {
    const { data: response } = await api.get('/creditCard/all');
    if (response && response.creditCards) {
      commit('SET_CREDIT_CARDS', response.creditCards);
    } else {
      commit('SET_CREDIT_CARDS', []);
    }
  },
  async handleCreatePaymentMethods({ commit }, payload) {
    const { data: response } = await api.post('/creditCard/paymentMethods', payload);
    return response
  },
  async handleChangePaymentMethodSubscription({ commit }, payload) {
    const { data: response } = await api.post('/creditCard/changePaymentMethodSubscription', payload);
    return response
  },
}

export const getters = {
  getCreditCards: (state) => state.creditCards,
}
