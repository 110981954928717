<template>
  <div v-if='breadcrumb.tab' style='' class='d-flex flex-wrap align-items-end'>
    <b-modal
      header-bg-variant='info'
      header-text-variant='light'
      ref='alert'
      id='alert'
      scrollable
      hide-footer
      title='Atenção'
    >
      <section class='modal-font-family'>
        <p>
          A impressão do arquivo em Excel esta sendo processada,
          clique no botão abaixo para acessar a página de downloads.
        </p>
        <div class="w-100 justify-content-end align-items-center">
          <router-link class='' style='text-decoration: none; float: right' :to="{ name: 'RelatoriosConsultaLegal' }">
            <base-button
              class='btn_export px-4'
            >
              <span class='label'>Acessar</span>
            </base-button>
          </router-link>
        </div>
      </section>
    </b-modal>

    <b-modal
      header-bg-variant='info'
      header-text-variant='light'
      hide-footer
      id='select_format'
      title='Selecione o formato do arquivo'
      ref='select_format'
      size='lg'
    >
      <p
        v-if="(exportReportTab === 'insalubrity' || exportReportTab === 'ltcat') && userPreferences && userPreferences._id && userPreferences.showAnalysisEffectiveProtectionPermanentExhibition == false"
      >
        <span style='font-weight: 700' class='text-danger'>Atenção: </span>
        <span style='font-weight: 500'>
          As frases de orientação relacionadas à análise das informações inseridas em <i>Proteção eficaz e Exposição permanente</i> não serão impressas por seleção do usuário.
          Para alterar essa opção acesse <i>Minha conta > Preferências do usuário</i>. Caso queira manter assim, basta ignorar este aviso e prosseguir com a seleção do formato de arquivo a seguir.
        </span>
      </p>
      <b-form-group
        class='ml-2 objectiveAnalysisSelected'
        v-slot='{ ariaDescribedby }'>
        <b-form-radio-group
          id='checkbox-group-2'
          class='pr-2'
          v-model='reportFormat'
          :aria-describedby='ariaDescribedby'
          name='flavour-2'
          stacked
        >

          <b-form-radio
            class='item py-2 text'
            value='pdf'
          >
            <strong>
              Pdf(.pdf)
            </strong>
            <img width='20' class='mb-2' height='20' src='../../../assets/images/File-pdf.svg' alt='' />
          </b-form-radio>

          <b-form-radio
            v-if="exportReportTab === 'insalubrity' || exportReportTab === 'ltcat'"
            class='item py-2 text'
            value='docx'
          >
            <strong>
              Word(docx)
            </strong>
            <img width='20' class='mb-2' height='20' src='../../../assets/images/File-word.svg' alt='' />
          </b-form-radio>

          <b-form-radio
            class='item py-2 text'
            value='xlsx'
          >
            <strong>
              Excel(.xlsx)
            </strong>

            <img width='20' class='mb-2' height='20' src='../../../assets/images/File-excel.svg' alt='' />
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <b-row class='d-flex w-100 mx-1 justify-content-between'>
      <!--<a :href='xlsxLink' target='_blank'>
          imprimir
        </a>-->
        <b-overlay
          :show='exporting'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class=' w-100 justify-content-center'
        >
          <base-button
            block
            @click="generateReport()"
            class='w-100  btn_export d-flex justify-content-center align-items-center'
          >
            <span class='d-flex justify-content-center align-items-center'>
              <img src='@/assets/images/export.svg' class='fa fa-download'  alt=''/>
              Exportar
            </span>
          </base-button>
        </b-overlay>
      </b-row>
    </b-modal>

    <modal-confirm-action
      variant='info'
      refModal='showAnalysisEffectiveProtectionPermanentExhibition'
      idModal='showAnalysisEffectiveProtectionPermanentExhibition'
      description="Deseja que as frases de orientação relativas à proteção eficaz e exposição permanente apareçam no documento impresso?"
      title='Frases de proteção eficaz e exposição permanente'
      canceltext="Não"
      okText="Sim"
      @confirmed='setShowAnalysisEffectiveProtectionPermanentExhibition'
      @close='close'
    />

    <div v-if="breadcrumb.tab === 'legalSearch'" class='d-flex flex-wrap'>
      <base-button
        class='btn_export mr-1 mt-1 btn_whidth'
        @click="selectReportFormat('agentsSelected')"
      >
        <b-icon font-scale='1.1' class='pa-1 mr-2' icon='printer'></b-icon>
        <span>
          Clique aqui para exportar
        </span>
      </base-button>

      <base-button
        @click="callGlobalCustomEvent('showModalUploadLisCas')"
        v-if="!lookingForAgents && (user && user.plan && user.plan.plan !== 'TRIAL' || user.role === 'admin')"
        class='btn_export mr-1 mt-1 btn_whidth'
      >
        <i style='color: #022245;' class='fa fa-upload'></i>
        <span class='ml-2'>
          Importar
        </span>
      </base-button>
    </div>

    <div v-else-if="breadcrumb.tab === 'insalubrity'" class='d-flex flex-wrap align-items-end'>
      <base-button
        class='btn_export mr-1 mt-1 btn_whidth'
        @click="selectReportFormat('insalubrity')"
      >
        <b-icon font-scale='1.1' class='pa-1 mr-1' icon='printer'></b-icon>
        <span class='ml-2'>
          Clique aqui para imprimir
        </span>
      </base-button>
    </div>

    <div v-else-if="breadcrumb.tab === 'acgih'" class=''>
      <b-overlay
        :show='exporting'
        rounded
        opacity='0.6'
        spinner-small
        spinner-variant='primary'
        class='d-inline-block mr-1 mt-1'
      >
        <base-button
          class='btn_export btn_whidth'
          @click="callGlobalCustomEvent('generateReportACGIH')"
        >
          <img src='@/assets/images/export.svg' class='fa fa-download' />

          <span class='ml-2'>
            Exportar em Excel (.xls)
          </span>
        </base-button>
      </b-overlay>
    </div>

    <div v-else-if="breadcrumb.tab === 'pcmso'" class='d-flex flex-wrap align-items-end'>
      <b-overlay
        :show='exporting'
        rounded
        opacity='0.6'
        spinner-small
        spinner-variant='primary'
        class='d-inline-block mr-1 mt-1'
      >
        <base-button
          class='btn_export btn_whidth'
          @click="callGlobalCustomEvent('generateReportPCMSO')"
        >
          <img src='@/assets/images/export.svg' class='fa fa-download' />
          <span class='ml-2'>
            Exportar em Excel (.xls)
          </span>
        </base-button>
      </b-overlay>
    </div>

    <div v-else-if="breadcrumb.tab === 'ltcat'" class='d-flex flex-wrap align-items-end'>
      <base-button
        class='btn_export mr-1 mt-1 btn_whidth'
        @click="selectReportFormat('ltcat')"
      >
        <b-icon font-scale='1.1' class='pa-1 mr-1' icon='printer'></b-icon>
        <span class='ml-2'>
          Clique aqui para imprimir
        </span>
      </base-button>
    </div>

    <div v-else-if="breadcrumb.tab === 'quantitativeAssessment'">
      <b-overlay
        :show='exporting'
        rounded
        opacity='0.6'
        spinner-small
        spinner-variant='primary'
        class='d-inline-block mr-1 mt-1'
      >
        <base-button
          class='btn_export btn_whidth'
          @click="callGlobalCustomEvent('generateReportQuantitativeAssessment')"
        >
          <img src='@/assets/images/export.svg' class='fa fa-download' />
          <span class='ml-2'>
            Exportar em Excel (.xls)
          </span>
        </base-button>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { mapActions, mapGetters } from 'vuex'
import ModalConfirmAction from '@/components/ModalConfirmAction.vue'

export default {
  name: 'Buttons',
  components: {
    ModalConfirmAction
  },
  data() {
    return {
      exportReportTab: null,
      userPreferences: null,
      showAnalysisEffectiveProtectionPermanentExhibition: null,
      reportFormat: '',
      type: ''
    }
  },
  computed: {
    xlsxLink() {
      return `${process.env.VUE_APP_BASE_URL}/pdf/${this.user.id}/ltcat`
    },
    menuCollapsed() {
      return this.$store.getters['access/menuCollapsed']
    },
    user() {
      return this.$store.getters['user/current']
    },
    exporting() {
      return this.$store.getters['legalSearch/exporting']
    },
    lookingForAgents() {
      return this.$store.getters['legalSearch/lookingForAgents']
    },
    summaryMeta() {
      return this.$store.getters['legalSearch/summaryMeta']
    },
    ...mapGetters('breadcrumb', {
      breadcrumb: 'getBreadcrumb'
    }),
    ...mapGetters('effectiveProtection', {
      loadingEffectiveProtection: 'loadingEffectiveProtection'
    })
  },
  props: {},
  methods: {
    ...mapActions('reportsDownloads', {
      handleCreateReportDownloads: 'handleCreateReportDownloads'
    }),
    ...mapActions('legalSearch', {
      exportingExcel: 'exportingExcel'
    }),
    ...mapActions('userPreferences', {
      handleGetUserPreferences: 'handleGetUserPreferences',
      handleCreateUserPreferences: 'handleCreateUserPreferences',
      handleChangUserPreferences: 'handleChangUserPreferences'
    }),
    async generateReport() {
      if (!this.reportFormat) {
        this.$bvToast.toast('Selecione o formato do arquivo.', {
          title: 'Atenção',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
        });
        return
      }

      if (this.exportReportTab === 'agentsSelected' && this.reportFormat === 'pdf') {
        this.callGlobalCustomEvent('handleDownloadPdf')
        return
      }

      await this.exportingExcel(true)
      const payload = {
        user: this.user.id,
        type: this.exportReportTab,
        format: this.reportFormat,
        job: 'legalConsultationReports',
        parameters: {
          selectedFilters: this.summaryMeta,
        }
      }
      payload.parameters.selectedFilters.showAnalysisEffectiveProtectionPermanentExhibition = this.showAnalysisEffectiveProtectionPermanentExhibition
      const { data, status } = await this.handleCreateReportDownloads(payload)

      let message = data.message
      let variant = 'danger'

      await this.exportingExcel(false)

      if (status === 201) {
        message = data.message
        variant = 'success'
        this.$refs['alert'].show()
        this.$refs['select_format'].hide()
        return
      }

      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
    },

    async selectReportFormat(exportReportTab) {
      try {
        if(exportReportTab === 'agentsSelected'){
          this.showSelectFormat(exportReportTab)
          return
        }
        const { userPreferences } = await this.handleGetUserPreferences(this.user.id)

        this.userPreferences = userPreferences
        if(!this.userPreferences || this.userPreferences.showAnalysisEffectiveProtectionPermanentExhibition == null || this.userPreferences.showAnalysisEffectiveProtectionPermanentExhibition){
          this.exportReportTab = exportReportTab
          this.$bvModal.show('showAnalysisEffectiveProtectionPermanentExhibition')
          return
        }
        this.showAnalysisEffectiveProtectionPermanentExhibition = this.userPreferences.showAnalysisEffectiveProtectionPermanentExhibition
        this.showSelectFormat(exportReportTab)
      } catch (e) {

      }
    },
    showSelectFormat(exportReportTab){
      this.exportReportTab = exportReportTab
      this.$bvModal.show('select_format')
    },

    setShowAnalysisEffectiveProtectionPermanentExhibition(){
      this.showAnalysisEffectiveProtectionPermanentExhibition = true
      this.$bvModal.hide('showAnalysisEffectiveProtectionPermanentExhibition')
      this.showSelectFormat(this.exportReportTab)
    },

    close(){
      this.$bvModal.hide('showAnalysisEffectiveProtectionPermanentExhibition')
      this.showAnalysisEffectiveProtectionPermanentExhibition = false
      this.showSelectFormat(this.exportReportTab)
    },
    callGlobalCustomEvent(event) {
      eventBus.$emit(event)
    }
  },
  mounted() {
  }
}
</script>

<style lang='scss' scoped>
.custom-header-bg {
  background-color: #ffc107; /* cor de fundo personalizada */
  color: #fff; /* cor do texto personalizada */
}

.btn_export {
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 24px;

  i {
    color: #022245;
    margin-right: 6px;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
}

.btn_exposicao_permanente {
  height: 38px;
  bottom: 0;
  border: 1px solid #022245;
  background: #FFF;
  color: #022245;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 24px;

  i {
    color: #022245;
    margin-right: 6px;
  }

  img {
    width: 25px;
    height: 25px;
    margin-right: 6px;
  }
}
.btn_whidth{
  width: 260px
}
</style>
