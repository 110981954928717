import api from '../../utils/api'

import IUGU from "../../services/iugu"
import moment from 'moment'

export const state = {
  currentStep: { key: 'number-accesses', name: 'Quantidade de acessos', completed: false },
  steps: [],
  discount: 0,
  billingData: {
    hits: 'acessos_para_mim_minha_equipe',
    numberAccesses: 0,
    numeroUsuariosExistentes: 0,
    precoCompraUsuariosSubordinados: 0,
    precoCompraUsuariosSubordinadosSemDesconto: 0,
    type: 'person',
    companyName: '',
    cnpj: '',
    name: '',
    document: '',
    birthDate: '',
    stateRegister: '',
    cityRegister: '',
    email: '',
    phone: '',
    customPlanCode: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_id: '',
    utm_term: '',
    utm_content: '',
    adhesionContractAccepted: false,
    adhesionContractUrl: "",
    adhesionContractDate: "",
    adhesionContractName: "",
    address: {
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      cep: '',
      city: '',
      state: ''
    }
  },
  payment: {
    method: '',
    data: {
      first_name: '',
      last_name: '',
      number: '',
      month: '',
      year: '',
      cvv: '',
      installments: 1
    }
  },
  alertDetails: {
    open: false,
    message: '',
    type: ''
  },
  plan: {},
  emailIndication: null,
  promotion: {
    code: '',
  },
}

export const mutations = {
  SET_STEP_COMPLETED(state, { index, value }) {
    state.steps[index].completed = value
  },
  SET_STEP_UNSHIFT(state, steps) {
    state.steps = steps
  },
  SET_CURRENT_STEP(state, index) {
    state.currentStep = state.steps[index]
  },
  SET_BILLING_DATA(state, data) {
    state.billingData = data
  },
  SET_PAYMENT(state, data) {
    state.payment = data
  },
  SET_ALERT_DETAILS(state, data) {
    state.alertDetails = data
  },
  SET_PLAN(state, data) {
    state.plan = data
  },
  SET_PROMOTION(state, data) {
    state.promotion = data
  },
  SET_DISCOUNT(state, discount) {
    state.discount = discount
  },
  SET_PRECOCOMPRAUSUARIOSSUBORDINADOS(state, precoCompraUsuariosSubordinados) {
    state.billingData.precoCompraUsuariosSubordinados = precoCompraUsuariosSubordinados
  },
  SET_PRECOCOMPRAUSUARIOSSUBORDINADOS_SEM_DESCONTO(state, precoCompraUsuariosSubordinadosSemDesconto) {
    state.billingData.precoCompraUsuariosSubordinadosSemDesconto = precoCompraUsuariosSubordinadosSemDesconto
  },
  SET_EMAIL_INDICATION(state, emailIndication) {
    state.emailIndication = emailIndication
  },
  SET_NUMBER_ACCESSES(state, numberAccesses) {
    state.billingData.numberAccesses = numberAccesses
  },
}

function getIndexStep(state, stepKey) {
  let index = 0

  state.steps.forEach((step, i) => {
    if (step.key === stepKey) {
      index = i
    }
  })
  return index
}
function precoCompraUsuariosSubordinados(precoNoCheckout, totalUsuariosSubordinadosAoUsuarioMaster) {
  const descontoBase = 0.275;
  const maximumDiscount = 0.5;
  let total = 0;
  let desconto = (0.025 * totalUsuariosSubordinadosAoUsuarioMaster) + descontoBase
  if (desconto > maximumDiscount) {
    desconto = maximumDiscount
  }
  for (let i = 1; i <= totalUsuariosSubordinadosAoUsuarioMaster ; i++) {
    const precoPorAcesso = Number(precoNoCheckout) * (1 - desconto)
    total += Number(precoPorAcesso)
  }
  return total
}

export const actions = {
  handleResetBillingData: ({ commit }) => commit('SET_BILLING_DATA', {
    type: 'person',
    companyName: '',
    cnpj: '',
    name: '',
    document: '',
    stateRegister: '',
    cityRegister: '',
    email: '',
    phone: '',
    address: {
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      cep: '',
      city: '',
      state: ''
    }
  }),
  handleSetBillingData: ({ commit }, data) => commit('SET_BILLING_DATA', data),
  handleResetPayment: ({ commit }) => commit('SET_PAYMENT', {
    method: '',
    data: {
      first_name: '',
      last_name: '',
      number: '',
      month: '',
      year: '',
      cvv: '',
      installments: 1
    }
  }),
  handleSetPayment: ({ commit }, data) => commit('SET_PAYMENT', data),
  handleSetEmailindication: ({ commit }, emailIndication) => commit('SET_EMAIL_INDICATION', emailIndication),
  handleSetdiscount: ({ commit }, discount) => commit('SET_DISCOUNT', discount),
  handleSetAlertDetails: ({ commit }, data) => commit('SET_ALERT_DETAILS', data),
  handleResetAlertDetails: ({ commit }) => commit('SET_ALERT_DETAILS', {
    open: false,
    message: '',
    type: '',
  }),
  handleSetPlan: ({ commit }, data) => commit('SET_PLAN', data),
  handleSetPromotion: ({ commit }, data) => {commit('SET_PROMOTION', data)},
  handleResetPromotion: ({ commit }) => commit('SET_PROMOTION', {
    code: '',
  }),
  handleResetSteps: ({ commit }) => commit('SET_CURRENT_STEP', 0),
  handleNextStep({ state, commit }) {
    const currentStep = state.currentStep
    let index = getIndexStep(state, currentStep.key)

    commit('SET_STEP_COMPLETED', { index, value: true })
    commit('SET_CURRENT_STEP', index + 1)
  },
  handleBackStep({ state, commit }) {
    const currentStep = state.currentStep
    let index = getIndexStep(state, currentStep.key)

    if (currentStep.key === 'confirmation') {
      commit('SET_PAYMENT', {
        method: '',
        data: {
          first_name: '',
          last_name: '',
          number: '',
          month: '',
          year: '',
          cvv: '',
          installments: 1
        }
      })
    }

    commit('SET_STEP_COMPLETED', { index, value: false })
    commit('SET_CURRENT_STEP', index - 1)
  },
  async handleGetTokenCreditCard({ state }) {
    const profile = state.billingData
    const payment = state.payment

    let response = await IUGU.getTokenCreditCard({ profile, payment })

    return response
  },
  async handleSubmitCheckout({ state }) {
    const profile = state.billingData
    const payment = state.payment
    const plan = state.plan
    const promotion = state.promotion
    const emailIndication = state.emailIndication
    const adhesionContractAccepted = profile.adhesionContractAccepted
    const adhesionContractUrl = profile.adhesionContractUrl
    const adhesionContractDate = profile.adhesionContractDate
    const adhesionContractName = profile.adhesionContractName

    profile.document = profile.document.replace(/\D+/g, '')
    profile.address.cep = profile.address.cep.replace(/\D+/g, '')

    delete payment.data

    const body = {
      profile: {
        name: profile.name || '',
        email: profile.email || '',
        phone: profile.phone.replace(/\D+/g, '') || '',
        cpf: profile.document || '',
        cnpj: profile.cnpj || '',
        companyName: profile.companyName || '',
        type: profile.type || '',
        address: profile.address.street || '',
        complement: profile.complement || '',
        number: profile.address.number || '',
        cep: profile.address.cep || '',
        neighborhood: profile.address.neighborhood || '',
        city: profile.address.city || '',
        state: profile.address.state || '',
        stateRegister: profile.stateRegister || '',
        cityRegister: profile.cityRegister || '',
      },
      customPlanCode: profile.customPlanCode ? profile.customPlanCode : '',
      numberAccesses: profile.numberAccesses ? Number(profile.numberAccesses) : 0,
      utm_source: profile.utm_source ? profile.utm_source : null,
      utm_medium: profile.utm_medium ? profile.utm_medium : null,
      utm_campaign: profile.utm_campaign ? profile.utm_campaign : null,
      utm_id: profile.utm_id ? profile.utm_id : null,
      utm_term: profile.utm_term ? profile.utm_term : null,
      utm_content: profile.utm_content ? profile.utm_content : null,
      payment: {
        method: payment.method || ''
      },
      plan: plan.code || '',
      code: promotion.code || '',
      emailIndication: emailIndication || null,
      adhesionContractAccepted,
      adhesionContractUrl,
      adhesionContractDate,
      adhesionContractName,
    }


    if (payment.method === 'credit_card') {
      body.payment.creditCard = payment.token
      body.month = payment.installments
    }

    const { data } = await api.post('/checkout', body)

    return data
  },
  async handleGetAmountPaidPlan(_, planId) {
    const { data } = await api.get(`/checkout/amountPaidPlan/${planId}`)

    return data
  },
  handleSetStepUnshift: ({ commit }, data) => commit('SET_STEP_UNSHIFT', data),
  async handleSeNumberAccesses({ commit, numberAccesses }){
    commit('SET_NUMBER_ACCESSES', numberAccesses)
  },
  async calculateTotalPlan({ state, commit }) {
    const desconto = state.discount ? state.discount : 0
    const numberAccesses = parseFloat(state.billingData.numberAccesses)
    const valorCompraUsuariosSubordinados = precoCompraUsuariosSubordinados((parseFloat(state.plan.value) - desconto), numberAccesses);
    const valorCompraUsuariosSubordinadosSemsedeconto = precoCompraUsuariosSubordinados(parseFloat(state.plan.value), numberAccesses);
    commit('SET_PRECOCOMPRAUSUARIOSSUBORDINADOS', valorCompraUsuariosSubordinados ? valorCompraUsuariosSubordinados : 0)
    commit('SET_PRECOCOMPRAUSUARIOSSUBORDINADOS_SEM_DESCONTO', valorCompraUsuariosSubordinadosSemsedeconto ? valorCompraUsuariosSubordinadosSemsedeconto : 0)
    return valorCompraUsuariosSubordinados
  },
  async handleChangeSubscriptionPaymentMethod(_, subscription) {
    return await api.put('/checkout/subscriptionsPaymentMethod', subscription)
  },
}

export const getters = {
  getSteps: (state) => state.steps,
  getCurrentStep: (state) => state.currentStep,
  getBillingData: (state) => state.billingData,
  getPayment: (state) => state.payment,
  getIsCreditCard: (state) => state.payment.method === 'credit_card',
  getAlertDetails: (state) => state.alertDetails,
  getPlan: (state) => state.plan,
  getPromotion: (state) => state.promotion,
  getDiscount: (state) => state.discount,
  getEmailIndication: (state) => state.emailIndication,
}
