import api from '../../utils/api'

export const state = {
  chemicalInventoryReport: {
    body: '',
    _id: null
  },
};

export const mutations = {
  SET_CHEMICALINVENTORYREPORT(state, chemicalInventoryReport) {
    state.chemicalInventoryReport = chemicalInventoryReport;
  },
}

export const actions = {
  async handleCreateChemicalInventoryReport({ state }) {
    const body = state.chemicalInventoryReport;
    const data = await api.post('/chemicalInventoryReport', body);
    return data;
  },

  async handleGetChemicalInventoryReport({ commit }) {
    const { data: response } = await api.get('/chemicalInventoryReport');
    if (response && response.data.chemicalInventoryReport) {
      commit('SET_CHEMICALINVENTORYREPORT', response.data.chemicalInventoryReport);
    }
  },

  async handleUpdateChemicalInventoryReport({ state },) {
    const body = state.chemicalInventoryReport;
    const data = await api.put(`/chemicalInventoryReport/${body._id}`, body);
    return data;
  },
}

export const getters = {
  getChemicalInventoryReport: (state) => state.chemicalInventoryReport,
}
