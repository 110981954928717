import api from '../../utils/api'

export const state = {
  logsFrequencyOfUse:[],
  logsFrequencyOfUseMeta:{},
}

export const mutations = {
  SET_LOGS_FREQUENCY_OF_USE(state, logsFrequencyOfUse) {
    state.logsFrequencyOfUse = logsFrequencyOfUse;
  },
  SET_LOGS_FREQUENCY_OF_USE_META(state, logsFrequencyOfUseMeta) {
    state.logsFrequencyOfUseMeta = logsFrequencyOfUseMeta;
  }
}

export const actions = {
  resetLogsFrequencyOfUseMeta({ commit }) {
    commit('SET_LOGS_FREQUENCY_OF_USE_META', {})
  },
  async createUserActionLogs(_, log) {
    await api.post(`/userActionLogs`, log)
  },
  async createUserActionsLogs(_, actions) {
    await api.post(`/userActionLogs/actionLogs`, actions)
  },
  async createUserActionLogsLocation(_, location) {
    await api.post(`/userActionLogs/location`, location)
  },

  async handleGetLogsFrequencyOfUse({ commit }, payload) {
    let params = {};

    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        filter: payload.filter || undefined,
        action: payload.action || undefined,
        skip: payload.skip || undefined,
        limit: payload.limit || undefined,
      }
    }
    const { data: response } = await api.get('/userActionLogs', { params });
    if (response && response.logsFrequencyOfUse && response.logsFrequencyOfUse.data && response.logsFrequencyOfUse.data.logsFrequencyOfUse) {
      commit('SET_LOGS_FREQUENCY_OF_USE', response.logsFrequencyOfUse.data.logsFrequencyOfUse);
      commit('SET_LOGS_FREQUENCY_OF_USE_META', response.logsFrequencyOfUse.meta);
    } else {
      commit('SET_LOGS_FREQUENCY_OF_USE', []);
      commit('SET_LOGS_FREQUENCY_OF_USE_META', {});
    }
  },

  async exportFrequencyOfUse( { state }) {
    const payload = state.logsFrequencyOfUseMeta
    let params = {}
    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        filter: payload.filter || undefined,
      }
    }
    const { data: response } = await api.get(`/userActionLogs/csv`, { params });
    return response.data.userActionLogs
  },
}

export const getters = {
  getlogsFrequencyOfUse: (state) => state.logsFrequencyOfUse,
  getlogsFrequencyOfUseMeta: (state) => state.logsFrequencyOfUseMeta,
}
