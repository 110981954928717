<template>
  <div v-if='event'  class='event text-center'>
    <section class='py-1'>
      <section class='d-flex align-items-start mb-3'>
        <section class='infoLink'>
          <img width='50px' src='@/assets/images/infoLink.svg' />
        </section>

        <section class='containerTitleDescription'>
          <strong class='title'>{{ event.title }}</strong><br />
          <strong class='description'>{{ event.description }}</strong><br />
        </section>
      </section>

      <section class='w-100'>
        <section class='linkContainer d-flex'>
          <section class='iconeGoogleMeet'>
            <img width='42px' src='@/assets/images/Icone_Google_Meet.svg' />
          </section>

          <section class='align-self-center w-100' >
            <a :href='event.link' target='_blank' class='link'>Clique aqui para participar da reunião </a><br />
          </section>
        </section>
      </section>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Event',
  props:{
    event: { type: Object, default: null },
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  methods: {
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
.event {
  max-width: 600px;
  min-width: 500px;
  background: #022245;
  box-shadow: 0 0 2px rgba(14, 31, 53, 0.12);
  border-radius: 5px;
  padding: 7px 15px;
}

.infoLink {
  background: rgba(4, 168, 243, 0.1);
  border-radius: 50%;
  padding: 5px;
  margin-right: 5px;
}

.containerTitleDescription {
  border-left: 2px solid #ECF0F3;
  padding-left: 5px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #ECF0F3;
}

.description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #808080;
}

.linkContainer {
  width: 100%;
  background: #CBD63E;
  border-radius: 5px;
  height: 43px;
}

.iconeGoogleMeet {
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(14, 31, 53, 0.12);
  border-radius: 5px 0 0 5px;
  height: 100%;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  color: #022245;

}

.container-edit {
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: end;
}
</style>
