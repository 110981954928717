import api from '../../utils/api'
import format from 'date-fns/esm/format/index'

export const state = {}

export const mutations = {}

export const actions = {
  async handleGetLinkUpdatePhone(_, params) {
    return (await api.get(`/linkUpdatePhone`, { params })).data
  },
  async handleUpdateLinkUpdatePhone(_, id) {
    return await api.put(`/linkUpdatePhone/usedLink/${id}`)
  }
}

export const getters = {}
