export function profileIsFilled(user) {
  return !!(user.profile &&
    user.profile.name &&
    user.profile.birthday &&
    user.profile.phone &&
    user.profile.gender &&
    user.profile.cpf &&
    user.profile.address &&
    user.profile.number &&
    user.profile.neighborhood &&
    user.profile.cep &&
    user.profile.city &&
    user.profile.state)
}
