import api from '../../utils/api'

export const state = {
  paidReferenceValue: 0,
  numberExistingUsers: 0,
  additionalAccessQuantity: 0,
  token: '',
  totalPrice: 0,
  planId: 0,
  interestCharged: 0,
  billingData: {
    type: 'person',
    companyName: '',
    cnpj: '',
    name: '',
    document: '',
    birthDate: '',
    stateRegister: '',
    cityRegister: '',
    email: '',
    phone: '',
    customPlanCode: '',
    address: {
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      cep: '',
      city: '',
      state: ''
    }
  },
}

export const mutations = {
  SET_PAID_REFERENCE_VALUE(state, paidReferenceValue) {
    state.paidReferenceValue = paidReferenceValue
  },
  SET_NUMBER_EXISTING_USERS(state, numberExistingUsers) {
    state.numberExistingUsers = numberExistingUsers
  },
  SET_ADDITIONAL_ACCESS_QUANTITY(state, additionalAccessQuantity) {
    state.additionalAccessQuantity = additionalAccessQuantity
  },
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_TOTAL_PRICE(state, totalPrice) {
    state.totalPrice = totalPrice
  },
  SET_PLAN_ID(state, planId) {
    state.planId = planId
  },
  SET_INTEREST_CHARGED(state, interestCharged) {
    state.interestCharged = interestCharged
  },
  SET_BILLING_DATA(state, data) {
    state.billingData = data
  },
}

export const actions = {
  handleSetPaidReferenceValue: ({ commit }, paidReferenceValue) => commit('SET_PAID_REFERENCE_VALUE', paidReferenceValue),
  handleSetNumberExistingUsers: ({ commit }, numberExistingUsers) => commit('SET_NUMBER_EXISTING_USERS', numberExistingUsers),
  handleSetadditionalAccessQuantity: ({ commit }, additionalAccessQuantity) => commit('SET_ADDITIONAL_ACCESS_QUANTITY', additionalAccessQuantity),
  handleSetToken: ({ commit }, token) => commit('SET_TOKEN', token),
  handleSetTotalPrice: ({ commit }, totalPrice) => commit('SET_TOTAL_PRICE', totalPrice),
  handleSetPlanId: ({ commit }, planId) => commit('SET_PLAN_ID', planId),
  handleSetInterestCharged: ({ commit }, interestCharged) => commit('SET_INTEREST_CHARGED', interestCharged),
  async handleSubmitCheckout({ state }, payment) {
    const profile = state.billingData
    const body = {
      payment,
      totalPrice: Number((Number(state.totalPrice) + Number(state.interestCharged)).toFixed(2).replaceAll('.','')),
      planId: state.planId,
      additionalAccessQuantity: state.additionalAccessQuantity,
      profile: {
        name: profile.name || '',
        email: profile.email || '',
        phone: profile.phone.replace(/\D+/g, '') || '',
        cpf: profile.document || '',
        cnpj: profile.cnpj || '',
        companyName: profile.companyName || '',
        type: profile.type || '',
        address: profile.address.street || '',
        complement: profile.complement || '',
        number: profile.address.number || '',
        cep: profile.address.cep || '',
        neighborhood: profile.address.neighborhood || '',
        city: profile.address.city || '',
        state: profile.address.state || '',
        stateRegister: profile.stateRegister || '',
        cityRegister: profile.cityRegister || '',
      },
    }

    if (payment.method === 'credit_card') {
      body.payment.token = state.token
    } else {
      body.payment.installments = 1
    }
    const { data } = await api.post('/checkout/buyAccess', body)
    return data
  },
  handleResetBillingData: ({ commit }) => commit('SET_BILLING_DATA', {
    type: 'person',
    companyName: '',
    cnpj: '',
    name: '',
    document: '',
    stateRegister: '',
    cityRegister: '',
    email: '',
    phone: '',
    address: {
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      cep: '',
      city: '',
      state: ''
    }
  }),
  handleSetBillingData: ({ commit }, data) => commit('SET_BILLING_DATA', data),
}

export const getters = {
  paidReferenceValue: (state) => state.paidReferenceValue,
  numberExistingUsers: (state) => state.numberExistingUsers,
  additionalAccessQuantity: (state) => state.additionalAccessQuantity,
  totalPrice: (state) => state.totalPrice,
  interestCharged: (state) => state.interestCharged,
  getBillingData: (state) => state.billingData,
}
