<template>
  <section class='w-100 p-3'>
    <b-alert
      show
      variant='danger'
      :class='{ hidden: !error }'
    >
      {{ error }}
    </b-alert>
    <section class='mb-3' >
      <img width='80' src='../../../../assets/images/logoCadastroPerfil.svg'/>
    </section>
    <section>
      <p>Qual é a <strong>sua área de atuação?</strong></p>

      <section class='mt-3 mb-3'>
        <section v-for='(area, i) in areasOfExpertise' :key='i' class='d-inline-block mr-3 mb-3'>
          <base-button
            :style="userProfile.actingArea.value === area.value ? 'border: 2px solid var(--blue);' : ''"
            class='btn_quantity_person' @click='selectActingArea(area)'>
            {{ area.description }}
          </base-button>
        </section>
      </section>

      <p>Qual é a <strong>sua renda</strong> mensal?</p>

      <section class='mb-3'>
        <section v-for='(salary, i) in salaryRranges' :key='i' class='d-inline-block mr-3 mb-3'>
          <base-button
            :style="userProfile.salaryRange.value === salary.value ? 'border: 2px solid var(--blue);' : ''"
            class='btn_quantity_person' @click='selectSalaryRange(salary)'>
            {{ salary.description }}
          </base-button>
        </section>
      </section>

      <section class='d-flex justify-content-between align-items-center my-4'>
        <b-progress :value='60' height='10px' variant='primary' class='w-25'></b-progress>
        <b-overlay
          :show='loading'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='d-inline-block'
        >
          <base-button
            :disabled='!userProfile.actingArea.value || !userProfile.salaryRange.value'
            @click='next()'
            class='btn mt-1'>
            <span class='text_btn'>Continuar</span>
          </base-button>
        </b-overlay>
      </section>
    </section>
  </section>
</template>

<script>

export default {
  namer: 'AreaOfWorkSalaryRange',
  components: {},
  computed: {
    userProfile() {
      return this.$store.getters['user/userProfile']
    },
    message() {
      return this.$store.getters['access/message']
    },
    error() {
      return this.$store.getters['access/error']
    }
  },
  data() {
    return {
      loading: false,
      areasOfExpertise: [
        {
          value: 1,
          description: 'Trabalho em uma Consultoria de SST'
        },
        {
          value: 2,
          description: 'Trabalho no SESMT de uma empresa'
        },
        {
          value: 3,
          description: 'Sou Consultor autônomo'
        },
        {
          value: 4,
          description: 'Sou sócio de uma Consultoria de SST'
        },
        {
          value: 5,
          description: 'Sou Perito'
        },
        {
          value: 6,
          description: 'Trabalho em uma Clínica de medicina do trabalho'
        },
        {
          value: 7,
          description: 'Não trabalho com SST no momento'
        }
      ],
      salaryRranges: [
        {
          value: 1,
          description: 'Até R$ 1.000,00'
        },
        {
          value: 2,
          description: 'Entre R$ 1.001,00 a R$ 3.000,00'
        },
        {
          value: 3,
          description: 'Entre R$ 3.001,00 a R$ 5.000,00'
        },
        {
          value: 4,
          description: 'Entre R$ 5.001,00 a R$10.0000,00'
        },
        {
          value: 5,
          description: 'Mais de R$10.000,00'
        }
      ]
    }
  },
  validations() {
    return {}
  },
  created() {
    this.$store.dispatch('access/updateError', '')
    this.$store.dispatch('access/updateMessage', '')
  },
  async mounted() {
  },
  methods: {
    selectActingArea(area) {
      if (area.value === this.userProfile.actingArea.value) {
        this.userProfile.actingArea = {
          value: null,
          description: null
        }
        return
      }
      this.userProfile.actingArea = area
    },

    selectSalaryRange(position) {
      if (position.value === this.userProfile.salaryRange.value) {
        this.userProfile.salaryRange = {
          value: null,
          description: null
        }
        return
      }
      this.userProfile.salaryRange = position
    },
    next() {
      this.$emit('next', 5)
    }
  }
}
</script>

<style scoped>
.text_btn {
  font-weight: 600;
}

.btn {
  width: 130px;
  height: 45px;
  border-radius: 5px;
  border: none;
}

.btn:hover {
  color: white;
}

.btn_quantity_person {
  background: transparent;
  border-radius: 20px;
  color: #48626f;
  border: 2px solid #C1D5E4;
}

.btn_quantity_person:hover {
  border-color: var(--blue);
}
</style>
