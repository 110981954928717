import api from '../../utils/api'

export const state = {
  promocode: {
    client: '',
    discount: 10.00
  },
  promotionalCode: {
    description: '',
    discount: 0,
    _id: ''
  },
  discount: 0,
  code: '',
  renews:[],
  promotionalCodes:[],
}

export const mutations = {
  SET_CODE(state, promo) {
    state.code = promo
  },
  SET_PROMO(state, promo) {
    state.discount = promo
  },
  SET_PROMOCODE(state, promocode) {
    state.promocode = promocode
  },
  SET_RENEWS(state, data) {
    state.renews = data
  },
  SET_LISTPROMOCODE(state, promotionalCode) {
    state.promotionalCode = promotionalCode
  },
  SET_LISTPROMOCODES(state, promotionalCodes) {
    state.promotionalCodes = promotionalCodes
  },
}

export const actions = {
  getPromoByCpf({ commit }, cpf) {
    api.get(`plans/promo-by-cpf/${cpf}`).then((res) => {
      commit('SET_PROMO', res.data[0])
    })
  },
  async getPromo({ commit }, user) {
    const { data } = await api.post(`promocode`, user)

    commit('SET_PROMO', data)
    commit('SET_CODE', user.code)

    return data
  },
  async searchRenewalDiscountCoupon({ commit }, username) {
    const payload = {
      username
    }
    const { data } = await api.post(`promocode/searchRenewalDiscountCoupon`, payload)
    return data
  },
  async getRenew({ commit }) {
    const { data } = await api.get(`/admin/promocodes`)
    commit('SET_RENEWS', data)
  },
  handleClearPromocode: ({ commit }) => commit('SET_PROMOCODE', {
    client: ''
  }),
  async handleCreatePromocode({ state }) {
    const body = state.promocode;
    const { data } = await api.post('/admin/promocodes', body);

    return data;
  },
  async removePromocode(_, _id) {
    const { data } = await api.delete(`/admin/promocodes/${_id}`);

    return data;
  },

  async handleCreatePromotionalCodes({ state }) {
    const body = state.promotionalCode;
    const { data } = await api.post('/admin/promocodes/promotionalCodes', body);

    return data;
  },
  async handleUpdatePromotionalCodes({ state }, _id) {
    const body = state.promotionalCode;
    const { data } = await api.put(`/admin/promocodes/promotionalCodes/${_id}`, body);

    return data;
  },
  async handleAddUserPromotionalCodes({ state }, user) {
    const { data } = await api.post('/admin/promocodes/addUserPromotionalCodes', user);

    return data;
  },
  async getAllPromotionalCodes({ commit }) {
    const { data } = await api.get(`/admin/promocodes/getAllPromotionalCodes`)
    commit('SET_LISTPROMOCODES', data)
  },
  async setListPromocode({ commit }, list) {
    commit('SET_LISTPROMOCODE', list)
  },

  async handleGetPromotionalCodes({ commit }, _id) {
    const { data } = await api.get(`/admin/promocodes/getPromotionalCodes/${_id}`);
    commit('SET_LISTPROMOCODE', data)
    return data;
  },
  async handleDeletePromotionalCodes({ commit }, _id) {
    const { data } = await api.delete(`/admin/promocodes/deletePromotionalCodes/${_id}`);
    return data;
  },

  resetListPromocode: ({ commit }) => commit('SET_LISTPROMOCODE', {
    description: '',
    discount: '',
    _id: '',
  }),
}

export const getters = {
  promocode: (state) => state.promocode,
  promotionalCode: (state) => state.promotionalCode,
  code: (state) => state.code,
  discount: (state) => state.discount,
  renews: (state) => state.renews,
  promotionalCodes: (state) => state.promotionalCodes
}
