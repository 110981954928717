import api from '../../utils/api'

export const state = {
  listOfPhrasesControlBanding: [],
  listOfPrecautionaryPhrasesControlBanding: []
}

export const mutations = {
  SET_LISTOFPHRASESCONTROLBANDING(state, listOfPhrasesControlBanding) {
    state.listOfPhrasesControlBanding = listOfPhrasesControlBanding
  },
  SET_LISTOFPRECAUTIONARYPHRASESCONTROLBANDING(state, listOfPrecautionaryPhrasesControlBanding) {
    state.listOfPrecautionaryPhrasesControlBanding = listOfPrecautionaryPhrasesControlBanding
  },
}

export const actions = {
  async loadListOfPhrases({ commit }) {
    const {data} = await api.get(`/phrase-list/`)
    commit('SET_LISTOFPHRASESCONTROLBANDING', data.listOfPhrasesControlBanding)
    commit('SET_LISTOFPRECAUTIONARYPHRASESCONTROLBANDING', data.listOfPrecautionaryPhrasesControlBanding)
  },
}

export const getters = {
  getListOfPhrasesControlBanding: (state) => state.listOfPhrasesControlBanding,
  getListOfPrecautionaryPhrasesControlBanding: (state) => state.listOfPrecautionaryPhrasesControlBanding,
}
