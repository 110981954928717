import api from '../../utils/api'

export const state = {
  plan: {},
  plans: [],
};

export const mutations = {
  SET_PLAN(state, plan) {
    state.plan = plan;
  },
  SET_PLANS(state, plans) {
    state.plans = plans;
  },
  SET_PLANS_CUSTOM(state, plan) {
    state.plans = plan;
  }
}

export const actions = {
  handleSetPlanCuston: ({ commit }, data) =>{
    commit('SET_PLANS_CUSTOM', data)
  },
  resetPlan({ commit }) {
    commit('SET_PLAN', {})
  },
  async handleGetPlans({ commit }) {
    const { data: response } = await api.get('/plans');
    if (response) {
      commit('SET_PLANS', response);
    }
  }
}

export const getters = {
  getPlan: (state) => state.plan,
  getPlans: (state) => state.plans,
}
