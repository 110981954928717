<template>
  <nav id='sidemenu' :class="`${menuCollapsed ? 'menuCollapsed' : ''}`">
    <modal-see-update-new/>

    <user-profile
      class='user_profile'
      v-if='getUserRegisterProfileData'
      :step='2'
    />

    <b-modal ref='show-bloquear' no-close-on-esc no-close-on-backdrop size='lg' scrollable hide-footer hide-header title='Cadastro'>
      <dialog-confirmation
        description="Você está prestes a ativar o período de testes do HO Fácil Web. Ao clicar no botão de confirmar você terá acesso completo à plataforma durante os próximos 7 dias contados a partir da sua confirmação."
        :is-open="openDialog"
        @confirmed="handleUsePlanTrial"
        @close="openDialog = false"
      />

      <b-row class='mx-1 mb-5'>
        <p><b>{{textGuidanceToSignPlan}}</b></p>
      </b-row>

      <b-row class='mx-1 mt-5'>
        <base-button
          v-if="userEligibleUserFreeTrial"
          pulse
          sucess
          @click="openDialog = true"
        >
          Clique aqui para ativar seu teste grátis
        </base-button>

        <base-button
          v-else-if="!signedPlan"
          @click="redirectCheckout()"
          pulse
          sucess
        >
          Clique aqui para assinar um plano
        </base-button>
      </b-row>
    </b-modal>

    <div class='collapseButton' :style="menuCollapsed ? 'left: 68px;' : 'left: 230px;'" @click='collapseMenu'>
      <i :class="menuCollapsed ? 'fas fa-angle-right' : 'fas fa-angle-left'"></i>
    </div>

    <ul class='list-side-menu' >
      <li class='collapseMenuImg'>
        <a v-if='!menuCollapsed' class='icon ml-4' @click='collapseMenu'>
          <img width='163' src='@/assets/images/new-logo.svg' />
        </a>

        <a v-else class='icon' @click='collapseMenu'>
          <img src='@/assets/images/new-logo.svg' />
        </a>
      </li>

      <li
        v-for='(item, i) in itemsFiltered'
        class='text-label-hover-container'
        :key='i'
      >
        <b-popover
          v-if='menuCollapsed'
          custom-class='my-popover-class'
          :target="`${i}_menu`"
          placement="right"
          triggers='hover'
          variant='info'
        >
          <span class='label' v-html='item.label'></span>
        </b-popover>

        <router-link
          :id="`${i}_menu`"
          active-class='link-clicked'
          class='listMenuItem  '
          :to='{ name: `${item.link}` }'
        >
          <div style='width: 32px'>
            <span class='icon'>
              <i :class='`${item.icon}`'></i>
            </span>
          </div>
          <span class='label' v-html='item.label'></span>
        </router-link>
      </li>

      <b-popover
        v-if='menuCollapsed'
        custom-class='my-popover-class'
        target="countOfUsersHaveNotSeenUpdateNews"
        placement="right"
        triggers='hover'
        variant='info'
      >
        <span class='label'>Atualizações</span>
      </b-popover>

      <li
        id="countOfUsersHaveNotSeenUpdateNews"
        class='listMenuItem'
        @click="showModalListUpdateLogs()"
      >
        <div class='position-relative'>
          <span class="icon" >
            <i class="fas fa-bell notification"></i>
          </span>

          <div v-if='countOfUsersHaveNotSeenUpdateNews > 0' class="quantity-notification">
            {{countOfUsersHaveNotSeenUpdateNews}}
          </div>
        </div>
        <span class='label'>Atualizações</span>
      </li>

      <section :style="this.menuCollapsed ? 'width: 83px': 'width: 240px'">
        <li>
          <b-popover
            v-if='menuCollapsed'
            custom-class='my-popover-class'
            target="Tutorials"
            placement="right"
            triggers='hover'
            variant='info'
          >
            <span class='label'>Tutoriais</span>
          </b-popover>

          <router-link
            id='Tutorials'
            active-class='link-clicked'
            class='listMenuItem'
            :to="{ name: `Tutorials` }"
          >
            <div style='width: 32px'>
              <span class='icon'>
                <i class='fas fa-info-circle'></i>
              </span>
            </div>
            <span class='label'>Tutoriais</span>
          </router-link>
        </li>

        <b-popover
          v-if='menuCollapsed'
          custom-class='my-popover-class'
          target="feedback"
          placement="right"
          triggers='hover'
          variant='info'
        >
          <span class='label'>Envie seu feedback</span>
        </b-popover>

        <li
          id="feedback"
          :style="menuCollapsed ? 'text-align: center !important' : 'text-align: start !important; '"
          @click='feedbackModal = true'
        >
          <a active-class='link-clicked' class='listMenuItem'>
            <span class='icon'><i class='fas fa-question-circle'></i></span>
            <span class='label'>Envie seu feedback</span>
          </a>
        </li>

        <li
          id='minhaConta'
          class='text-label-hover-container'
        >
          <b-popover
            v-if='menuCollapsed'
            custom-class='my-popover-class'
            target="minhaConta"
            placement="right"
            triggers='hover'
            variant='info'
          >
            <span class='label'>Minha conta</span>
          </b-popover>

          <router-link
            active-class='link-clicked'
            class='listMenuItem'
            :to="{ name: 'MyAccount' }"
          >
            <div style='width: 32px'>
                <span class='icon'>
                  <i class='fas fa-user-circle'></i>
                </span>
            </div>
            <span class='label'>Minha conta</span>
          </router-link>
        </li>

        <section class='separator mt-3'>
          <div class='line'></div>
        </section>

        <b-popover
          v-if='menuCollapsed'
          custom-class='my-popover-class'
          target="sair"
          placement="right"
          triggers='hover'
          variant='info'
        >
          <span class='label'>Sair</span>
        </b-popover>

        <li id='sair'>
          <a  class='listMenuItem' @click='signout'>
            <span class='icon'><i class='fas fa-sign-out-alt'></i></span>
            <span class='label'>Sair</span>
          </a>
        </li>
      </section>
    </ul>

    <Feedback
      v-if='feedbackModal'
      :isOpen='feedbackModal'
      @close='feedbackModal = false'
    />
  </nav>
</template>

<script>
import Feedback from '@/layouts/components/Feedback'
import { mapActions, mapGetters } from 'vuex'
import ModalSeeUpdateNew from '@/components/UpdateNew/ModalSeeUpdateNew'
import { profileIsFilled } from '../../utils/checkProfileIsFilled'
import DialogConfirmation from '@/components/DialogConfirmation.vue'
import UserProfile from '@/views/Access/UserProfile/UserProfile'

export default {
  components: {
    Feedback,
    ModalSeeUpdateNew,
    DialogConfirmation,
    UserProfile
  },
  data() {
    return {
      textGuidanceToSignPlan: '',
      menuCollapsed: true,
      pagetitle: this.$route.meta.pagetitle,
      pagetitleicon: this.$route.meta.pagetitleicon,
      textMenu: '',
      items: [
        {
          link: 'LegalSearch',
          icon: 'fas fa-clipboard-list',
          label: 'Consulta Legal',
          role: '',
          active: true
        },
        {
          link: 'RelatoriosConsultaLegal',
          icon: 'fas fa-download',
          label: 'Relatórios',
          role: '',
          active: false
        },
        {
          link: 'ChemicalInventory',
          icon: 'far fa-folder-open',
          label: 'Inventário Químico',
          role: '',
          active: false
        },
        {
          link: 'ManageCompanies',
          icon: 'far fa-building',
          label: 'Gerenciar empresas',
          role: '',
          active: false
        },
        {
          link: 'Users',
          icon: 'fas fa-users',
          label: 'Usuários',
          role: 'admin',
          active: false
        },
        {
          link: 'ClassificationRequests',
          icon: 'fas fa-list',
          label: 'Pedidos de Classificação',
          role: 'admin',
          active: false
        },
        {
          link: 'Surveys',
          icon: 'fas fa-poll',
          label: 'Pesquisas',
          role: 'admin',
          active: false
        },
        {
          link: 'Promocodes',
          icon: 'fas fa-receipt',
          label: 'Promoções de renovação',
          role: 'admin',
          active: false
        },
        {
          link: 'LinkCheckoutsDifferentPrices',
          icon: 'fas fa-link',
          label: 'Link de checkout',
          role: 'admin',
          active: false
        },
        {
          link: 'TutorialsAdmin',
          icon: 'fas fa-play',
          label: 'Tutoriais',
          role: 'admin',
          active: false
        },
        {
          link: 'RelatorioInventarioQuimico',
          icon: 'fas fa-file',
          label: 'Relatório de <br/> Inventário Químico',
          role: 'admin',
          active: false
        },
        {
          link: 'UpdateLogs',
          icon: 'fas fa-newspaper',
          label: 'Atualizações e novidades',
          role: 'admin',
          active: false
        },
        {
          link: 'ChaveApi',
          icon: 'fas fa-key',
          label: 'Api pesquisar agente',
          role: 'admin',
          active: false
        },
        {
          link: 'PlatformContracts',
          icon: 'fas fa-handshake',
          label: 'Contratos da plataforma',
          role: 'Admin',
          active: false
        },
      ],
      linksMenuMyAccount: [
        {
          link: 'Profile',
          icon: 'fas fa-user-circle',
          label: 'Perfil',
          role: '',
          active: true
        },
        {
          link: 'ChangePlan',
          icon: 'fas fa-user',
          label: 'Meu plano',
          role: '',
          active: true
        },
        {
          link: 'RedefinePwd',
          icon: 'fas fa-lock',
          label: 'Redefinir senha',
          role: '',
          active: true
        },
        {
          link: 'Colaboradores',
          icon: 'fas fa-user-plus',
          label: 'Colaboradores',
          role: '',
          active: true
        },
        {
          link: 'Indicacao',
          icon: 'fas fa-link',
          label: 'Indicação',
          role: '',
          active: true
        },
        {
          link: 'Policy',
          icon: 'fas fa-file',
          label: 'Política de privacidade e <br />termos de uso</span>',
          role: '',
          active: true
        },
        {
          link: 'Preferencias',
          icon: 'fas fa-user',
          label: 'Preferências do usuário',
          role: '',
          active: true
        },
      ],
      dropdownMenu: false,
      feedbackModal: false,
      openDialogModalSeeUpdateLogs: false,
      showDialogModalSeeUpdateLogs: false,
      openDialog: false,
    }
  },
  async mounted(){
    await this.handleUserEligibleUserFreeTrial()
    await this.checkIfTheUserHasSignedPlan()
    await this.bloquearTela()
    await this.handleCountOfUsersHaveNotSeenUpdateNews()
    const sessionId = localStorage.getItem("sessionId");
    const user = (await this.$api.get(`users/${sessionId}`)).data;
    await this.$store.dispatch('user/updateUser', user);
  },
  computed: {
    ...mapGetters('updateNews', {
      countOfUsersHaveNotSeenUpdateNews: 'getCountOfUsersHaveNotSeenUpdateNews'
    }),
    ...mapGetters('plan', {
      signedPlan: 'getSignedPlan',
      freeTrialSubscription: 'getFreeTrialSubscription',
    }),
    ...mapGetters('surveys', {
      getUserRegisterProfileData: 'getUserRegisterProfileData',
    }),
    routes() {
      return this.$router.options.routes
    },
    user() {
      return this.$store.getters['user/current']
    },
    userEligibleUserFreeTrial() {
      return this.$store.getters['user/userEligibleUserFreeTrial']
    },
    updatePageName() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return (this.pagetitle = this.$route.meta.pagetitle)
    },
    updatePageTitleIcon() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return (this.pagetitleicon = this.$route.meta.pagetitleicon)
    },
    itemsFiltered() {
      return this.items.filter((item) => {
        if (!(!!(this.user.functionalities.find((x) => x.namePath === item.link)))) {
           return false;
        }
        if (item.role === '') {
          return true
        }
        if (this.user.role == 'admin') {
          return true
        }
      })
    }
  },
  methods: {
    ...mapActions('updateNews', {
      handleCountOfUsersHaveNotSeenUpdateNews: 'handleCountOfUsersHaveNotSeenUpdateNews',
      handleProcessUserWhoSawTheUpdates: 'handleProcessUserWhoSawTheUpdates',
    }),
    ...mapActions('promocodes', {
      searchRenewalDiscountCoupon: 'searchRenewalDiscountCoupon',
    }),
    ...mapActions('plan', {
      checkIfTheUserHasSignedPlan: 'checkIfTheUserHasSignedPlan',
      checkIfFreeTrialSubscription: 'checkIfFreeTrialSubscription',
    }),
    ...mapActions('surveys', {
      userRegisterProfileData: 'userRegisterProfileData',
    }),
    ...mapActions('user', {
      handleUserEligibleUserFreeTrial: 'handleUserEligibleUserFreeTrial',
    }),
    ...mapActions('auth', {
      handleSaveLocalStorageSessionId: 'handleSaveLocalStorageSessionId'
    }),
    ...mapActions('access', {
      updateMenuCollapsed: 'updateMenuCollapsed',
    }),
    linksMenuMyAccountFiltered(link) {
      if (link !== 'Colaboradores'){
        return true
      }
       return this.user.plan && (this.user.plan.status === 'paid' || this.user.endDateExtraAccessTime > 0) && (this.user.plan.plan === 'AN1HO' || this.user.plan.plan === 'PROMO')
    },


    async redirectCheckout(){
      const code = await this.searchRenewalDiscountCoupon(this.user.username)
      const router = code
        ? { name: 'Checkout',
            query: { plano: 'AN1HO', renew: code, utm_source: 'app', utm_medium: 'renov', utm_campaign: 'vazio', utm_term: 'renov', utm_content: 'vazio' }
          }
        : { name: 'ChangePlan'}
      await this.$router.push(router)
    },

    bloquearTela(){
      if(
        this.$router.currentRoute.path === "/planos" ||
        this.$router.currentRoute.path === "/perfil" ||
        this.user.role === 'admin' ||
        !this.checkProfileIsFilled(this.user)
      ){
        this.hide()
        return
      }

      if(this.userEligibleUserFreeTrial){
        this.show();
        this.textGuidanceToSignPlan = 'Para acessar as funcionalidades do HO Fácil Web, por gentileza, ative seu teste grátis de 7 dias clicando no botão ativar abaixo.'
      } else if(!this.signedPlan){
        this.show();
        this.textGuidanceToSignPlan = `Seu acesso completo a plataforma HO Fácil Web expirou. Para continuar trabalhando com alta produtividade e com segurança técnica e jurídica em seus documentos, por gentileza, clique no botão abaixo para aderir a um plano de assinatura.`
      } else {
        this.hide()
      }
    },

    checkProfileIsFilled(user) {
      return profileIsFilled(user)
    },

    hide() {
      this.$refs['show-bloquear'].hide()
    },

    show() {
      this.$refs['show-bloquear'].show()
    },

    async handleUsePlanTrial(){
      const response = (await this.$api.post('checkout/trial')).data;
      let message = '';
      let variant = '';

      if(response.message){
        message = response.message;
        variant = 'success';
      } if(response.errors){
        message = response.errors;
        variant = 'danger';
      }

      this.openDialog = false;
      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
      })
      await this.checkIfTheUserHasSignedPlan()
      await this.checkIfFreeTrialSubscription()
      const sessionId = localStorage.getItem("sessionId");;
      const user = (await this.$api.get(`users/${sessionId}`)).data;
      await this.$store.dispatch('user/updateUser', user);
      await this.handleUserEligibleUserFreeTrial()
      await this.bloquearTela()
    },

    async showModalListUpdateLogs(){
      try {
        this.$bvModal.show('modalnewUpdate')
        const response = await this.handleProcessUserWhoSawTheUpdates()
        if (response.status === 200) {
          await this.handleCountOfUsersHaveNotSeenUpdateNews();
        }
      }catch (error) {
      }
    },

    collapseMenu() {
      this.menuCollapsed = !this.menuCollapsed
      this.dropdownMenu = false
      this.$emit('collapse')
      this.updateMenuCollapsed(this.menuCollapsed)
    },

    goToRouter(router) {
      this.$router.push({ name: router })
    },

    changeColorClick(link) {
      this.itemsFiltered = this.itemsFiltered.map((item) => {
        if (item.link === link) {
          item.active = true
        } else {
          item.active = false
        }
        return item
      })
    },
    closeMenu() {
      if (this.dropdownMenu == true) {
        this.dropdownMenu = false
      }
    },

    changePlan() {
      this.$router.push({ name: 'ChangePlan' })
    },

    profile() {
      this.$router.push({ name: 'Profile' })
    },

    policy() {
      this.$router.push({ name: 'Policy' })
    },

    redefinePwd() {
      this.$router.push({ name: 'RedefinePwd' })
    },

    signout() {
      this.$api
        .get('auth/signout')
        .then(async () => {
          await this.$router.push({ name: 'Signin' })
          await this.handleSaveLocalStorageSessionId(null)
          await this.$store.dispatch('user/updateUser', null);
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  watch: {
    $route: {
      deep: true,
      async handler(to, from) {
        await this.handleUserEligibleUserFreeTrial()
        this.bloquearTela()
        const sessionId = localStorage.getItem("sessionId");
        const user = (await this.$api.get(`users/${sessionId}`)).data;
        await this.$store.dispatch('user/updateUser', user);
      },
    },
  },
}
</script>

<style>
.my-popover-class .arrow {
display: none!important;
}
.my-popover-class {
  margin-left: 35px !important;
}
</style>

<style lang='scss' scoped>
.collapseMenuImg {
  margin-bottom: 20px;
  max-width: 160px!important;
}

.collapseButton {
  cursor: pointer;
  border-radius: 50%;
  background: #022245!important;
  padding: 5px 13px;
  position: absolute!important;
  top: 60px;
  transition: all 0.5s ease;
  color: #FFFFFF !important;
  z-index: 1;
}

#sidemenu {
  background: var(--white);
  position: fixed;
  z-index: 100;
  box-shadow: 0 0 3px 1px #cccccc;
  color: var(--white);
  width: 250px;
  height: 100%;
  transition: all 0.5s ease;
  box-sizing: content-box;
}

#sidemenu ul {
  list-style: none;
  height: 100%;
  padding-bottom: 50px;
}

#sidemenu li {
  text-align: center;
  cursor: pointer;
}

#sidemenu li a {
  width: 100%;
}

#sidemenu li span {
  color: #2ab5f5;
}

#sidemenu li a.router-link-exact-active.router-link-active {
  background-color: #deeeef;
}

#sidemenu.menuCollapsed ul > li:first-child {
  padding: 6px;
}

#sidemenu ul > li:first-child {
  padding: 12px 0;
}

#sidemenu ul > li:not(:first-child) {
  display: flex;
}

#sidemenu ul > li:not(:first-child):hover {
  background-color: #deeeef;
}

#sidemenu img {
  cursor: pointer;
  width: 100%;
  max-width: 10vw;
  margin: 0 auto;
  height: auto;
}

#sidemenu.menuCollapsed span.label {
  transition: all 0.5s ease;
  visibility: hidden;
  opacity: 0;
}

#sidemenu li span.icon {
  font-size: 25px;
  color: #022245 !important;
}

#sidemenu.menuCollapsed {
  transition: width 0.5s ease;
  color: #000 !important;
  display: flex;
  align-items: center;
  width: 83px;
}

#sidemenu span.label {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 17px;
  color: #022245 !important;
}

.list-side-menu::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.list-side-menu::-webkit-scrollbar-track {
  background: #ffffff;
}

.list-side-menu::-webkit-scrollbar-thumb {
  background: #022245; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #ffffff; /* creates padding around scroll thumb */
  -webkit-box-shadow: inset 0 0 6px #022245;
}

.list-side-menu {
  z-index: 0;
  scrollbar-color: #022245 white;
  scrollbar-width: thin 12px;
  margin: 0 !important;
  overflow-y : scroll;
  overflow-x : hidden;
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu {
  position: absolute;
  top: 66%;
  left: 20px;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0 10px;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #04a7f39c;
  border-radius: 0.25rem;
}

.line {
  width: 70%;
  height: 1px;
  background-color: #e0e0e0;
}

.link-clicked {
  background-color: rgba(4, 168, 243, 0.4) !important;
  border-radius: 2px;
  color: #fff !important;
  width: 100%;
}

.link-clicked span.icon {
  color: #022245 !important;
}

.link-clicked span.label {
  color: #022245 !important;
}

.listMenuItem {
  padding: 5px 20px !important;
  display: flex;
  align-items: center;
  gap: 13px;
  text-decoration: none;
}

.icon-link-sub-menu{
  color: #022245 !important;
}

.subMenu {
  position: fixed;
  bottom: 0;
  transition: width 0.5s ease;
}

.subMenuItens {
  transition: width 0.5s ease;
  margin-left: 45px;
  margin-top: -25px;
  margin-bottom: -55px;
}

.subMenuItens .label {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.container-notification{
  cursor: pointer;
  position: relative;
  padding: 10px;
  margin-left: -170px;
}

.quantity-notification{
  background: #FF0000;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  margin: -40px 0 0px 12px;
  text-align: center;
  align-self: center;
  color: #FFFFFF;
  font-weight: bold;
  border: 2px solid #FFFFFF;
  font-size: 10px;
}

.notification{
  font-size: 33px
}

.text-label-hover{
  display: none;
}
.text-label-hover-container:hover > section > .text-label-hover{
  display: block;
  z-index: 999;
  background: #DBECF4;
  height: 47.5px;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  position: absolute!important;
  white-space: nowrap;
  margin-left: 88px!important;
}
</style>
