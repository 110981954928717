<template>
  <main class="landpage">
    <div id="logo-image" class="landing-1">
      <div style="height: 100%; margin: auto; width: 60%">
        <h1
          :style="
            $route.name == 'ForgotPassword'
              ? 'font-size: 40px; font-weight: 800 !important; color: #cbd63e'
              : 'font-size: 46px; font-weight: 800 !important; color: #cbd63e'
          "
        >
          {{ title }}
        </h1>
        <p style="font-size: 22px; font-weight: 600">
          {{ subtitle }}
        </p>
        <a
          v-if="isRegister"
          style="
            font-size: 19px;
            font-weight: 600;
            width: 370px;
            text-decoration: underline;
            color: #04a8f3;
            cursor: pointer;
          "
          @click="login"
          >{{ textLink }}</a
        >
      </div>
    </div>
    <div class="landing-2">
      <slot />
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      title: null,
      isRegister: false,
      subtitle: '',
      textLink: null,
    }
  },
  created() {
    this.changeTexts(this.$route.name)
  },
  watch: {
    $route() {
      this.changeTexts(this.$route.name)
    },
  },
  methods: {
    login() {
      this.$store.dispatch('access/updateError', '')
      this.$router.push({ path: '/login' })
    },
    changeTexts(name) {
      switch (name) {
        case 'Signup':
          this.title = 'Crie sua conta'
          this.isRegister = true
          this.textLink = 'Já é cadastrado? Login'
          this.subtitle = 'Entre com usuário e senha para ter acesso ao sistema.'
          /*this.title = ''
          this.isRegister = false
          this.textLink = ''
          this.subtitle = ''*/
          break
        case 'ForgotPassword':
          this.title = 'Esqueceu sua senha?'
          this.subtitle = 'Não se preocupe, vamos te ajudar a recuperar.'
          this.isRegister = false

          break
        default:
          this.title = 'Bem vindo'
          this.subtitle =
            'Entre com usuário e senha para ter acesso ao sistema.'
          this.isRegister = false
          break
      }
    },
  },
}
</script>

<style scoped>
main.landpage {
  align-items: center;
  flex-direction: row;
  background-color: #022245;
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  min-height: 100%;
  height: fit-content;
  justify-content: center;
  width: 100%;
}
main div.landing-1 {
  /* flex-grow: 1; */
  width: 50%;
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: flex-start;
  height: 250px;
}
main div.landing-2 {
  /* flex-grow: 1; */
  padding: 30px 0px;
  width: 50%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
@media only screen and (max-width: 800px) {
  main.landpage {
    flex-direction: column;
  }
  main div.landing-1 {
    display: none;
  }
  main div.landing-2 {
    width: 100%;
    justify-content: center;
  }
}
</style>
