import api from '../../utils/api'

export const state = {
}

export const mutations = {
}

export const actions = {
  async addTasksQueue({ commit }, payload) {
    return await api.post('/task-queue', payload);
  },
}

export const getters = {
}
