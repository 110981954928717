import Profile from '../../models/Payment/Profile'
import CreditCard from '../../models/Payment/CreditCard'
import api from '../../utils/api'

export const state = {
  creditCard: new CreditCard(),
  error: undefined,
  nextStep: false,
  paymentMethod: 'bank_slip',
  profile: new Profile(),
  month: undefined
}

export const mutations = {
  CLEAR_PROFILE(state) {
    state.profile = new Profile()
  },
  SET_ERROR(state, data) {
    state.error = data
  },
  SET_NEXT_STEP(state, data) {
    state.nextStep = data
  },
  SET_PAYMENT_METHOD(state, data) {
    state.paymentMethod = data
  },
  SET_PROFILE(state, data) {
    state.profile.set(data)
  },
  SET_ACCESS_DATA(state, data) {
    state.profile.setAcess(data)
  },
  SET_MONTH(state, data) {
    state.month = data
  }
}

export const actions = {
  async complete({ commit, state }, data) {
    try {
      if (data.payment.method === 'credit_card') {
        Iugu.setAccountID(process.env.VUE_APP_IUGU_ACCOUNT_ID)
        Iugu.setTestMode(false)
        Iugu.setup()
        let month = state.creditCard.expirationDate.substr(
          0,
          state.creditCard.expirationDate.indexOf('/')
        )
        let year = state.creditCard.expirationDate.substr(
          state.creditCard.expirationDate.length - 2,
          state.creditCard.expirationDate.length
        )
        let cc = Iugu.CreditCard(
          state.creditCard.number,
          month,
          year,
          state.profile.name,
          state.profile.name,
          state.creditCard.verificationValue
        )
        let token = await new Promise((resolve, reject) => {
          Iugu.createPaymentToken(cc, function(response) {
            if (response.errors) {
              reject(new Error('cartão rejeitado, confira os dados'))
            } else {
              resolve(response.id)
            }
          })
        })
        data.payment.creditCard = token
      } else {
        data.payment.creditCard = undefined
      }

      data.profile = state.profile

      data.month = state.month

      commit('SET_ERROR', undefined)
      return (await api.post('/checkout', data)).data
    } catch (err) {
      if (err.response && err.response.data) {
        commit('SET_ERROR', err.response.data)
      } else {
        commit('SET_ERROR', err)
      }
      console.log(err)
      return err
    }
  },
  async checkAccount({ commit }, data) {
    try {
      return (await api.post('/check', data)).data
    } catch (err) {
      console.log(err)
      if (err.response && err.response.data) {
        commit('SET_ERROR', err.response.data)
      } else {
        commit('SET_ERROR', err)
      }
      console.log(err)
      return err
    }
  },
  async checkCpf({ commit }, data) {
    try {
      return (await api.post('/checkCpf', { cpf: data })).data
    } catch (err) {
      console.log(err)
      if (err.response && err.response.data) {
        commit('SET_ERROR', err.response.data)
      } else {
        commit('SET_ERROR', err)
      }
      return err
    }
  },
  async checkCpfIsDifferentEmail({ commit }, data) {
    try {
      return (await api.post('/checkCpfIsDifferentEmail', data)).data
    } catch (err) {
      if (err.response && err.response.data) {
        commit('SET_ERROR', err.response.data)
      } else {
        commit('SET_ERROR', err)
      }
      return err
    }
  },
  async checkCnpfIsDifferentUser({ commit }, data) {
    try {
      return (await api.post('/checkCnpfIsDifferentUser', data)).data
    } catch (err) {
      if (err.response && err.response.data) {
        commit('SET_ERROR', err.response.data)
      } else {
        commit('SET_ERROR', err)
      }
      return err
    }
  },
  async checkEmail({ commit }, data) {
    try {
      return (await api.post('/checkEmail', { email: data })).data
    } catch (err) {
      console.log(err)
      if (err.response && err.response.data) {
        commit('SET_ERROR', err.response.data)
      } else {
        commit('SET_ERROR', err)
      }
      console.log(err)
      return err
    }
  },
  async updatePaymentMethod({ commit }, data) {
    commit('SET_PAYMENT_METHOD', data)
  },
  async clearProfile({ commit }) {
    commit('CLEAR_PROFILE')
  },
  async updateProfile({ commit }, data) {
    commit('SET_PROFILE', data)
  },
  async setAccessData({ commit }, data) {
    commit('SET_ACCESS_DATA', data)
  },
  async enableNextStep({ commit }) {
    commit('SET_NEXT_STEP', true)
  },
  async disableNextStep({ commit }) {
    commit('SET_NEXT_STEP', false)
  },
  updateError({ commit }, error) {
    commit('SET_ERROR', error)
  },
  setMonth({ commit }, data) {
    commit('SET_MONTH', data)
  }
}

export const getters = {
  creditCard: (state) => state.creditCard,
  error: (state) => state.error,
  paymentMethod: (state) => state.paymentMethod,
  profile: (state) => state.profile,
  nextStep: (state) => state.nextStep,
  month: (state) => state.month
}
