<template>
  <div class="base-feelings">
    <div 
      v-for="emoji in emojis"
      :key="`value_${emoji.icon}`"
      :class="{ selected: emoji.value == value }"
      class="emoji" 
      @click="$emit('input', emoji.value)"
      v-html="emoji.icon"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseFeelings',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Number,
      default: undefined
    },
  },
  data(){
    return {
      emojis: [
        {
          icon: '&#128555;',
          value: 0
        },
        {
          icon: '&#128543;',
          value: 2.5
        },
        {
          icon: '&#128528;',
          value: 5
        },
        // {
        //   icon: '&#128522;',
        //   value: 7.5
        // },
        {
          icon: '&#128515;',
          value: 10
        }
      ].reverse()
    }
  }
}
</script>

<style lang="scss" scoped>
.base-feelings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  .emoji {
    width: 24px;
    height: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
    filter: grayscale(1);

    font-size: 20px;

    cursor: pointer;
    
    border: 2px solid transparent;
    border-radius: 24px;

    transition: all 0.1s ease-in-out;

    &.selected  {
    filter: grayscale(0 );
      border: 2px solid var(--blue);
      font-size: 45px;
    }
  }
}
</style>
