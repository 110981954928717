<template>
  <base-modal class="dialog-confirmation" :isOpened="isOpen" @close="$emit('close')">
    <template v-slot:header>
      <template>{{title}}</template>
    </template>
    <template v-slot:content>
      <div class="body">
        <span>{{description}}</span>
      </div>
    </template>
    <template v-slot:footer>
      <div class="w-50 px-1 py-1">
        <base-button
          @click="$emit('close')"
          danger
          block
        >
          {{ cancel }}
        </base-button>
      </div>
      <b-overlay
        :show='loading'
        rounded
        opacity='0.6'
        spinner-small
        spinner-variant='primary'
        class='d-inline-block w-50'
      >
        <div class="w-100 px-1 py-1">
          <base-button
            @click="$emit('confirmed')"
            sucess
            block
          >
            {{ ok }}
          </base-button>
        </div>
      </b-overlay>
    </template>
  </base-modal>
</template>

<script>

export default {
  name: 'DialogConfirm',
  props: {
    loading: { type: Boolean, default: false },
    isOpen: { type: Boolean, default: false },
    cancel: { type: String, default: 'Não' },
    ok: { type: String, default: 'Sim' },
    title: { type: String, default: "CONFIRMAR AÇÃO" },
    description: { type: String, default: "Tem certeza que deseja executar está ação?" },
  },
}
</script>
<style lang="scss" scoped>
.dialog-confirmation {
  /deep/ main {
    margin: 16px;
  }
  /deep/ footer {
    width: 100%;
    display: flex;
  }
}
</style>
