<template>
  <b-modal
    :title='title'
    :ref='refModal'
    :id='idModal'
    :header-bg-variant='variant'
    header-text-variant='light'
    size='lg'
  >
    <section class='modal-font-family'>
      <section class='body'>
        <p v-html='description'></p>

        <p v-html='descriptionAlert'></p>
      </section>
    </section>

    <template #modal-footer='{ ok, cancel, hide }'>
      <section class='d-flex w-100 modal-font-family'>
        <b-button
          variant='danger'
          class='w-50 mr-2'
          @click="cancel(), $emit('close')"
        >
          {{ canceltext }}
        </b-button>

        <b-overlay
          :show='loading'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='d-inline-block w-50'
        >
          <b-button
            @click="$emit('confirmed')"
            variant='success'
            class='w-100'
          >
            {{ okText }}
          </b-button>
        </b-overlay>
      </section>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'ModalConfirmAction',
  props: {
    refModal: { type: String, default: 'modalConfirmAction' },
    idModal: { type: String, default: 'modalConfirmAction' },
    loading: { type: Boolean, default: false },
    variant: { type: String, default: 'info' },
    canceltext: { type: String, default: 'Não' },
    okText: { type: String, default: 'Sim' },
    title: { type: String, default: 'Confimar ação' },
    description: { type: String, default: 'Tem certeza que deseja executar está ação?' },
    descriptionAlert: { type: String, default: '' }
  }
}
</script>
<style lang='scss' scoped>
</style>
