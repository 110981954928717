export default class Profile {
  constructor(name, email, userCpf) {
    this.name = name ? name : ''
    this.email = email ? email : ''
    this.userCpf = userCpf ? userCpf : ''
  }
  set(data) {
    this.name = data.profile.name
    this.email = data.username
    this.userCpf = data.profile.cpf
    this.invoiceName = data.profile.name
    this.invoiceCpf = data.profile.cpf
    this.address = data.profile.address
    this.complement = data.profile.complement
    this.number = data.profile.number
    this.cep = data.profile.cep
    this.neighborhood = data.profile.neighborhood
    this.city = data.profile.city
    this.state = data.profile.state
  }
  setAcess(data) {
    this.name = data.profile.name
    this.email = data.profile.email
    this.userCpf = data.profile.cpf
  }
  validateAccess() {
    return this.name && this.email && this.userCpf
  }
  validateInvoiceData() {
    return (
      this.name &&
      this.address &&
      this.number &&
      this.cep &&
      this.neighborhood &&
      this.city &&
      this.state
    )
  }
  validate() {
    console.log(
      this.name,
      this.invoiceName,
      this.email,
      this.userCpf,
      this.invoiceCpf,
      this.address,
      this.number,
      this.cep,
      this.neighborhood,
      this.city,
      this.state
    )
    return (
      !this.name ||
      !this.invoiceName ||
      !this.email ||
      !this.userCpf ||
      !this.invoiceCpf ||
      !this.address ||
      !this.number ||
      !this.cep ||
      !this.neighborhood ||
      !this.city ||
      !this.state
    )
  }
  // validateNew() {
  //   return (
  //     !this.name ||
  //     !this.email ||
  //     !this.cpf ||
  //     !this.password ||
  //     !this.address ||
  //     !this.number ||
  //     !this.cep ||
  //     !this.neighborhood ||
  //     !this.city ||
  //     !this.state
  //   );
  // }
}
