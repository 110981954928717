import api from '../../utils/api'

export const state = {
  breadcrumb: {},
}

export const mutations = {
  SET_BREADCRUMB(state, breadcrumb) {
    state.breadcrumb = breadcrumb
  },
}

export const actions = {
  setBreadcrumb({ commit }, breadcrumb) {
    commit('SET_BREADCRUMB', breadcrumb)
  },
}

export const getters = {
  getBreadcrumb: (state) => state.breadcrumb,
}
