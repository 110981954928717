import api from '../../utils/api'

export const state = {
  objectivesAnalysis: {
    _id: null,
    changedAgentsLegalConsultation: false,
    journeyMinutes: null,
    objectiveAnalysis: [],
    quantificationCriterion: null,
    laboratory: null,
    considerSafetyMargin: false,
  },
  laboratories: []
}

export const mutations = {
  SET_OBJECTIVES_ANALYSIS(state, objectivesAnalysis) {
    state.objectivesAnalysis = objectivesAnalysis
  },
  SET_CHANGED_AGENTS_LEGAL_CONSULTATION(state, changedAgentsLegalConsultation) {
    state.objectivesAnalysis.changedAgentsLegalConsultation = changedAgentsLegalConsultation
  },
  SET_LABORATORIES(state, laboratories) {
    state.laboratories = laboratories
  }
}

export const actions = {
  setchangedAgentsLegalConsultation: ({ commit }, changedAgentsLegalConsultation) => commit('SET_CHANGED_AGENTS_LEGAL_CONSULTATION', changedAgentsLegalConsultation),

  async handleCreateObjectivesAnalysis({ state }) {
    try {
      const objectivesAnalysis = state.objectivesAnalysis
      return await api.post(`/objectivesAnalysis`, objectivesAnalysis)
    } catch (e) {

    }
  },

  async handleUpdateObjectivesAnalysis({ state }) {
    try {
      const objectivesAnalysis = state.objectivesAnalysis
      const id = state.objectivesAnalysis._id
      delete state.objectivesAnalysis._id
      return await api.put(`/objectivesAnalysis/${id}`, objectivesAnalysis)
    } catch (e) {

    }
  },

  async handleUpdateChangedAgentsLegalConsultation({ state }, changedAgentsLegalConsultation) {
    try {
      if(state.objectivesAnalysis && state.objectivesAnalysis._id) {
        const id = state.objectivesAnalysis._id
        return await api.put(`/objectivesAnalysis/changedAgentsLegalConsultation/${id}`, { changedAgentsLegalConsultation })
      }
    } catch (e) {

    }
  },

  async handleUpdateLaboratoryObjectivesAnalysis({ state }, laboratoryId) {
    try {
      if(state.objectivesAnalysis && state.objectivesAnalysis._id) {
        const id = state.objectivesAnalysis._id
        return await api.put(`/objectivesAnalysis/laboratory/${id}`, { laboratoryId })
      }
    } catch (e) {

    }
  },


  async handleUpdateIAgreeObjectivesAnalysis({ state }, objectivesAnalysis) {
    try {
      return await api.put(`/objectivesAnalysis/iAgree/${objectivesAnalysis._id}`, objectivesAnalysis)
    } catch (e) {

    }
  },

  async handleLaboratories({ commit }) {
    try {
      const { data } = await api.get(`/objectivesAnalysis/laboratories`)
      commit('SET_LABORATORIES', data.laboratories)
    } catch (e) {

    }
  },

  async handleGetObjectivesAnalysis({ commit }) {
    try {
      const { data } = await api.get(`/objectivesAnalysis`)
      commit('SET_OBJECTIVES_ANALYSIS', data.objectivesAnalysis)
    } catch (e) {

    }
  },

  async handleSendLaboratoryInvitation(_, laboratory) {
    try {
      return await api.post(`/objectivesAnalysis/laboratoryInvitation`, laboratory)
    } catch (e) {
      console.log(e)
    }
  }
}

export const getters = {
  getObjectivesAnalysis: (state) => state.objectivesAnalysis,
  getLaboratories: (state) => state.laboratories,
}
