import api from '../../utils/api'

export const state = {
  exposicaoPermanente: {
    frequenciaTrabalhadorRealizarAtividadefrequqnciaTrabalhadorRealizarAtividade: null,
    trabalhadorRealizeAtividadeSemPresencaSemNecessidadeEmpregoagentesQuimicos: null,
    trabalhadorexecutaAtividadeAgentesQuimicosAnalisados: null
  },
  exposicaoPermanenteProduto: {
    frequenciaTrabalhadorRealizarAtividadefrequqnciaTrabalhadorRealizarAtividade: null,
    trabalhadorRealizeAtividadeSemPresencaSemNecessidadeEmpregoagentesQuimicos: null,
    trabalhadorexecutaAtividadeAgentesQuimicosAnalisados: null
  },
}

export const mutations = {
  SET_EXPOSICAO_PERMANENTE(state, exposicaoPermanente) {
    state.exposicaoPermanente = exposicaoPermanente
  },
  SET_EXPOSICAO_PERMANENTE_PRODUTO(state, exposicaoPermanenteProduto) {
    state.exposicaoPermanenteProduto = exposicaoPermanenteProduto
  },
}

export const actions = {
  async handleResetPermanentExhibition({ commit }, exposicaoPermanente) {
    commit('SET_EXPOSICAO_PERMANENTE', exposicaoPermanente)
  },

  async handleCreatePermanentExhibition(_, exposicaoPermanente) {
    try {
      await api.post('/permanent-exhibition', exposicaoPermanente)
    } catch (err) {
    }
  },

  async handleUpdatePermanentExhibition(_, exposicaoPermanente) {
    try {
      const { data } = await api.put(`/permanent-exhibition/${exposicaoPermanente._id}`, exposicaoPermanente)
      return data
    } catch (err) {
    }
  },

  async handleGetPermanentExhibition({ commit }, user) {
    try {

      const { data } = await api.get(`/permanent-exhibition/${user}`)
      if (data && data.permanentExhibition) {
        commit('SET_EXPOSICAO_PERMANENTE', data.permanentExhibition)
      }
      return data
    } catch (err) {
    }
  },

  async handleGetByProductPermanentExhibition({ commit }, product) {
    try {

      const { data } = await api.get(`/permanent-exhibition/product/${product}`)
      if (data && data.permanentExhibition) {
        commit('SET_EXPOSICAO_PERMANENTE_PRODUTO', data.permanentExhibition)
      }
      return data
    } catch (err) {
    }
  }
}

export const getters = {
  exposicaoPermanente: (state) => state.exposicaoPermanente,
  exposicaoPermanenteProduto: (state) => state.exposicaoPermanenteProduto,
}
