import api from '../../utils/api'

export const state = {
  classificationRequest: {},
  classificationRequests: [],
  classificationRequestsMeta: {}
}

export const mutations = {
  SET_CLASSIFICATION_REQUEST(state, data) {
    state.classificationRequest = data
  },
  SET_CLASSIFICATION_REQUESTS(state, data) {
    state.classificationRequests = data
  },
  SET_CLASSIFICATION_REQUESTS_META(state, data) {
    state.classificationRequestsMeta = data
  }
}

export const actions = {
  async handleCreateClassificationRequest({ state }) {
    const classificationRequest = state.classificationRequest
    const response = await api.post('/classification-requests', classificationRequest)

    return response.data
  },
  async handleGetClassificationRequests({ commit }, payload) {
    let params = {};

    if (payload) {
      params = {
        startDate: payload.startDate || undefined,
        endDate: payload.endDate || undefined,
        sortBy: payload.sortBy || undefined,
        sortIn: payload.sortIn || undefined,
        skip: payload.skip || undefined,
        limit: payload.limit || undefined,
        filter: payload.filter || undefined,
      }
    }
    const { data: response } = await api.get('/classification-requests', { params })

    if (response && response.data.classificationRequests) {
      commit('SET_CLASSIFICATION_REQUESTS', response.data.classificationRequests);
      commit('SET_CLASSIFICATION_REQUESTS_META', response.meta);
    } else {
      commit('SET_CLASSIFICATION_REQUESTS', []);
      commit('SET_CLASSIFICATION_REQUESTS_META', {});
    }
  },

  async handleGetClassificationRequestsExport({ commit }, payload) {
    let params = {};

    if (payload) {
      params = {
        startDate: payload.startDate || undefined,
        endDate: payload.endDate || undefined,
        filter: payload.filter || undefined,
      }
    }
    const { data: response } = await api.get('/classification-requests/export', { params })

    return response.data.classificationRequests
  },
}

export const getters = {
  getClassificationRequest: (state) => state.classificationRequest,
  getClassificationRequests: (state) => state.classificationRequests,
  getClassificationRequestsMeta: (state) => state.classificationRequestsMeta,
}
