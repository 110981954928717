<template>
  <section class='w-100 p-3'>
    <b-alert
      show
      variant='danger'
      :class='{ hidden: !error }'
    >
      {{ error }}
    </b-alert>
    <section class='mb-3' >
      <img width='80' src='../../../../assets/images/logoCadastroPerfil.svg'/>
    </section>
    <section>
      <p>Quais as <strong>principais razões para você</strong> utilizar o HO Fácil Web?</p>

      <section class='mt-3 mb-3'>
        <section v-for='(age, i) in reasonsToUse' :key='i' class='d-inline-block mr-3 mb-3'>
          <base-button
            :style="userProfile.reasonsToUse.find((x) => x.value === age.value) ? 'border: 2px solid var(--blue);' : ''"
            class='btn_quantity_person' @click='selectReasonToUse(age)'>
            {{ age.description }}
          </base-button>
        </section>
      </section>
      <base-input-text
        v-if='!!userProfile.reasonsToUse.find((x) => x.value === optionOther.value)'
        class='w-100'
        placeholder='Digite qual a razão para você utilizar o HO Fácil Web?'
        v-model='optionOther.description'
        label='Qual a razão para você utilizar o HO Fácil Web?'
        type='test'
        block
      />
      <section class='d-flex justify-content-between align-items-center my-4'>
        <b-progress :value='75' height='10px' variant='primary' class='w-25'></b-progress>
        <b-overlay
          :show='loading'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='d-inline-block'
        >
          <base-button
            :disabled='!userProfile.reasonsToUse || userProfile.reasonsToUse.length <= 0 || (!!userProfile.reasonsToUse.find((x) => x.value === optionOther.value) && !optionOther.description)'
            @click='next()'
            class='btn mt-1'>
            <span class='text_btn'>Continuar</span>
          </base-button>
        </b-overlay>
      </section>
    </section>
  </section>
</template>

<script>

export default {
  namer: 'ReasonsToUse',
  components: {},
  computed: {
    userProfile() {
      return this.$store.getters['user/userProfile']
    },
    message() {
      return this.$store.getters['access/message']
    },
    error() {
      return this.$store.getters['access/error']
    }
  },
  data() {
    return {
      loading: false,
      optionOther: {
        value: 8,
        description: ''
      },
      reasonsToUse: [
        {
          value: 1,
          description: 'Praticidade na consulta de agentes químicos'
        },
        {
          value: 2,
          description: 'Facilidade no enquadramento do laudo de insalubridade'
        },
        {
          value: 3,
          description: 'Facilidade no enquadramento do LTCAT'
        },
        {
          value: 4,
          description: 'Facilidade na utilização da ACGIH'
        },
        {
          value: 5,
          description: 'Facilidade na análise dos agentes químicos dentro do PCMSO'
        },
        {
          value: 6,
          description: 'Elaboração de plano de amostragem quantitativa'
        },
        {
          value: 7,
          description: 'Elaboração do inventário químico'
        },
        {
          value: 8,
          description: 'Outro'
        }
      ]
    }
  },
  validations() {
    return {}
  },
  created() {
    this.$store.dispatch('access/updateError', '')
    this.$store.dispatch('access/updateMessage', '')
  },
  async mounted() {
  },
  methods: {
    selectReasonToUse(reasonToUse) {
      const index = this.userProfile.reasonsToUse.findIndex((x) => x.value === reasonToUse.value)
      if (index >= 0) {
        this.userProfile.reasonsToUse.splice(index, 1)
        return
      }
      this.userProfile.reasonsToUse.push(reasonToUse)
    },

    async next() {
      try {
        if (this.userProfile.reasonsToUse.find((x) => x.value === this.optionOther.value)){
         this.userProfile.reasonsToUse.find((x) => x.value === this.optionOther.value).description = this.optionOther.description
        }
        this.loading = true;
        this.$emit('next', 6)
      } catch (e) {

      } finally {
        this.loading = false;
      }

    }
  }
}
</script>

<style scoped>
.text_btn {
  font-weight: 600;
}

.btn {
  width: 130px;
  height: 45px;
  border-radius: 5px;
  border: none;
}

.btn:hover {
  color: white;
}

.btn_quantity_person {
  background: transparent;
  border-radius: 20px;
  font-size: 15px;
  color: #48626f;
  border: 2px solid #C1D5E4;
}

.btn_quantity_person:hover {
  border-color: var(--blue);
}
</style>
