import api from '../../utils/api'

export const state = {

}

export const mutations = {

}

export const actions = {
  async handleCreate(_, sector) {
    try {
      return await api.post('/sector', sector)
    } catch (err) {

    }
  },

  async handleGet(_, sectorId) {
    try {
      return await api.get(`/sector/${sectorId}`)
    } catch (err) {

    }
  },


  async handleOpen(_, sectorId) {
    try {
      return await api.put(`/sector/open/${sectorId}`)
    } catch (err) {

    }
  },

  async handleUpdade(_, sector) {
    try {
      return await api.put(`/sector/${sector._id}`, sector)
    } catch (err) {

    }
  },

  async handleDelete(_, sectorId) {
    try {
      return await api.delete(`/sector/${sectorId}`)
    } catch (err) {

    }
  },

}

export const getters = {

}
