<template>
  <b-modal
    ref='renew-modal'
    id='renew-modal'
    scrollable
    hide-footer
    hide-header
    header-bg-variant='info'
    header-text-variant='light'
    size='lg'
    @shown='showModal()'
    @hide='hideModal()'
  >
    <section class='modal-font-family' >
      <section class='d-flex align-content-center justify-content-between mb-2'>
        <section>
          <p class='renew-title mt-1'>Renove agora seu plano!</p>
        </section>

        <section>
          <b-icon
            style='cursor: pointer'
            @click='closeModal()'
            class='ml-1'
            font-scale='2.5'
            icon='x'
            color='#262626'
          >
          </b-icon>
        </section>
      </section>

      <section class='d-flex justify-content-between'>
        <section class=''>
          <p class=' renew-sub-title'>Data de vencimento do plano</p>
          <p
            class='renew-sub-sub-title'
            v-if='user.plan && user.plan.dueDate'
          >
            {{ formatDate(user.plan.dueDate) }}
            <span class='renew-sub-sub-title-normal text-danger'>
              {{ calculateHowManyDaysPlanExpires }}
            </span>
          </p>
          <p
            v-else
            class='renew-sub-sub-title'
          >
            <span class='renew-sub-sub-title'>
              Plano expirado
            </span>
          </p>
        </section>

        <section>
          <p class='renew-sub-title'>Tipo de plano</p>

          <p class='renew-sub-sub-title' v-if='user.plan'>
            {{ planName }}
          </p>
          <p v-else class='renew-sub-sub-title'>
            Assinatura Anual
          </p>
        </section>

        <section class=''>
          <p class=' renew-sub-title'>Desconto para renovar</p>
          <p class='renew-sub-sub-title'>
            {{ user.renewalCoupon.discount }}% do valor total
          </p>
        </section>
      </section>

      <section class='mt-3'>
        <p class=' renew-sub-title'>Valor Final a ser pago</p>
        <p class='renew-sub-sub-title'>
          De: <span class='planPrice'>{{ formatRealCurrent(planPrice) }}</span>
        </p>
        <p class=' renew-sub-title'>Por {{ formatRealCurrent(planPriceDiscount) }} à vista</p>
      </section>

      <section class='container-attention mt-3'>
        <p class='text-center attention-title'>Atenção!</p>

        <section class=''>
          <section class='text-center d-flex align-content-end '>
            <img width='24' height='24' src='../../assets/images/Obs.svg' alt=''>
            <p class='ml-2 mt-1 attention-sub-title'>
              Caso você não renove antes do seu plano vencer, este desconto não será mais válido.
            </p>
          </section>

          <section>
            <section
              v-if='user.plan'
              class='mt-1 d-flex justify-content-center align-items-center timer'
            >
              <span class='pr-2 attention-sub-title ' >Tempo restante:</span>
              <section class='pr-2 text'>
                <strong class='mr-2'>{{ days | two_digits }} {{days > 1 ? ' Dias' : ' Dia'}},</strong>

                <strong class='mr-2'>{{ hours | two_digits }} {{hours > 1 ? ' Horas' : ' Hora'}},</strong>

                <strong class='mr-2'>{{ minutes | two_digits }} Min,</strong>

                <strong>{{ seconds | two_digits }} Seg</strong>
              </section>
            </section>
          </section>
        </section>
      </section>

      <section class='mt-4 mb-1'>
        <button
          @click='submit()'
          class='btn-pulse w-100 d-flex align-items-center justify-content-center'
        >
          <span class='btn-title mr-3'>
            Clique aqui para renovar
          </span>

          <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M12.172 9.00005L-3.83699e-07 9.00005L-2.96276e-07 7.00005L12.172 7.00005L6.808 1.63605L8.222 0.222046L16 8.00005L8.222 15.778L6.808 14.364L12.172 9.00005Z'
              fill='#022245' />
          </svg>
        </button>
      </section>
    </section>
  </b-modal>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import format from 'date-fns/esm/format/index'
import moment from 'moment'
import { formatRealCurrent } from '@/utils/format'

export default {
  name: 'RenewModal',
  props: {
    isOpen: { type: Boolean, default: false }
  },
  data() {
    return {
      planPrice: 0,
      planPriceDiscount: 0,
      now: Math.trunc(new Date().getTime() / 1000)
    }
  },
  methods: {
    ...mapActions('promocodes', {
      searchRenewalDiscountCoupon: 'searchRenewalDiscountCoupon'
    }),
    ...mapActions('plans', {
      handleGetPlans: 'handleGetPlans'
    }),
    ...mapActions('user', {
      setShowModalRenew: 'setShowModalRenew'
    }),
    formatRealCurrent,

    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy')
    },

    closeModal() {
      this.$refs['renew-modal'].hide()
    },
    async showModal() {
      await this.handleGetPlans()
      this.planPrice = Number(this.plans['AN1HO'].value)
      this.planPriceDiscount = this.planPrice - (this.planPrice / 100) * Number(this.user.renewalCoupon.discount)
      window.setInterval(() => {
        this.now = Math.trunc(new Date().getTime() / 1000)
      }, 1000)
    },
    async hideModal() {
      await this.setShowModalRenew(false)
    },
    async submit() {
      const code = await this.searchRenewalDiscountCoupon(this.user.username)
      await this.$router.push({
        name: 'Checkout',
        query: {
          plano: 'AN1HO',
          renew: code,
          utm_source: 'app',
          utm_medium: 'renov',
          utm_campaign: 'vazio',
          utm_term: 'renov',
          utm_content: 'vazio'
        }
      })
      this.closeModal()
    }
  },
  computed: {
    ...mapGetters('plans', {
      plans: 'getPlans'
    }),
    user() {
      return this.$store.getters['user/current']
    },
    checkPlanIsExpired() {
      return !!(this.user.plan && this.user.plan.status === 'paid')
    },
    calculateHowManyDaysPlanExpires() {
      if (this.checkPlanIsExpired) {
        const days = Math.ceil(moment.duration(moment(this.user.plan.dueDate).diff(moment(this.user.currentDate))).asDays())
        return `(${days} ${days > 1 ? 'dias' : 'dia'} restantes)`
      }
      return ``
    },
    planName() {
      return 'Assinatura Anual'
    },
    dateInMilliseconds() {
      return this.user.plan.dueDate ? Math.trunc(Date.parse(this.user.plan.dueDate) / 1000) : 0
    },
    seconds() {
      return (this.dateInMilliseconds - this.now) % 60
    },
    minutes() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60
    },
    hours() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24
    },
    days() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24)
    }
  },
  filters: {
    two_digits: (value) => {
      if (value < 0) {
        return '00'
      }
      if (value.toString().length <= 1) {
        return `0${value}`
      }
      return value
    }
  }
}
</script>

<style lang='scss' scoped>

.renew-title {
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #262626;
}

.renew-sub-title {
  color: #262626;
  font-size: 16px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 24px; /* 150% */
}

.renew-sub-sub-title {
  color: #262626;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1px;
}

.renew-sub-sub-title-normal {
  color: #022245;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
}

.planPrice {
  text-decoration: line-through;
}

.container-attention {
  border-radius: 6.25px;
  background: rgba(4, 168, 243, 0.40);
  padding: 5px 10px;

  .attention-title {
    color: var(--navy-blue);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .attention-sub-title {
    color: var(--navy-blue);
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

.btn-title {
  color: #022245;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.btn-pulse {
  -webkit-animation: pulse 1s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1s infinite cubic-bezier(0.66, 0, 0, 1);
  background: #CBD63E !important;
  box-shadow: 0 0 0 0 #CBD63E;
  padding: 12px 0;
  border-radius: 5px;
  border: none;

  &:hover {
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;
  }
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}

.text {
  font-size: 16px;
  font-weight: bold!important;
  line-height: 24px;
  text-align: center;
  color: var(--navy-blue);
}
.timer{
  background: #CBD63E;
  border-radius: 6px;
  padding: 10px 0;
}
</style>
