<template>
  <fieldset>
    <legend v-if="label">
      {{ label }}
    </legend>
    <div class="inputs" :class="direction">
      <label
        v-for="option in options"
        :key="option[_key]"
      >
        <input
          :name="`group_${label}`"
          type="radio"
          :value="option[_key]"
          :checked="modelValue === option[_key]"
          @input="updateValue"
        >
        <span>{{ option[_value] }}</span>
      </label>
    </div>
    <span class="error">
      {{ error }}
    </span>
  </fieldset>
</template>
<script>

export default {
  model: {
    prop: 'modelValue',
    event: 'input',
  },
  props: {
    label: { type: String, required: false, default: '' },
    modelValue: { type: [Number, String, Boolean], default: '' },
    options: { type: Array, default: () => [] },
    direction: { type: String, default: 'row' },
    error: { type: String, default: '' },
    _value: { type: String, default: 'value' },
    _key: { type: String, default: 'key' },
  },
  emits: ['input'],
  methods: {
    updateValue(event) {
      const { value } = event.target;

      if(value === 'false' || value === 'true'){
        this.$emit('input', value === 'true');
      } else {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style scoped lang="scss">
fieldset {
  border: 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  .inputs {
    width: 100%;
    display: flex;
    gap: 8px;

    &.row {
      flex-direction: row;
      align-items: center;
    }

    &.column {
      flex-direction: column;
      align-items: flex-start;
    }
    
    label {
      cursor: pointer;

      > input[type="radio"] {
        display: none;
      }
      
      > input[type="radio"] + *::before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 8px;
        border-radius: 50%;
        border-style: solid;
        border-width: 0.1rem;
        border-color: var(--blue);
      }

      > input[type="radio"]:checked + *::before {
        background: radial-gradient(var(--blue) 0%, var(--blue) 40%, transparent 50%, transparent);
        border-color: var(--blue);
      }
    }
  }
}
</style>
