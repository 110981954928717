import api from '../../utils/api'

export const state = {
  selectedAgent: undefined,
  appliedFilters: false,
  selectedAgents: [],
  summary: [],
  legalConsultationAgentsGroupedByProduct: [],
  summaryMeta: {
    skip: 1,
    limit: 50,
    search: '',
    acgih: '',
    nr15: '',
    linach: '',
    esocial: '',
    decreto : '',
    products : [],
  },
  buttons: [],
  exporting: false,
  lookingForAgents: false,
  thereAreDuplicateProducts: false,
  duplicateProducts: [],
  skip: false,
  agentsPerPage: 50,
  tabView: 'Summary',
  synergisticRiskProducts: [],
  synergisticRiskRoles: [],
}

export const mutations = {
  SET_SELECTED_AGENT(state, selectedAgent) {
    state.selectedAgent = selectedAgent
  },
  SET_SUMMARY(state, summary) {
    state.summary = summary
  },
  SET_SUMMARY_META(state, summaryMeta) {
    state.summaryMeta = summaryMeta
  },
  SET_SUMMARY_META_ORDER_BY(state, orderBy) {
    state.summaryMeta.orderBy = orderBy
  },
  SET_EXPORTING(state, exporting) {
    state.exporting = exporting
  },
  SET_SUMMARY_META_SKIP(state, skip) {
    state.skip = skip
  },
  SET_LOOKING_FOR_AGENTS(state, lookingForAgents) {
    state.lookingForAgents = lookingForAgents
  },
  SET_APPLIED_FILTERS(state, appliedFilters) {
    state.appliedFilters = appliedFilters
  },
  SET_TAB_VIEW(state, tabView) {
    state.tabView = tabView
  },
  SET_SELECTED_AGENTS(state, selectedAgents) {
    state.selectedAgents = selectedAgents
  },
  SET_THERE_ARE_DUPLICATE_PRODUCTS(state, thereAreDuplicateProducts) {
    state.thereAreDuplicateProducts = thereAreDuplicateProducts
  },
  SET_DUPLICATE_PRODUCTS(state, duplicateProducts) {
    state.duplicateProducts = duplicateProducts
  },
  SET_LEGAL_CONSULTATION_AGENTS_GROUPED_BY_PRODUCT(state, legalConsultationAgentsGroupedByProduct) {
    state.legalConsultationAgentsGroupedByProduct = legalConsultationAgentsGroupedByProduct
  },
  SET_SYNERGISTIC_RISK_PRODUCTS(state, synergisticRiskProducts) {
    state.synergisticRiskProducts = synergisticRiskProducts
  },
  SET_SYNERGISTIC_RISK_ROLES(state, synergisticRiskRoles) {
    state.synergisticRiskRoles = synergisticRiskRoles
  }
}

export const actions = {
  importList({ commit }, payload) {
    return api.post(`/legal-search/import`, payload)
  },
  importAgentsCompany({ commit }, payload) {
    return api.post(`/legal-search/importAgentsCompany`, payload)
  },
  importAgentsProduct({ commit }, payload) {
    return api.post(`/legal-search/importAgentsProduct`, payload)
  },
  add({ }, entry) {
    return api.post(`/legal-search/`, entry)
  },
  setSkip({ commit }, skip) {
    commit('SET_SUMMARY_META_SKIP', skip)
  },
  setSlectedAgents({ commit }, selectedAgents) {
    commit('SET_SELECTED_AGENTS', selectedAgents)
  },
  summaryClear({ commit }) {
    commit('SET_SUMMARY', [])
    commit('SET_LEGAL_CONSULTATION_AGENTS_GROUPED_BY_PRODUCT', [])
  },
  setLegalConsultationAgentsGroupedByProduct({ commit }, legalConsultationAgentsGroupedByProduct) {
    commit('SET_LEGAL_CONSULTATION_AGENTS_GROUPED_BY_PRODUCT', legalConsultationAgentsGroupedByProduct)
  },
  handleResetSummaryMeta: ({ commit }) => commit('SET_SUMMARY_META', {
    skip: 1,
    limit: 50,
    search: '',
    acgih: '',
    nr15: '',
    linach: '',
    esocial: '',
    decreto : '',
    products : [],
  }),
  handleSetSummaryMeta({ commit, state }, selectedFilters){
    const { skip, limit} = state.summaryMeta
    const { acgih, nr15, linach, esocial, decreto, products} = selectedFilters
    localStorage.setItem('summaryMeta', JSON.stringify(selectedFilters));
    const filterSummaryMeta = {
      skip,
      limit,
      acgih,
      nr15,
      linach,
      esocial,
      decreto,
      products
    }
    commit('SET_SUMMARY_META', filterSummaryMeta)
  },
  handleSetSummaryMetaOrderBy({ commit, state }, orderBy){
    localStorage.setItem('summaryMetaOrderBy', JSON.stringify(orderBy));
    commit('SET_SUMMARY_META_ORDER_BY', orderBy)
  },
  setTabView({ commit }, tabView) {
    commit('SET_TAB_VIEW', tabView)
  },
  async load({ commit, state }, userId) {
    try {
      commit('SET_LOOKING_FOR_AGENTS', true)
      const params = {
        ...state.summaryMeta,
        skip: state.skip
      }
      params.products = params.products.join(',')
      const { data } = await api.get(`/legal-search/${userId}`, { params })

      if (data.legalSearch && data.legalSearch.length > 0) {
        data.legalConsultationAgentsGroupedByProduct.forEach((x) => {
          x.legalSearches.forEach((y) => {
            y.registeredProduct = !!(y.product && y.product._id && y.product._id !== '-');
            y.showCompleteObservationSentence = false
          })
        })
        data.legalSearch.forEach((x) => {
          x.registeredProduct = !!(x.product && x.product._id && x.product._id !== '-');
          x.showCompleteObservationSentence = false
        })
        commit('SET_SUMMARY', data.legalSearch)
        commit('SET_LEGAL_CONSULTATION_AGENTS_GROUPED_BY_PRODUCT', data.legalConsultationAgentsGroupedByProduct)
        commit('SET_SUMMARY_META', data.meta)
      } else {
        commit('SET_SUMMARY', [])
        commit('SET_SUMMARY_META', data.meta)
        commit('SET_LEGAL_CONSULTATION_AGENTS_GROUPED_BY_PRODUCT', [])
      }

      commit('SET_LOOKING_FOR_AGENTS', false)
      const meta = state.summaryMeta;
      commit('SET_APPLIED_FILTERS', !!(meta.search ||
        meta.acgih ||
        meta.nr15 ||
        meta.linach ||
        meta.esocial ||
        meta.decreto ||
        meta.products.length > 0)
      )

      commit('SET_THERE_ARE_DUPLICATE_PRODUCTS', false)
      commit('SET_DUPLICATE_PRODUCTS', [])
      const { data: productsDuplicate } = await api.get(`/legal-search/product/${userId}`)
      if (productsDuplicate && productsDuplicate.legalSearch && productsDuplicate.legalSearch.length > 0) {
        commit('SET_THERE_ARE_DUPLICATE_PRODUCTS', true)
        commit('SET_DUPLICATE_PRODUCTS', productsDuplicate.legalSearch)
      }

      const {data: dataSynergisticRisk} = await api.get(`/legal-search/synergisticRisk/${userId}`)
      const {products, roles} = dataSynergisticRisk
      commit('SET_SYNERGISTIC_RISK_PRODUCTS', [])
      commit('SET_SYNERGISTIC_RISK_ROLES', [])
      if (products && products.length > 0) {
        commit('SET_SYNERGISTIC_RISK_PRODUCTS', products)
      }
      if (roles && roles.length > 0) {
        commit('SET_SYNERGISTIC_RISK_ROLES', roles)
      }
    } catch (e) {

    }
  },

  async handGetProductByName({ commit}, userId) {
    try {
      commit('SET_THERE_ARE_DUPLICATE_PRODUCTS', false)
      commit('SET_DUPLICATE_PRODUCTS', [])
      const { data } = await api.get(`/legal-search/product/${userId}`)
      if (data && data.legalSearch && data.legalSearch.length > 0) {
        commit('SET_THERE_ARE_DUPLICATE_PRODUCTS', true)
        commit('SET_DUPLICATE_PRODUCTS', data.legalSearch)
      }
    } catch (e) {
      commit('SET_THERE_ARE_DUPLICATE_PRODUCTS', false)
      commit('SET_DUPLICATE_PRODUCTS', [])
    }
  },

  async loadAll(_, body) {
    const { data } = await api.post(`/legal-search/all`, body)
    return data.legalSearch
  },
  async remove({ }, itemsToRemove) {
    await api.post(`/legal-search/delete`, itemsToRemove)
  },
  async importFreeModeAgents({ }, user) {
    await api.post(`/legal-search/import-free-mode-agents/${user}`)
  },
  async notRemove({ }, itemsToRemove) {
    try {
      await api.post(`/legal-search/notDelete`, itemsToRemove)
    } catch (e) {

    }
  },
  async finishInsertionAgents({ }, body) {
    await api.post(`/legal-search/finishInsertionAgents`, body)
  },
  async legalSearch({ }, legalSearchId) {
    return (await api.get(`/legal-search/agent/${legalSearchId}`)).data
  },
  async saveActivities({ }, agent) {
    await api.put(`/legal-search/${agent.legalSearchId}`, agent)
  },
  async saveEvaluationForm({ }, evaluationForm) {
    await api.put(`/legal-search/evaluationForm/${evaluationForm.legalSearchId}`, evaluationForm)
  },
  async saveInteractions({ }, agent) {
    await api.put(`/legal-search/interactions/${agent.legalSearchId}`, agent)
  },
  async handUpdatePercentage({ }, payload) {
    return await api.put(`/legal-search/update-percentage`, payload)
  },
  select({ commit }, agent) {
    commit('SET_SELECTED_AGENT', agent)
  },
  async exportingExcel({ commit }, exporting) {
    commit('SET_EXPORTING', exporting)
  }
}

export const getters = {
  selectedAgent: (state) => state.selectedAgent,
  summary: (state) => state.summary,
  exporting: (state) => state.exporting,
  lookingForAgents: (state) => state.lookingForAgents,
  summaryMeta: (state) => state.summaryMeta,
  agentsPerPage: (state) => state.agentsPerPage,
  appliedFilters: (state) => state.appliedFilters,
  tabView: (state) => state.tabView,
  selectedAgents: (state) => state.selectedAgents,
  thereAreDuplicateProducts: (state) => state.thereAreDuplicateProducts,
  duplicateProducts: (state) => state.duplicateProducts,
  legalConsultationAgentsGroupedByProduct: (state) => state.legalConsultationAgentsGroupedByProduct,
  synergisticRiskProducts: (state) => state.synergisticRiskProducts,
  synergisticRiskRoles: (state) => state.synergisticRiskRoles,
}
