import api from '../../utils/api'

export const state = {
  collaborator: {
    userMaster: '',
    collaborator: '',
    planId: ''
  },
  collaborators: [],
  collaboratorsMeta: {},
  collaboratorsHistoric: [],
  collaboratorsHistoricMeta: {}
}

export const mutations = {
  SET_COLLABORATOR(state, collaborator) {
    state.collaborator = collaborator
  },
  SET_COLLABORATORS(state, collaborators) {
    state.collaborators = collaborators
  },
  SET_COLLABORATORS_META(state, collaboratorsMeta) {
    state.collaboratorsMeta = collaboratorsMeta
  },
  SET_COLLABORATORS_HISTORIC(state, collaboratorsHistoric) {
    state.collaboratorsHistoric = collaboratorsHistoric
  },
  SET_COLLABORATORS_HISTORIC_META(state, collaboratorsHistoricMeta) {
    state.collaboratorsHistoricMeta = collaboratorsHistoricMeta
  }
}

export const actions = {
  async handleCreateCollaborator({ state }) {
    const collaborator = state.collaborator
    return await api.post('/collaborator', collaborator)
  },
  async handleCollaboratorByUserName(_, payload) {
    return (await api.post(`/collaborator/userName`, payload)).data
  },
  async handleCollaboratorTransferPlan(_, body) {
    return await api.post('/collaborator/transferPlan', body)
  },
  async handleCollaboratorRevokeAccess(_, body) {
    return await api.post('/collaborator/revokeAccess', body)
  },
  async handleSetCollaborator({ commit }, collaborator) {
    commit('SET_COLLABORATOR', collaborator)
  },
  async handleCollaborators({ commit }, payload) {
    try {
      let params = {}

      if (payload) {
        params = {
          skip: payload.skip || undefined,
          userMaster: payload.userMaster || undefined
        }
      }

      const { data: response } = await api.get('/collaborator/active', { params })
      if (response && response.data.collaborators && response.data.collaborators.length > 0) {
        commit('SET_COLLABORATORS', response.data.collaborators)
        commit('SET_COLLABORATORS_META', response.meta)
      } else {
        commit('SET_COLLABORATORS', [])
        commit('SET_COLLABORATORS_META', {})
      }
    } catch (err) {
    }
  },

  async handleCollaboratorsHistoric({ commit }, payload) {
    try {
      let params = {}

      if (payload) {
        params = {
          skip: payload.skip || undefined,
          userMaster: payload.userMaster || undefined,
          limit: payload.limit || undefined,
        }
      }

      const { data: response } = await api.get('/collaborator/historic', { params })
      if (response && response.data.collaborators && response.data.collaborators.length > 0) {
        commit('SET_COLLABORATORS_HISTORIC', response.data.collaborators)
        commit('SET_COLLABORATORS_HISTORIC_META', response.meta)
      } else {
        commit('SET_COLLABORATORS_HISTORIC', [])
        commit('SET_COLLABORATORS_HISTORIC_META', {})
      }
    } catch (err) {
    }
  }
}

export const getters = {
  getCollaborator: (state) => state.collaborator,
  getCollaborators: (state) => state.collaborators,
  getCollaboratorsMeta: (state) => state.collaboratorsMeta,
  getCollaboratorsHistoric: (state) => state.collaboratorsHistoric,
  getCollaboratorsHistoricMeta: (state) => state.collaboratorsHistoricMeta,
}
