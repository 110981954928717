import axios from 'axios'
import api from '../../utils/api'

export const state = {
  companies: [],
  branches: [],
  departments: [],
  roles: [],
  products: [],
  termsOfUse: [],
  form: {},
  hierarchyToBeCompleted: {}
}

export const mutations = {
  SET_HIERARCHYTOBECOMPLETED(state, hierarchyToBeCompleted) {
    state.hierarchyToBeCompleted = hierarchyToBeCompleted;
  },
  SET_ITEMS(state, data) {
    const [type, items] = data
    state[type] = items
  },
  SET_FORM_DATA(state, data) {
    state.form = data
    if (data.body) {
      state.form.body = data.body
    }
  }
}

const parentLevel = {
  branch: 'companies',
  department: 'branches',
  role: 'departments',
  product: 'roles',
  termsOfUse: 'product',
}

const childrenLevel = {
  company: 'branches',
  branch: 'departments',
  department: 'roles',
  role: 'products',
  products: 'termsOfUse'
}

const collection = {
  company: 'companies',
  branch: 'branches',
  department: 'departments',
  role: 'roles',
  product: 'products',
  termsOfUse: 'termsOfUse'
}

export const actions = {
  async getParents({ commit }, type) {
    const { data } = await api.get(`hierarchies/${parentLevel[type]}`)
    commit('SET_ITEMS', [parentLevel[type], data])
  },
  async checkHierarchy({ commit }, payload) {
    const {type, hierarchyId} = payload
    const { data } = await api.get(`hierarchies/checkHierarchyComplete/${type}/${hierarchyId}`)
    commit('SET_HIERARCHYTOBECOMPLETED', data)
    return data
  },
  async getChildren({ commit }, params) {
    const [type, parent] = params
    if (parent) {
      const { data } = await api.get(
        `hierarchies/${childrenLevel[type]}?parent=${parent}`
      )
      commit('SET_ITEMS', [childrenLevel[type], data])
      return data
    }
    commit('SET_ITEMS', [childrenLevel[type], []])
  },
  async getOptions({ commit }, level) {
    const { data } = await api.get(
      `hierarchies/${level.type}`,
      {
        params: {
          parent: level.parent || undefined
        }
      }
    )
    commit('SET_ITEMS', [level.type, data])

    return data
  },
  async addHierarchyLevel(_, item) {
    try {
      if (item.file) {
        const file = item.file

        const { data: responseSignedUrl } = await api.get('uploads/signed-url', {
          params: {
            fileType: file.type
          }
        })

        if (responseSignedUrl.error) {
          return responseSignedUrl
        }

        const { url: signedUrl } = responseSignedUrl
        const url = signedUrl.split('?')[0]

        const options = {
          headers: {
            'Content-Type': file.type
          }
        };

        await axios.put(signedUrl, file, options)

        delete item.file

        item.body.file = url
      }

      const { data } = await api.post('hierarchies', item)

      return data
    } catch (err) {
      return {
        error: err
      }
    }
  },
  async updateHierarchy(_, hierarchy) {
    try {

      if (hierarchy.file && hierarchy.file.size) {
        const file = hierarchy.file

        const { data: responseSignedUrl } = await api.get('uploads/signed-url', {
          params: {
            fileType: file.type
          }
        })


        if (responseSignedUrl.error) {
          return responseSignedUrl
        }

        const { url: signedUrl } = responseSignedUrl
        const url = signedUrl.split('?')[0]

        const options = {
          headers: {
            'Content-Type': file.type
          }
        };

        await axios.put(signedUrl, file, options)

        delete hierarchy.file


        hierarchy.body.file = url
      }

      await api.put(`hierarchies/${hierarchy._id}`, hierarchy)
    } catch (err) {
      console.log(err)
    }
  },
  async removeItem(_, id) {
    try {
      const inserted = await api.delete(`hierarchies/${id}`)
      return inserted._id
    } catch (err) {
      console.log(err)
    }
  },
  async generateChemicalInventoryReport(_,payload) {
    try {
      const { data } = await api.post(`hierarchies/export/chemicalInventory`, payload)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  loadItem({ commit, state }, data) {
    try {
      const item = state[collection[data.type]].find((item) => {
        return item._id === data._id
      })
      commit('SET_FORM_DATA', item)
    } catch (err) {
      console.log(err)
    }
  },
  setFormData({ commit }, data) {
    commit('SET_FORM_DATA', data)
  },
  setNewData({ commit }, data) {
    commit('SET_FORM_DATA', data)
  },
  async handleCopyProduct(_, payload) {
    try {
      const { data } = await api.post(`hierarchies/copy/product`, payload)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  async handleCopyProductByDepartment(_, payload) {
    try {
      const { data } = await api.post(`hierarchies/copy/product-by-department`, payload)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  async handleGetHierarchy(_, userId) {
    try {
      const { data } = await api.get(`hierarchies/hierarchy/${userId}`)
      return data
    } catch (err) {
      console.log(err)
    }
  },
}

export const getters = {
  companies: (state) => state.companies,
  branches: (state) => state.branches,
  departments: (state) => state.departments,
  roles: (state) => state.roles,
  products: (state) => state.products,
  termsOfUse: (state) => state.termsOfUse,
  form: (state) => state.form,
  gethierarchyToBeCompleted: (state) => state.hierarchyToBeCompleted
}
