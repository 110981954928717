import api from '../../utils/api'

export const state = {
  protecaoEficaz: {
    pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico: null,
    pergunta_1_1_OEPCFuncionandoAdequadamente: null,
    pergunta_1_2_EPCTemLaudoDocumentoAtestandoEficacia: null,
    pergunta_1_3_LaudoDocumentoDentroPrazoPrevistoCronogramaManutencao: null,
    pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico: null,
    pergunta_2_1_todosEPIsEmpresaAdquiriuPossuemCA: null,
    pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI: null,
    pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco: null,
    pergunta_2_4_fornecimentoEPISempreRegistrado: null,
    pergunta_2_5_empresaCaraterFiscalizacaoConstanteJuntoSeusEmpregadosEstaExigindoUsoEPI: null,
    pergunta_2_6_empresaExecutaProcedimentosInternosParaRealizarHigienizacaoManutencaoPeriodicaEPIs: null,
    pergunta_2_7_empresaSubstituiImediatamenteEPICasoDeleEstarDanificadoExtraviado: null,
    pergunta_2_8_empresaExecutaProcedimentosInternosParaCasoEPIApresenteIrregularidades: null,
    pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66: null,
    pergunta_3_1_EPITrabalhadorUsaSomenteAqueleFornecidoPelaEmpresa: null,
    pergunta_3_2_EPIUtilizadoPeloTrabalhadorEstaAlinhadoSuaNecessidadeFinalidadeRisco: null,
    pergunta_3_3_trabalhadorEstaSendoResponsavelPelaLimpezaGuardaConservacaoEPI: null,
    pergunta_3_4_trabalhadorMatemComunicaaoComEmpresaParaInformarSituacoesEPIEstiverExtraviadoDanificadoQualquerAlteracaoTorneImproprioParaUso: null,
    pergunta_3_5_trabalhadorCumpreDeterminacoeEmpresaQuantoUsoEPI: null
  },
  protecaoEficazProduto: {
    pergunta_1_existeEquipamentoProtecaoColetivaAuxiliarControleRiscoQuimico: null,
    pergunta_1_1_OEPCFuncionandoAdequadamente: null,
    pergunta_1_2_EPCTemLaudoDocumentoAtestandoEficacia: null,
    pergunta_1_3_LaudoDocumentoDentroPrazoPrevistoCronogramaManutencao: null,
    pergunta_2_empregadorOrganizacaoCumpreFazCumprirItensNR06Topico: null,
    pergunta_2_1_todosEPIsEmpresaAdquiriuPossuemCA: null,
    pergunta_2_2_empregadosForamPrientadosTreinadosSobreUsoEPI: null,
    pergunta_2_3_EPIFornecidoGratuitoEmpregadoAssimMomentoFornecimentoEstavaPerfeitoEstadoConservacaooFormaAdequadaRisco: null,
    pergunta_2_4_fornecimentoEPISempreRegistrado: null,
    pergunta_2_5_empresaCaraterFiscalizacaoConstanteJuntoSeusEmpregadosEstaExigindoUsoEPI: null,
    pergunta_2_6_empresaExecutaProcedimentosInternosParaRealizarHigienizacaoManutencaoPeriodicaEPIs: null,
    pergunta_2_7_empresaSubstituiImediatamenteEPICasoDeleEstarDanificadoExtraviado: null,
    pergunta_2_8_empresaExecutaProcedimentosInternosParaCasoEPIApresenteIrregularidades: null,
    pergunta_3_empregadoTrabalhadorCumpreTodosItensNR06Topico66: null,
    pergunta_3_1_EPITrabalhadorUsaSomenteAqueleFornecidoPelaEmpresa: null,
    pergunta_3_2_EPIUtilizadoPeloTrabalhadorEstaAlinhadoSuaNecessidadeFinalidadeRisco: null,
    pergunta_3_3_trabalhadorEstaSendoResponsavelPelaLimpezaGuardaConservacaoEPI: null,
    pergunta_3_4_trabalhadorMatemComunicaaoComEmpresaParaInformarSituacoesEPIEstiverExtraviadoDanificadoQualquerAlteracaoTorneImproprioParaUso: null,
    pergunta_3_5_trabalhadorCumpreDeterminacoeEmpresaQuantoUsoEPI: null
  },
  loadingEffectiveProtection: false
}

export const mutations = {
  SET_PROTECAO_EFICAZ(state, protecaoEficaz) {
    state.protecaoEficaz = protecaoEficaz
  },
  SET_PROTECAO_EFICAZ_PRODUTO(state, protecaoEficazProduto) {
    state.protecaoEficazProduto = protecaoEficazProduto
  },
  SET_LOADING_EFFECTIVE_PROTECTION(state, loadingEffectiveProtection) {
    state.loadingEffectiveProtection = loadingEffectiveProtection
  }
}

export const actions = {
  async handleReset({ commit }, protecaoEficaz) {
    commit('SET_PROTECAO_EFICAZ', protecaoEficaz)
  },

  async handleCreateEffectiveprotection(_, protecaoEficaz) {
    try {
      await api.post('/effective-protection', protecaoEficaz)
    } catch (err) {
    }
  },

  async handleUpdateEffectiveprotection(_, protecaoEficaz) {
    try {
      const { data } = await api.put(`/effective-protection/${protecaoEficaz._id}`, protecaoEficaz)
      return data
    } catch (err) {
    }
  },

  async handleGetEffectiveprotection({ commit }, user) {
    try {
      commit('SET_LOADING_EFFECTIVE_PROTECTION', true)
      const { data } = await api.get(`/effective-protection/${user}`)
      if (data && data.effectiveProtection) {
        commit('SET_PROTECAO_EFICAZ', data.effectiveProtection)
      }
      commit('SET_LOADING_EFFECTIVE_PROTECTION', false)
      return data
    } catch (err) {
    }
  },

  async handleGetByProductEffectiveprotection({ commit }, product) {
    try {
      commit('SET_LOADING_EFFECTIVE_PROTECTION', true)
      const { data } = await api.get(`/effective-protection/product/${product}`)
      if (data && data.effectiveProtection) {
        commit('SET_PROTECAO_EFICAZ_PRODUTO', data.effectiveProtection)
      }
      commit('SET_LOADING_EFFECTIVE_PROTECTION', false)
      return data
    } catch (err) {
    }
  }
}

export const getters = {
  protecaoEficaz: (state) => state.protecaoEficaz,
  protecaoEficazProduto: (state) => state.protecaoEficazProduto,
  loadingEffectiveProtection: (state) => state.loadingEffectiveProtection
}
