export default class CreditCard {
  constructor(number, expirationDate, year, verificationValue) {
    this.number = number ? number : ''
    this.expirationDate = expirationDate ? expirationDate : ''
    this.verificationValue = verificationValue ? verificationValue : ''
  }
  validate() {
    return this.number && this.expirationDate && this.verificationValue
  }
}
