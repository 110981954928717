import api from '../../utils/api'

export const state = {
  accessesCollaborator: [],
  accessCollaboratorMeta: {}
}

export const mutations = {
  SET_ACCESSES_COLLABORATOR(state, accessesCollaborator) {
    state.accessesCollaborator = accessesCollaborator
  },
  SET_ACCESS_COLLABORATOR_META(state, accessCollaboratorMeta) {
    state.accessCollaboratorMeta = accessCollaboratorMeta
  }
}

export const actions = {
  async handleAccessCollaborator({ commit }, payload) {
    try {
      let params = {}

      if (payload) {
        params = {
          skip: payload.skip || undefined,
          planId: payload.planId || undefined
        }
      }

      const { data: response } = await api.get('/access-collaborator', { params })
      if (response && response.data.accessCollaborator && response.data.accessCollaborator.length > 0) {
        commit('SET_ACCESSES_COLLABORATOR', response.data.accessCollaborator)
        commit('SET_ACCESS_COLLABORATOR_META', response.meta)
      } else {
        commit('SET_ACCESSES_COLLABORATOR', [])
        commit('SET_ACCESS_COLLABORATOR_META', {})
      }
    } catch (err) {
    }
  },

  async handleAmountAccessCollaboratorPaid(_, planId) {
    const { data } = await api.post(`/access-collaborator/amountAccessCollaboratorPaid/${planId}`)
    return data
  },

  async handleCreateAccessCollaborator(_, accessCollaborator) {
    try {
      return await api.post(`/access-collaborator`, accessCollaborator)
    } catch (e) {

    }
  },

  async handleAmountAccessCollaboratorRemaining(_, planId) {
    const { data } = await api.post(`/access-collaborator/amountAccessCollaboratorRemaining/${planId}`)
    return data
  },

  async handleAmountAccessPlan(_, planId) {
    return (await api.get(`/access-collaborator/accessPlan/${planId}`)).data
  },

  async handleCancelAccess(_, accessId) {
    return await api.post(`/access-collaborator/cancel/${accessId}`)
  }

}

export const getters = {
  getAccessesCollaborator: (state) => state.accessesCollaborator,
  getAccessCollaboratorMeta: (state) => state.accessCollaboratorMeta
}
