import axios from 'axios'
import { decrypt, encrypt } from '@/utils/EncryptDecryptData'

let $api

$api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 360000 /*6 minutes */,
  withCredentials: true,
  headers: {}
})

$api.interceptors.request.use(function(config) {
  const sessionId = localStorage.getItem('sessionId')
  const tokenHofacil = localStorage.getItem('tokenHofacil')
  if (sessionId && sessionId !== 'null' && tokenHofacil && tokenHofacil !== 'null') {
    config.headers.sessionId = sessionId
    config.headers.Authorization = tokenHofacil
  }
  return config
})

$api.interceptors.response.use(
  function(response) {
    const { data } = response
    if(data){
      response.data = decrypt(response.data)
    }
    return response
  },
  async function(error) {
    if (!error.response || error.response.status === 504 || error.response.status === 408) {
      fetch(`${process.env.VUE_APP_BASE_URL}/health`, {
        method: 'GET'
      })
        .then(response => {
        })
        .catch(err => {
          window.location.assign(`${window.location.origin}/em-manutencao`)
        })
    }
    if (401 === error.response.status && window.location.href.indexOf('checkout') === -1) {
      window.location.href = `${window.location.origin}/login`
    } else {
      return Promise.reject(error)
    }
  }
)

export default $api