<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseAlert',
  props: {
    block: { type: Boolean },
    danger: { type: Boolean },
  },
  computed: {
    classes() {
      return {
        block: this.block,
        danger: this.danger,
      }
    },
    component() {
      return this.asLink ? 'a' : 'button'
    },
  },
}
</script>

<style scoped lang="scss">
div {
  box-sizing: border-box;
  display: block;
  outline: none;
  background-color: var(--primary);
  color: white;
  border: none;
  padding: 0.6rem 1.5rem;
  font-size: 18px;
  font-weight: bold;
  line-height: 100%;
  margin: 0px;
  border: 0px;
  width: fit-content;
  height: fit-content;
  border: 1px solid #cecece;
  padding: 0.8rem 2rem;
  font-weight: 600;
  box-shadow: 1px 1px 4px 1px #cacaca;
  margin: 15px 0px;
  &.block {
    width: 100%;
    text-align: center;
  }
  &.danger {
    border: 1px solid #f5c6cb;
    color: #721c24;
    background-color: #f5c6cb88;
  }
}
</style>
