import api from '../utils/api'
import store from '../stores/index'

export default async (to, from, next) => {
  const sessionId = localStorage.getItem("sessionId");
  try {
    if (sessionId !== null) {
      const user = JSON.parse(localStorage.getItem("userHoFacil"))
      const { name } = to
      if (user && user.functionalities && !!(user.functionalities.find((functionality) => functionality.namePath === name))) {
        await store.dispatch('user/updateUser', user)
        return next()
      }
    }
    await store.dispatch('user/updateUser', undefined)
    if (from.path !== '/login') {
      return next('/login')
    } else {
      return false
    }
  } catch (err) {
    return next('/login')
  }
}
