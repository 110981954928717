function convertNameFile(nameFile, typeFile) {
  const nameFileReplace = nameFile.replace(typeFile, '').replace('./', '');

  const newName = nameFileReplace[0].toLowerCase()
    + nameFileReplace
      .slice(1, nameFileReplace.length)
      .replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);

  return newName;
}

export function setupBaseComponents(app) {
  const components = require.context(
    '../components/Base',
    false,
    /.(vue)$/,
  );

  components.keys().forEach((fileName) => {
    const componentName = convertNameFile(fileName, '.vue');
    const componentConfig = components(fileName);

    app.component(componentName, componentConfig.default);
  });
}
