import api from '../../utils/api'

export const state = {
  invitationsCollaborator: [],
  invitationsCollaboratorMeta: {},
}

export const mutations = {
  SET_INVITATIONS_COLLABORATOR(state, invitationsCollaborator) {
    state.invitationsCollaborator = invitationsCollaborator
  },
  SET_INVITATIONS_COLLABORATOR_META(state, invitationsCollaboratorMeta) {
    state.invitationsCollaboratorMeta = invitationsCollaboratorMeta
  },
}

export const actions = {
  async handleSendInvitation(_, invitation) {
    try {
      return await api.post('/invitationCollaborator', invitation)
    } catch (e) {

    }
  },
  async handlerRevokeInvitation(_, invitation) {
    try {
      return await api.post('/invitationCollaborator/revoke', invitation)
    } catch (e) {

    }
  },
  async handleInvitationsCollaborator({ commit }, payload) {
    try {
      let params = {}

      if (payload) {
        params = {
          skip: payload.skip || undefined,
          userMaster: payload.userMaster || undefined
        }
      }

      const { data: response } = await api.get('invitationCollaborator/invitations', { params })
      if (response && response.data.invitations && response.data.invitations.length > 0) {
        commit('SET_INVITATIONS_COLLABORATOR', response.data.invitations)
        commit('SET_INVITATIONS_COLLABORATOR_META', response.meta)
      } else {
        commit('SET_INVITATIONS_COLLABORATOR', [])
        commit('SET_INVITATIONS_COLLABORATOR_META', {})
      }
    } catch (err) {
    }
  },
}

export const getters = {
  getInvitationsCollaborator: (state) => state.invitationsCollaborator,
  getInvitationsCollaboratorMeta: (state) => state.invitationsCollaboratorMeta,
}
