const encryptor = require('simple-encryptor')({
  key: process.env.VUE_APP_TOKEN_SECRET_DATA,
  hmac: false,
  debug: false
})

export function encrypt(data) {
  try {
    return encryptor.encrypt(data)
  } catch (e) {
    console.log(e)
  }
}

export function decrypt(data) {
  try {
    return encryptor.decrypt(data)
  } catch (e) {
    console.log(e)
  }
}