import api from '../../utils/api'
import format from 'date-fns/esm/format/index'
export const state = {
  reportDownloads: {
    type: '',
    status: '',
    url: ''
  },
  reportsDownloads: [],
  reportsDownloadsMeta: {},
};

export const mutations = {
  SET_REPORTDOWNLOADS(state, reportDownloads) {
    state.reportDownloads = reportDownloads;
  },
  SET_REPORSTDOWNLOADS(state, reportsDownloads) {
    state.reportsDownloads = reportsDownloads;
  },
  SET_REPORSTDOWNLOADS_META(state, reportsDownloadsMeta) {
    state.reportsDownloadsMeta = reportsDownloadsMeta;
  },
}

export const actions = {
  async handleCreateReportDownloads({ state }, payload) {
    return  await api.post(`/reports-downloads`, payload);

  },

  async handleGetReportDownloads({ commit }, payload) {
    let params = {};

    if (payload) {
      params = {
        endDate: payload.endDate || undefined,
        startDate: payload.startDate || undefined,
        skip: payload.skip || undefined,
        limit: payload.limit || undefined,
      }
    }
    const { data: response } = await api.get(`/reports-downloads`, { params });
    return response
  },
}

export const getters = {
  getReportDownloads: (state) => state.reportDownloads,
  getReportsDownloads: (state) => state.reportsDownloads,
  getReportsDownloadsMeta: (state) => state.reportsDownloadsMeta,
}
