<template>
  <div class='d-flex'>
    <Feedback
      v-if='feedbackModal'
      :isOpen='feedbackModal'
      @close='feedbackModal = false'
    />

    <b-modal
      id='modalCheckPendingInvoice'
      size='lg'
      title='Fatura pendente'
      header-bg-variant='danger'
      header-text-variant='light'
      ref='modalCheckPendingInvoice'
    >
      <p>
        Gostaríamos de informá-lo que há uma fatura pendente em seu plano.
        Por favor, verifique seu e-mail ou <a :href='secure_url' target='_blank'>clique aqui</a> para visualizar a
        fatura e efetuar o pagamento o quanto antes.
      </p>
      <p>
        É importante ressaltar que o não pagamento da fatura poderá resultar em suspensão dos serviços.
      </p>
      <p>
        Em caso de dúvidas ou para mais informações, por favor entre em contato com nossa equipe de suporte ao cliente.
      </p>
      <template #modal-footer='{ ok, cancel, hide }'>
        <b-button class='px-5' size='sm' variant='success' @click='ok()'>
          <strong>OK</strong>
        </b-button>
      </template>
    </b-modal>

    <dialog-confirmation
      description='Você está prestes a ativar o período de testes do HO Fácil Web. Ao clicar no botão de confirmar você terá acesso completo à plataforma durante os próximos 7 dias contados a partir da sua confirmação.'
      :is-open='openDialog'
      @confirmed='handleUsePlanTrial'
      @close='openDialog = false'
    />

    <b-modal
      ref='show-log'
      size='lg'
      scrollable
      hide-footer
      title='Atualização'
      header-bg-variant="info"
      header-text-variant="light"
    >
      <add-update-new :readOnly='true' :log='updateNew' />
    </b-modal>

    <Renew-modal if='openSurvey.login' :is-open='renewable' @close='renewable = false' />

    <div class='content'>
      <div>
        <SideMenu @collapse='collapseSideMenu' />
      </div>

      <div>
        <main
          :class='{ menuCollapsed: menuCollapsed }'
          v-if='itIsStillNecessaryToAcceptTermsOrPolicyPrivacy()'
        >
          <div class='mt-4 ml-2'>
            <h4>Termos de uso atualizado</h4>
            <p>Os Termos de Uso e Política de Privacidade - do sistema foram atualizados:</p>
            <section class='mb-3' v-if='acceptTermsUse()'>
              <Terms
                title='CONTRATO DE TERMOS DE USO'
                textLink='Tenha acesso aos termos de uso da Ho Fácil Web'
                :link='term.termsOfUseUrl'
              />

              <p class='mt-2'>
                <b-form-checkbox v-model='confirmTermsOfUseUrl'>Eu estou ciente e de acordo com os termos de uso.</b-form-checkbox>
              </p>

              <b-overlay
                :show='loadingTermsOfUse'
                rounded
                opacity='0.6'
                spinner-small
                style='width: fit-content'
                spinner-variant='primary'
                class=''
              >
                <b-button
                  variant='success'
                  @click='confirmAcceptedTermsOfUse()'
                  class='mb-2  '
                  :disabled='!confirmTermsOfUseUrl'
                >
                  Aceitar
                </b-button>
              </b-overlay>
            </section>

            <hr/>

            <section v-if='acceptPolicyPrivacy()'>
              <Terms
                title='CONTRATO DE POLÍTICA DE PRIVACIDADE'
                textLink='Tenha acesso a política de privacidade da Ho Fácil Web'
                :link='term.privacyPolicyUrl'
              />
              <p class='mt-2'>
                <b-form-checkbox v-model='confirmPrivacyPolicyUrl'>Eu estou ciente e de acordo com a política de privacidade.</b-form-checkbox>
              </p>
              <b-overlay
                :show='loadingPrivacyPolicy'
                rounded
                opacity='0.6'
                spinner-small
                style='width: fit-content'
                spinner-variant='primary'
                class=''
              >
                <b-button
                  variant='success'
                  @click='confirmTerms'
                  class='mb-2  '
                  :disabled='!confirmPrivacyPolicyUrl'
                >
                  Aceitar
                </b-button>
              </b-overlay>
            </section>
          </div>
        </main>

        <main
          style='height: 100%'
          :class='{ menuCollapsed: menuCollapsed }'
          class='mt-2'
          v-else-if='checkProfileIsFilled(user) || isFillingProfile()'
        >
          <div class='content_top'>
            <div>
              <div>
                <breadcrumb />
              </div>

              <div class='d-flex mt-3 align-content-end flex-wrap'>
                <buttons />

                <base-button
                  @click='feedbackModal = true'
                  style='width: 260px!important;'
                  class='btn-feedback mt-1'>
                  <i class='fas fa-question-circle'></i>
                  <span>
                    Envie seu feedback
                  </span>
                </base-button>
              </div>
            </div>

            <div class='mobile d-flex'>
              <section class='mr-2'>
                <base-button
                  v-if='userEligibleUserFreeTrial'
                  style='width: 210px'
                  class='mb-1'
                  pulse
                  sucess
                  @click='openDialog = true'
                >
                  Clique aqui para ativar <br />seu teste grátis
                </base-button>

                <base-button
                  pulse
                  style='font-size: 15px; width: 180px; height: 37.6px'
                  @click='showModalRenewPlan()'
                  v-if='renew'
                >
                  Renove seu plano!
                </base-button>
              </section>

              <div
                v-if='limitationOfAgentsAdded'
                style='min-width: 235px; width: 235px'
                class='mb-2'
              >
                <limitation-of-agents-added />
              </div>

              <event v-if='eventActive' :event='eventActive'/>

              <div v-else>
                <notification @readFullNotification='readFullNotification' />
              </div>
            </div>
          </div>
        </main>

        <main :class='{ menuCollapsed: menuCollapsed }' v-else>
          <div id='page-title'>
            <h1>Perfil pendente</h1>
          </div>
          Complete seu perfil
          <router-link :to="{ name: 'Profile' }">aqui</router-link>
        </main>

        <main
          :class='{ menuCollapsed: menuCollapsed }'
          v-if='(checkProfileIsFilled(user) || isFillingProfile()) && !itIsStillNecessaryToAcceptTermsOrPolicyPrivacy()'
        >
          <slot />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import SideMenu from '@/layouts/components/SideMenu'
import Breadcrumb from '@/layouts/components/Breadcrumb'
import Notification from '@/layouts/components/Notification'
import AddUpdateNew from '@/components/UpdateNew/AddUpdateNew'
import Event from '@/components/Events/Event'
import LimitationOfAgentsAdded from '@/components/LimitationOfAgentsAdded/LimitationOfAgentsAdded'
import RenewModal from '@/layouts/components/RenewModal'
import DialogConfirmation from '@/components/DialogConfirmation.vue'

import Terms from '@/components/Terms'
import Buttons from '../components/LegalSearch/components/Buttons'
import { profileIsFilled } from '../utils/checkProfileIsFilled'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import Feedback from '@/layouts/components/Feedback'
import terms from '@/components/Terms.vue'

export default {
  components: {
    SideMenu,
    Terms,
    AddUpdateNew,
    Breadcrumb,
    Notification,
    Event,
    RenewModal,
    DialogConfirmation,
    Buttons,
    Feedback,
    LimitationOfAgentsAdded
  },
  data() {
    return {
      feedbackModal: false,
      secure_url: undefined,
      plan: undefined,
      renewable: false,
      confirm: false,
      confirmTermsOfUseUrl: false,
      confirmPrivacyPolicyUrl: false,
      updateNew: null,
      menuCollapsed: true,
      loadingPrivacyPolicy: false,
      loadingTermsOfUse: false,
      pagetitle: this.$route.meta.pagetitle,
      subtitle: this.$route.meta.subtitle,
      pagetitleicon: this.$route.meta.pagetitleicon,
      openDialog: false
    }
  },
  computed: {
    terms() {
      return terms
    },
    user() {
      return this.$store.getters['user/current']
    },
    checkPendingInvoice() {
      return this.$store.getters['user/checkPendingInvoice']
    },
    showRenewPlan() {
      return this.$store.getters['user/showRenewPlan']
    },
    userEligibleUserFreeTrial() {
      return this.$store.getters['user/userEligibleUserFreeTrial']
    },
    eventActive() {
      return this.$store.getters['event/eventActive']
    },
    title() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return (this.pagetitle = this.$route.meta.pagetitle)
    },
    pageIcon() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return (this.pagetitleicon = this.$route.meta.pagetitleicon)
    },
    renew() {
      if (!this.plan || (this.plan.plan !== 'COPY' && this.plan.plan !== 'AN1HO' && this.plan.plan !== 'PROMO') || !this.user.renewalCoupon) {
        return false
      }
      const NOW = new Date()
      const subtracting30DaysFromTheCurrentDate = moment(new Date(NOW.getFullYear(), NOW.getMonth(), NOW.getDate(), 0, 0, 0)).add(-30, 'day').toDate()
      const adding30DaysFromTheCurrentDate = moment(new Date(NOW.getFullYear(), NOW.getMonth(), NOW.getDate(), 23, 59, 59)).add(30, 'day').toDate()
      const dueDate = moment(new Date(this.plan.dueDate))
      return (dueDate.isAfter(subtracting30DaysFromTheCurrentDate) && dueDate.isBefore(adding30DaysFromTheCurrentDate)) || this.plan.plan === 'COPY'
    },
    ...mapGetters('userPlans', {
      limitationOfAgentsAdded: 'limitationOfAgentsAdded'
    }),
    ...mapGetters('plan', {
      signedPlan: 'getSignedPlan',
    }),
    term() {
      return this.$store.getters['term/term']
    },
  },

  async mounted() {
    try {
      await this.handleUserEligibleUserFreeTrial()
      await this.checkIfTheUserHasSignedPlan()
      this.plan = this.signedPlan
      if (this.checkPendingInvoice) {
        const { secure_url } = await this.handlePlanInvoice(this.user)
        if (secure_url) {
          this.secure_url = secure_url
          this.$refs.modalCheckPendingInvoice.show()
        }
        await this.setCheckPendingInvoice(false)
      }
      if(this.showRenewPlan && this.renew){
        this.showModalRenewPlan()
      }
      await this.handleGet()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    ...mapActions('event', {
      handlGetEventActive: 'handlGetEventActive'
    }),
    ...mapActions('user', {
      setCheckPendingInvoice: 'setCheckPendingInvoice',
      handleUserEligibleUserFreeTrial: 'handleUserEligibleUserFreeTrial'
    }),
    ...mapActions('plan', {
      checkIfTheUserHasSignedPlan: 'checkIfTheUserHasSignedPlan',
      checkIfFreeTrialSubscription: 'checkIfFreeTrialSubscription'
    }),
    ...mapActions('userPlans', {
      handlePlanInvoice: 'handlePlanInvoice'
    }),
    ...mapActions('term', {
      handleGet: 'handleGet'
    }),
    showModalRenewPlan(){
      this.$bvModal.show('renew-modal')
    },
    async handleUsePlanTrial() {
      const response = (await this.$api.post('checkout/trial')).data
      let message = ''
      let variant = ''

      if (response.message) {
        message = response.message
        variant = 'success'
      }
      if (response.errors) {
        message = response.errors
        variant = 'danger'
      }

      this.openDialog = false
      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      const sessionId = localStorage.getItem("sessionId");
      const user = (await this.$api.get(`users/${sessionId}`)).data
      await this.$store.dispatch('user/updateUser', user)
      await this.handleUserEligibleUserFreeTrial()
      await this.checkIfTheUserHasSignedPlan()
      await this.checkIfFreeTrialSubscription()
    },

    readFullNotification(notificationId) {
      this.updateNew = notificationId
      this.$refs['show-log'].show()
    },

    closeModal() {
      this.$emit('close')
    },

    itIsStillNecessaryToAcceptTermsOrPolicyPrivacy() {
      return this.acceptTermsUse() || this.acceptPolicyPrivacy();
    },

    acceptTermsUse() {
      return !this.user.termsConfirmation;
    },

    acceptPolicyPrivacy() {
      return !this.user.privacyPolicyConfirmation;
    },

    isFillingProfile() {
      return this.$router.currentRoute.name === 'Profile'
    },
    checkProfileIsFilled(user) {
      return profileIsFilled(user)
    },
    hasFullProfile() {
      const attrs = [
        'name',
        'rg',
        'gender',
        'birthday',
        'cpf',
        'comercialPhone',
        'phone',
        'cep',
        'address',
        'number',
        'neighborhood',
        'city',
        'state'
      ]
      for (let attr in attrs) {
        if (!this.user.profile[attrs[attr]]) return false
      }
      return true
    },

    async confirmTerms() {
      this.loadingPrivacyPolicy = true;
      await this.$api.put(`users/${this.user.id}`, {
        privacyPolicyConfirmation: true,
        privacyPolicyUrl: this.term.privacyPolicyUrl,
        privacyPolicyDate: this.term.privacyPolicyDate,
        privacyPolicyName: this.term.privacyPolicyName,
      })
      const sessionId = localStorage.getItem("sessionId");
      const user = (await this.$api.get(`users/${sessionId}`)).data
      await this.$store.dispatch('user/updateUser', user)
      this.loadingPrivacyPolicy = false;
    },

    async confirmAcceptedTermsOfUse() {
      this.loadingTermsOfUse = true;
      await this.$api.put(`users/${this.user.id}`, {
        termsConfirmation: true,
        termsOfUseUrl: this.term.termsOfUseUrl,
        termsOfUseDate: this.term.termsOfUseDate,
        termsOfUseName: this.term.termsOfUseName,
      })
      const sessionId = localStorage.getItem("sessionId");
      const user = (await this.$api.get(`users/${sessionId}`)).data
      await this.$store.dispatch('user/updateUser', user)
      this.loadingTermsOfUse = false;
    },

    collapseSideMenu() {
      this.menuCollapsed = !this.menuCollapsed
    },

    changePageTitle(pageTitle) {
      return (this.pagetitle = pageTitle)
    },

    signout() {
      this.$api
        .get('auth/signout')
        .then(() => {
          this.$store.dispatch('user/updateUser', undefined)
          this.$router.push({ name: 'Signin' })
        })
        .catch((err) => {
          console.log(err)
        })
    },

    redefinePwd() {
      this.$router.push({ name: 'RedefinePwd' })
    }
  }
}
</script>

<style lang='scss' scoped>
div.page-title {
  padding-top: 2rem;
  width: 97%;
  margin: 0 auto;
}

div.page-title h1 {
  color: #04a7f3;
  font-size: 30px;
  font-weight: 700;
  margin: 0;
}

div.page-title p {
  color: #04a7f3;
  font-size: 15px;
  font-weight: 400;
  margin: 5px 0 10px 3px;
}

.box {
  max-width: 600px;
  border: 2px solid #022245;
  display: flex;
  padding: 15px;
  height: 150px;
  border-radius: 10px;
  gap: 20px;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.box::-webkit-scrollbar {
  display: none;
}

.box-child {
  border-radius: 10px;
  border: 1px solid #808080;
  padding: 15px;
  min-width: 450px;
  cursor: pointer;
}

.arrow-ball-right {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 110px;
  right: 55px;
  width: 40px;
  height: 40px;
  background-color: #022245;
  border-radius: 50%;
  cursor: pointer;
}

.arrow-ball-left {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 110px;
  right: 600px;
  background-color: #022245;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

main.menuCollapsed {
  margin-left: 90px;
  transition: all 0.5s ease;
}

main {
  /*background-color: #fafafa;*/
  margin-left: 250px;
  transition: all 0.5s ease;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  z-index: 1;
}

.btn-feedback {
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 24px;

  i {
    color: #022245;
    margin-right: 30px;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
}

.content_top {
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .content_top {
    flex-wrap: wrap;

  }

  .mobile {
    margin-top: 20px;

  }
}

.content {

}

@media (min-width: 870px) {
  .content {
    width: 100%;
  }
}

@media (min-width: 1700px) {
  main {
    padding: 0 10px 0 30px;
  }
}
@media (max-width: 900px) {
  .mobile {
    flex-wrap: wrap;
  }
}
</style>
