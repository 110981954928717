import api from '../utils/api'
import store from '../stores/index'

export default async (to, from, next) => {
  const sessionId = localStorage.getItem("sessionId");
  try {
    if (sessionId !== null) {
      const user = JSON.parse(localStorage.getItem("userHoFacil"))
      if (user) {
        await store.dispatch('user/updateUser', user)
        return next()
      }
    }
    return next()
  } catch (err) {
    console.log(err)
    return next()
  }
}
